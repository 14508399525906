import React from "react";
import map from "../../assests/india.png";

const Nation = () => {
  return (
    <>
      <div className="flex lg:flex-row flex-col justify-center items-center lg:gap-10 gap-5">
        <div className="lg:w-[55%] space-y-5">
          <h1 className="sm:text-4xl text-xl font-bold pt-7 lg:pt-0 lg:px-20 p-5 lg:p-0">Warehousing Across South</h1>
          <hr className="h-0.5 w-1/2 bg-mygreen " />
          <p className="text-gray-600 leading-loose lg:pl-20 lg:p-0 p-5 pt-10 text-justify">
            If you're looking to rent your warehouse in India and want to ensure
            a hassle-free experience while getting the best terms for your
            property, look no further. Our dedicated team specializes in leasing
            warehouses across India. From initial contact to finalizing the
            deal, we prioritize efficiency and transparency, making the rental
            process seamless for you. Get in touch with us today to discuss how
            we can help you lease your warehouse quickly and conveniently.
          </p>
        </div>
        <div className="lg:w-[45%] p-10">
          <img src={map} alt="" className=" " />
        </div>
      </div>
    </>
  );
};

export default Nation;
