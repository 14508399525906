import React, { useEffect, useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import sales from "../../assests/sales.png";
import home from "../../assests/SVG.png";
import clock from "../../assests/SVG (1).png";
import handshake from "../../assests/SVG (2).png";
import money from "../../assests/SVG (3).png";
import map from "../../assests/india.png";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { CiCircleChevRight } from "react-icons/ci";
import Banner from "./Banner";
import Testimonails from "./TestimonalsClient";
import BoxSection from "./BoxSection";
import SanityClient from "../../SanityClient";
import { Helmet } from "react-helmet";
import Contact from "./Contact";

const SellYourWarehouse = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const getdata = async () => {
      SanityClient.fetch(
        `*[_type=="sellyourwarehouse"]{
        metaTitle,
        metaDescriptions,
        MetaDate,
        FocusKeywords,
        MetaURL
        }`
      )
        .then((res) => {
          setData(res[0]);
          console.log(res, "sellyourwarehouse");
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getdata();
  }, []);

  const sell = [
    {
      no: 1,
      title: "Get in Touch With Us",
      image: home,
      content:
        "Feel free to reach out to us by filling out our online form. Provide us with some details about your property, and we'll be happy to assist you further.",
    },
    {
      no: 2,
      title: "Get an Appointment",
      image: clock,
      content:
        "Once you've reached out to us, we'll get in touch to arrange a convenient time to visit your warehouse and share an offer.",
    },
    {
      no: 3,
      title: "Offer Showcase",
      image: handshake,
      content:
        "Upon acceptance of our offer, we'll proceed to sign the contract and arrange a closing date according to your preference.",
    },
    {
      no: 4,
      title: "Riofin Assured",
      image: money,
      content:
        "On the closing day, we formally complete the transaction. Rest assured, with Riofin, you will receive your funds.",
    },
  ];
  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const faq = [
    {
      qus: "Are there any hidden charges or fees associated with selling my warehouse to you in India?",
      ans: "No, there are absolutely no hidden charges or fees when you sell your warehouse to us. We believe in transparency and ensure a straightforward process without any unexpected costs.",
    },
    {
      qus: "How long does the selling process typically take for warehouses?",
      ans: "No, there are absolutely no hidden charges or fees when you sell your warehouse to us. We believe in transparency and ensure a straightforward process without any unexpected costs.",
    },
    {
      qus: "Will I need to make any repairs or renovations to my warehouse before selling it to you?",
      ans: "No, there are absolutely no hidden charges or fees when you sell your warehouse to us. We believe in transparency and ensure a straightforward process without any unexpected costs.",
    },
    {
      qus: "What documents do I need to provide when selling my warehouse to you?",
      ans: "No, there are absolutely no hidden charges or fees when you sell your warehouse to us. We believe in transparency and ensure a straightforward process without any unexpected costs.",
    },
    {
      qus: "How can I be sure that selling my warehouse to Riofin is the best option?",
      ans: "No, there are absolutely no hidden charges or fees when you sell your warehouse to us. We believe in transparency and ensure a straightforward process without any unexpected costs.",
    },
    {
      qus: "What if my warehouse is located in a remote area of India?",
      ans: "No, there are absolutely no hidden charges or fees when you sell your warehouse to us. We believe in transparency and ensure a straightforward process without any unexpected costs.",
    },
  ];
  return (
    <>
      <Helmet>
        <title property="og:title">{data ? data.metaTitle : ""}</title>
        <meta
          property="og:description"
          content={data ? data.MetaDescription : ""}
        />
        <meta property="og:url" content={data ? data.MetaData : ""} />
        <meta name="keywords" content={data ? data.FocusKeyword : ""}></meta>
        <meta name="alldata" content={data ? data.MetaURL : ""}></meta>
      </Helmet>

      <div className="flex flex-row">
        <div className="p-10 space-y-5">
          <p className="text-blue1">Warehouse/Sell Warehouse</p>
          <h1 className="sm:text-3xl text-lg font-bold text-blue">
            Sell Us Your Warehouse for a Fair Price without any hassle!
          </h1>
          <hr className="w-1/2 h-0.5 bg-mygreen" />
          <div className="flex sm:flex-row flex-col md:gap-0 gap-3 lg:justify-between justify-around items-center">
            <div className="flex flex-col gap-3">
              <div className="flex flex-row items-center gap-3">
                <FaRegCheckCircle className="w-6 h-6 text-mygreen" />
                <p>No lengthy process</p>
              </div>
              <div className="flex flex-row items-center gap-3">
                <FaRegCheckCircle className="text-mygreen w-6 h-6" />
                <p>No delays</p>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex flex-row items-center gap-3">
                <FaRegCheckCircle className="text-mygreen w-6 h-6" />
                <p>No hidden charges</p>
              </div>
              <div className="flex flex-row items-center gap-3">
                <FaRegCheckCircle className="text-mygreen w-6 h-6" />
                <p>No Obligation</p>
              </div>
            </div>
          </div>
          <div className="w-full flex">
            <a href="#contact" >
              <button className="text-white bg-blue p-3 lg:mx-0 mx-auto px-8 text-center rounded-full">
                GET IN TOUCH
              </button>
            </a>
          </div>
        </div>
        <div className="w-[90%] xl:h-full lg:block hidden">
          <img src={sales} alt="" className="w-full h-full " />
        </div>
      </div>

      <div className="bg-blue">
        <div className="space-y-5">
          <h1 className="pt-10 px-10 md:text-4xl text-xl text-center text-white font-bold">
            How It Works
          </h1>
          <div className="w-full flex justify-end items-end">
            <hr className="w-1/2 h-0.5 bg-mygreen " />
          </div>
          <p className="text-white text-center">
            Sell your property fast with these 4 easy steps
          </p>
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 justify-evenly p-10 gap-10 lg:px-16">
          {sell.map((item, index) => (
            <div className="bg-white p-3 rounded-xl space-y-3">
              <div className="flex xl:flex-row lg:flex-col sm:flex-row flex-col items-center justify-between gap-3">
                <p className="text-white text-xl bg-mygreen rounded-full p-2 px-4 font-bold">
                  {item.no}
                </p>
                <p className="text-xl font-semibold">{item.title}</p>
                <img src={item.image} alt="" />
              </div>
              <p className="text-sm text-gray-400">{item.content}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="space-y-5">
        <h1 className="pt-10  text-4xl font-bold text-center">
          Why To Sell With Riofin?
        </h1>
        <hr className="w-1/2 h-0.5 bg-mygreen " />
        <div className="flex md:flex-row flex-col p-10 gap-5 justify-center lg:px-32 px-10">
          <div className="bg-gradient-to-t from-[#C6FFC6] to-transparent border border-mygreen md:rounded-tl-2xl md:rounded-br-2xl md:rounded-bl-[50px] rounded-xl w-full p-5 space-y-5 drop-shadow-2xl">
            <div className="flex md:flex-row flex-row-reverse justify-end gap-10 items-center ">
              <p className="text-green-500 md:text-base text-xs font-semibold">
                What you see is what you get
              </p>
              <IoCheckmarkCircleOutline className="text-green-500 md:w-10 md:h-10 w-5 h-5 " />
            </div>
            <div className="flex md:flex-row flex-row-reverse justify-end gap-10 items-center ">
              <p className="text-green-500 md:text-base text-xs font-semibold">
                We&apos;ll buy your warehouse without any delay
              </p>
              <IoCheckmarkCircleOutline className="text-green-500 md:w-10 md:h-10 homefunded:w-5 homefunded:h-5 w-7 h-7" />
            </div>
            <div className="flex md:flex-row flex-row-reverse justify-end gap-10 items-center ">
              <p className="text-green-500 md:text-base text-xs font-semibold">
                You&apos;ll get personalized service
              </p>
              <IoCheckmarkCircleOutline className="text-green-500 md:w-10 md:h-10 w-5 h-5" />
            </div>
            <div className="flex md:flex-row flex-row-reverse justify-end gap-10 items-center ">
              <p className="text-green-500 md:text-base text-xs font-semibold">
                You&apos;ll get quick closing
              </p>
              <IoCheckmarkCircleOutline className="text-green-500 md:w-10 md:h-10 w-5 h-5" />
            </div>
          </div>
          <div className="bg-gradient-to-t from-[#FFE2E2] to-transparent border border-red-500 md:rounded-tr-2xl md:rounded-br-[50px] md:rounded-bl-2xl rounded-xl w-full p-5 space-y-5 drop-shadow-2xl">
            <div className="flex flex-row gap-10 items-center ">
              <IoCheckmarkCircleOutline className="text-red-500 md:w-10 md:h-10 w-5 h-5" />
              <p className="text-red-500 md:text-base text-xs font-semibold">
                No third party invlovement
              </p>
            </div>
            <div className="flex flex-row  gap-10 items-center ">
              <IoCheckmarkCircleOutline className="text-red-500 md:w-10 md:h-10 w-5 h-5" />
              <p className="text-red-500 md:text-base text-xs font-semibold">
                No hidden charges
              </p>
            </div>
            <div className="flex flex-row  gap-10 items-center ">
              <IoCheckmarkCircleOutline className="text-red-500 md:w-10 md:h-10 w-5 h-5" />
              <p className="text-red-500 md:text-base text-xs font-semibold">
                No Undercut Price
              </p>
            </div>
            <div className="flex flex-row gap-10 items-center ">
              <IoCheckmarkCircleOutline className="text-red-500 md:w-10 md:h-10 w-5 h-5" />
              <p className="text-red-500 md:text-base text-xs font-semibold">
                No bypassing the network
              </p>
            </div>
          </div>
        </div>
      </div>
      <Banner />

      <div className="flex lg:flex-row flex-col md:gap-10 my-5 ">
        <div className="space-y-5 lg:w-[60%] w-full flex flex-col lg:items-center lg:justify-center">
          <h1 className="sm:text-4xl text-xl font-bold p-5">
            We Buy Warehouses Nation-Wide
          </h1>
          <hr className="w-3/4 h-0.5 bg-mygreen" />
          <p className="pt-5 md:pl-20 p-5 leading-10 lg:text-start text-gray-500 text-justify">
            If you're looking to sell your warehouse in India and want to ensure
            a hassle-free experience while getting the best price for your
            property, look no further. Our dedicated team specializes in
            purchasing warehouses across India. From initial contact to
            finalizing the deal, we prioritize efficiency and transparency,
            making the selling process seamless for you. Get in touch with us
            today to discuss how we can help you sell your warehouse quickly and
            conveniently.
          </p>
        </div>
        <div className="lg:w-[40%] md:w-3/5 w-full lg:p-0 p-5 mx-auto">
          <img src={map} alt="" className="w-full" />
        </div>
      </div>

      <div className="bg-black overflow-hidden">
        <div className="lg:p-10 p-5 space-y-5">
          <h1 className="text-white text-3xl font-bold text-center">FAQs</h1>
          <div className="flex w-full justify-end ml-10">
            <hr className="w-1/2 h-0.5 bg-mygreen" />
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 items-start justify-center">
            {faq.map((item, index) => (
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                style={{ backgroundColor: "white", borderRadius: "5px" }}
                className={`${
                  expanded === `panel${index}` ? "h-full" : "sm:h-[70px]"
                } w-full`}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === `panel${index}` ? (
                      <FaMinus className="w-6 h-6 bg-lightblue rounded-sm text-white" />
                    ) : (
                      <FaPlus className="w-6 h-6 bg-gray-100" />
                    )
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>
                    <p className="text-sm sm:text-base">{item.qus}</p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{item.ans}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
      <Testimonails />
      <BoxSection />
      <Contact />
    </>
  );
};

export default SellYourWarehouse;
