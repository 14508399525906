import React from "react";
import Circle1 from "../../assests/Circle1.png";
import Circle2 from "../../assests/Circle2.png";
import Circle3 from "../../assests/Circle3.png";
import Circle4 from "../../assests/Circle4.png";

const Card = () => {
  return (
    <div className=" bg-slate-100 md:p-10">
      <div className="">
        <h1 className="text-black font-bold text-center text-xl md:text-4xl  pt-10 md:pt-10 relative">
          Optimize Your Warehouse Management with Riofin
          {/* <div className='border-b-4 border-[#04ef00] md:absolute right-0 md:left-96 bottom-0 top-16 w-full md:w-1/1 font-sans'></div> */}
        </h1>
        <div class="bg-mygreen p-0.5 md:ml-96 ml-28 mt-5" />
      </div>
      <div className="mx-auto px-6 sm:px-5 mt-5   bg-slate-100  rounded-[18px] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-0 lg:grid-cols-4 gap-4">
        <div className="pt-4  w-full rounded-[18px] ">
          <div className="flex items-center justify-center">
            <img
              src={Circle1}
              alt="Your Alt Text"
              className="object-cover p-2 rounded-[18px] pb-2 lg:pb-4 h-full w-40 "
            />
          </div>
          {/* <div className="flex items-center justify-center">
          <img
            src={Img1}
            alt="Your Alt Text"
            className="object-cover w-14 h-14 -mt-56"
          />
        </div> */}
          <div className="">
            <div className=" text-blue-500 font-bold text-center text-lightblue pb-2 ">
              Warehouse Insights Powered by Data
            </div>
            <p className="text-black   text-sm text-center">
              Leverage Riofin's data-driven insights tailored to warehouse
              operations. Backed by thorough analysis and market intelligence,
              make informed decisions for optimized warehouse management
              strategies.
            </p>
          </div>
        </div>
        <div className="pt-4  w-full rounded-[18px]  ">
          <div className="flex items-center justify-center">
            <img
              src={Circle2}
              alt="Your Alt Text"
              className="object-cover p-2 rounded-[18px] pb-2 lg:pb-4  h-full w-40"
            />
          </div>
          {/* <div className="flex items-center justify-center">
          <img
            src={Img2}
            alt="Your Alt Text"
            className="object-cover w-14 h-14 -mt-52"
          />
        </div> */}
          <div className="">
            <div className="text-blue-500 font-bold  text-lightblue text-center pb-2">
              Transparent Operations
            </div>
            <p className="text-black   text-sm text-center">
              {" "}
              Experience unparalleled transparency throughout your warehouse
              journey with Riofin. Our platform provides clear and comprehensive
              information, ensuring you stay well-informed about your warehouse
              assets.
            </p>
          </div>
        </div>
        <div className="pt-4  w-full rounded-[18px]  ">
          <div className="flex items-center justify-center">
            <img
              src={Circle3}
              alt="Your Alt Text"
              className="object-cover p-2 rounded-[18px] pb-2 lg:pb-4  h-full w-40"
            />
          </div>
          {/* <div className="flex items-center justify-center">
          <img
            src={Img3}
            alt="Your Alt Text"
            className="object-cover w-14 h-14 -mt-52"
          />
        </div> */}
          <div className="">
            <div className="text-blue-500  font-bold text-lightblue text-center pb-2 ">
              Streamlined Digital Operations
            </div>
            <p className="text-black text-sm text-center">
              Enjoy the convenience of a fully digital warehouse management
              process with Riofin. From onboarding to monitoring, our platform
              offers a seamless digital experience, making warehouse management
              accessible from anywhere.
            </p>
          </div>
        </div>
        <div className="pt-4  w-full rounded-[18px]  ">
          <div className="flex items-center justify-center">
            <img
              src={Circle4}
              alt="Your Alt Text"
              className="object-cover p-2 rounded-[18px] pb-2 lg:pb-4  h-full w-40"
            />
          </div>
          {/* <div className="flex items-center justify-center">
          <img
            src={Img4}
            alt="Your Alt Text"
            className="object-cover w-14 h-14 -mt-52"
          />
        </div> */}
          <div className="pb-10">
            <div className="text-blue-500  text-lightblue font-bold text-center pb-2 ">
              Comprehensive Management Solutions
            </div>
            <p className="text-black   text-sm text-center  ">
              Riofin offers end-to-end warehouse management services, handling
              everything from tenant placement to maintenance. With our
              comprehensive approach, invest in warehouse assets with confidence
              and ease.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
