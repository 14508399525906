import MobileBanner from "./MobileBanner";
import WhyInvest from "./WhyInvest";
import PuzzleSctn1 from "./PuzzleSctn1";
import MobiletabPuzzle from "./MobiletabPuzzle";
import HighlySecured from "./HighlySecured";
import Diversification from "./Diversification ";
import DesktopBanner from "./DesktopBanner";
import { useEffect, useState } from "react";
import SanityClient from "../../SanityClient";
import { Helmet } from "react-helmet";

const Assestsleasing = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [data, setData] = useState(null);
  useEffect(() => {
    const getdata = () => {
      SanityClient.fetch(
        `*[_type=="assetLeasing"]{
          metaTitle,
          metaDescriptions,
          MetaData,
          FocusKeyword,
          MetaURL
        }`
      )
        .then((res) => {
          setData(res[0]);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getdata();
  });
  return (
    <>
      <Helmet>
        <title property="og:title">{data ? data.metaTitle : ""}</title>
        <meta
          property="og:description"
          content={data ? data.MetaDescription : ""}
        />
        <meta property="og:url" content={data ? data.MetaData : ""} />
        <meta name="keywords" content={data ? data.FocusKeyword : ""}></meta>
        <meta name="alldata" content={data ? data.MetaURL : ""}></meta>
      </Helmet>
      <DesktopBanner />
      <MobileBanner />
      <WhyInvest />
      <PuzzleSctn1 />
      <MobiletabPuzzle />
      {/* <HighlySecured /> */}
      <Diversification />
    </>
  );
};
export default Assestsleasing;
