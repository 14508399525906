import React, { useEffect, useState } from "react";
import About from '../../assests/image1.png';
import SanityClient from '../../SanityClient';
import { Helmet } from 'react-helmet';


const Fractionalownership = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const getdata = async () => {
      SanityClient.fetch(
        `*[_type=="fractionalownership"]{
        metaTitle,
        metaDescriptions,
        MetaData,
        FocusKeyword,
        MetaURL
        }`
      )
        .then((res) => {
          setData(res[0]);
          console.log(res, "fractionalOwnership");
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getdata();
  }, []);

  return (
    <>
    <Helmet>
        <title property="og:title">{data ? data.metaTitle : ""}</title>
        <meta
          property="og:description"
          content={data ? data.MetaDescription : ""}
        />
        <meta property="og:url" content={data ? data.MetaData : ""} />
        <meta name="keywords" content={data ? data.FocusKeyword : ""}></meta>
        <meta name="alldata" content={data ? data.MetaURL : ""}></meta>
      </Helmet>


      <div className='bg-black text-white px-4 md:px-6 lg:px-16'>
        <div className='max-w-7xl mx-auto'>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className='mb-6 w-full font-sans'>
              <div className='flex flex-col pt-8 md:pt-16'>
                <div className='border-b-8 border-mygreen'></div>
                <h1 className='mt-3 text-xl md:text-6xl font-bold mb-4 md:mb-8'>Fractional Ownership</h1>
                <p className='text-[16px] sm:text-lg lg:text-[20px] md:w-full'>Explore the advantages of fractional ownership with us
                </p>
              </div>
            </div>
            <div className='md:my-5 flex justify-center md:justify-end'>
              <img src={About} alt='no img' className='max-w-full hidden md:block h-auto md:max-w-md lg:max-w-full' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Fractionalownership;

