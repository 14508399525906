import React from "react";
import Benefitssectionimg2 from "../../assests/Benefitssectionimg2.png";
import Benefitssectionimg3 from "../../assests/Benefitssectionimg3.png";
import Benefitssectionimg5 from "../../assests/Benefitssectionimg5.png";
import Benefitssectionimg6 from "../../assests/Benefitssectionimg6.png";
import Benefitssectionimg7 from "../../assests/Benefitssectionimg7.png";

const Benefits = () => {
  return (
    <>
      <div className="pt-10">
        <h1 className="text-center text-3xl font-bold">Benefits</h1>
        <div className="flex flex-col justify-end items-end">
          <hr className="w-1/2 h-0.5 bg-mygreen mt-5" />
        </div>
        <div className="flex flex-wrap gap-1 gap-y-10 justify-around  p-14">
          <div className="border bg-white border-blue drop-shadow-xl relative rounded-md w-56 h-44">
            <div className="w-16 h-16 border bg-white border-blue rounded-full p-3 absolute -top-8  right-1/2 translate-x-1/2 drop-shadow-xl">
              <img src={Benefitssectionimg2} alt="" className="w-ful h-full" />
            </div>
            <div className="absolute bottom-1/2 translate-y-1/2 p-3 space-y-2">
              <h1 className="text-sm font-semibold text-center">
                Full Tenant Acquisition
              </h1>
              <p className="text-center  text-sm text-slate-800">
                We find and secure high-quality tenants.
              </p>
            </div>
          </div>
          <div className="border bg-white border-blue drop-shadow-xl relative rounded-md w-56 h-44">
            <div className="w-16 h-16 border bg-white border-blue rounded-full p-3 absolute -top-8  right-1/2 translate-x-1/2 drop-shadow-xl">
              <img src={Benefitssectionimg3} alt="" className="w-ful h-full" />
            </div>
            <div className="absolute bottom-1/2 translate-y-1/2 p-3 space-y-2">
              <h1 className="text-sm font-semibold text-center">
                Comprehensive Maintenance
              </h1>
              <p className="text-center text-sm text-slate-800">
                All maintenance tasks handled by our expert team.
              </p>
            </div>
          </div>
          <div className="border bg-white border-blue drop-shadow-xl relative rounded-md w-56 h-44">
            <div className="w-16 h-16 border bg-white border-blue rounded-full p-3 absolute -top-8  right-1/2 translate-x-1/2 drop-shadow-xl">
              <img src={Benefitssectionimg5} alt="" className="w-ful h-full" />
            </div>
            <div className="absolute bottom-1/2 translate-y-1/2 p-3 space-y-2">
              <h1 className="text-sm font-semibold text-center">Financial Management</h1>
              <p className="text-center text-sm text-slate-800">
                Detailed financial reporting and transparent management
              </p>
            </div>
          </div>
          <div className="border bg-white border-blue drop-shadow-xl relative rounded-md w-56 h-44">
            <div className="w-16 h-16 border bg-white border-blue rounded-full p-3 absolute -top-8  right-1/2 translate-x-1/2 drop-shadow-xl">
              <img src={Benefitssectionimg6} alt="" className="w-ful h-full" />
            </div>
            <div className="absolute bottom-1/2 translate-y-1/2 p-3 space-y-2">
              <h1 className=" text-sm font-semibold text-center">24/7 Support</h1>
              <p className="text-center  text-sm text-slate-800">
                Round-the-clock support to address any issues promptly.
              </p>
            </div>
          </div>
          <div className="border bg-white border-blue drop-shadow-xl relative rounded-md w-56 h-44">
            <div className="w-16 h-16 border bg-white border-blue rounded-full p-3 absolute -top-8  right-1/2 translate-x-1/2 drop-shadow-xl">
              <img src={Benefitssectionimg7} alt="" className="w-ful h-full" />
            </div>
            <div className="absolute  bottom-1/2 translate-y-1/2 p-3 space-y-2">
              <h1 className="text-sm font-semibold text-center">Priority Service</h1>
              <p className="text-center  text-sm text-slate-800">
                Get prioritized service for all your management needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Benefits;
