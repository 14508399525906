import React from 'react'

const Heading = () => {
  return (
    <>
       <h1 className='text-black font-bold text-center md:text-center mt-10 text-xl md:text-4xl '> How RioFin Assets Work?</h1>
    <div className='bg-mygreen h-0.5 md:ml-[650px] mt-4'/>
    </>
  )
}

export default Heading;