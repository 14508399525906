import React, { useEffect, useState } from 'react';
import client from '../../assests/7.png';
import build from '../../assests/img3.png';
import second from '../../assests/8.png';
import third from '../../assests/9.png';
import fourth from '../../assests/10.png';
import fifth from '../../assests/11.png';
import { BiSolidQuoteAltLeft } from "react-icons/bi";
import { FaStar } from "react-icons/fa";
import { BiSolidQuoteAltRight } from "react-icons/bi";
function TestimonalsClient() {
  const [loops, setLoops] = useState(0);

  const arr = [
    { image: client, name: "Chandra", content: "Selling my warehouse to Riofin was a seamless experience. The team was professional and ensured every detail was taken care of. I highly recommend their services.", role: "Business Owner" },
    { image: second, name: "Arjun", content: "Riofin made the process of selling my warehouse incredibly easy. Their expertise and transparency stood out, making the entire transaction stress-free.", role: "Real Estate Investor" },
    { image: third, name: "Vikram", content: "From start to finish, Riofin handled the sale of my warehouse with utmost efficiency. Their commitment to client satisfaction is commendable.", role: "Industrial Property Owner" },
    { image: fourth, name: "Priya", content: "The sale of my warehouse was handled exceptionally well by Riofin. Their team was responsive and managed everything with great professionalism.", role: "Commercial Property Owner" },
    { image: fifth, name: "Anita", content: "Riofin provided an outstanding service when I sold my warehouse. The process was quick, transparent, and hassle-free. I am very satisfied with their work.", role: "Entrepreneur" },
  ];


  useEffect(() => {
    const interval = setInterval(() => {
      setLoops((prev) => (prev + 1) % arr.length);
    }, 5000); // Change the interval time as needed

    return () => clearInterval(interval);
  }, [arr.length]);

  return (
    <div className="bg-gray-100">
      <div className="relative w-full pt-5 h-[70px]">
        <p className="text-4xl text-center font-bold">Testimonials</p>
        <p className="w-[60%] h-[2px] absolute left-0 bottom-0 bg-mygreen"></p>
      </div>
      <div className="w-full flex justify-center items-center  lg:justify-end lg:items-end py-5">
        <div className="flex lg:flex-row flex-col justify-center items-center w-full  lg:w-[90%]">
          <div className='z-10 rounded-xl drop-shadow-xl'>
            <div style={{
                backgroundImage:`url(${arr[loops].image})`,
                transition:'background-image 1s ease'
                
            }} className="lg:w-[350px] rounded-xl lg:h-[450px] z-40 w-[200px] h-[200px] sm:w-[300px] sm:h-[300px]  bg-no-repeat bg-cover bg-center">
             
            </div>
          </div>
          <div className="lg:bg-blue lg:-mt-0 -mt-4 relative lg:pl-28 pl-0 w-full h-fit lg:h-[300px]">
            <div className="flex w-full flex-col lg:mt-10 p-5 gap-3">
              <BiSolidQuoteAltLeft size={80} className="text-white lg:flex hidden opacity-60" />
              <div  className="lg:w-[400px] w-full relative lg:mx-0 mx-auto flex -mt-5 flex-col justify-around gap-3 rounded-lg bg-white drop-shadow-2xl lg:p-4 p-6">
              <BiSolidQuoteAltLeft size={30} className=" text-blue lg:hidden hidden leawidth:flex -top-4 left-0 absolute" />
         <div className='w-[2px] absolute bottom-0 lg:hidden flex right-0 h-10 bg-blue'></div>
         <div className='h-[2px] absolute bottom-0 lg:hidden flex right-0 w-10 bg-blue'></div>
                <div className="flex flex-col">
                  <p>{arr[loops].name}</p>
                  <p className="text-xs text-gray-400">{arr[loops].role}</p>
                </div>
                <p className="text-sm text-justify ">{arr[loops].content}</p>
                <div className="flex gap-2 ">
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestimonalsClient;
