import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import banner from '../../assests/support.png';

const MobileBanner = () => {
  const [content, setContent] = useState('');

  const handleInputChange = (e) => {
    setContent(e.target.value);
  };

  return (
    <div className="relative block md:hidden">
      {/* Content for the section */}
      <div className="relative">
        <img src={banner} alt="" className="w-full h-auto" />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
          <h1 className="text-[26px] md:text-4xl lg:text-5xl font-bold text-white mb-4">
            How can we help?
          </h1>
          
        </div>
      </div>
    </div>
  );
};

export default MobileBanner;
