import React from "react";
import Image1 from "../../assests/pa-1.png";
import Image2 from "../../assests/pa-2.png";
import Image3 from "../../assests/pa-3.png";
import Image4 from "../../assests/pa-4.png";

const SectionWithIconBoxes3 = () => {
  return (
    <div className="mx-auto px-4 sm:px-6 mb-4   lg:px-0 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-0 lg:grid-cols-4  gap-4">
      {/* Box 1 */}
      <div className=" text-black flex flex-col items-center justify-center border-none hidden  md:hidden lg:block ">
        <img src={Image1} className="lg:hidden" alt="Image 1" />
        <h2 className="box-title lg:pr-8 lg:mr-10 lg:mb-2 text-center sm:text-center md:text-center font-bold text-[#216DFD] ">
          Carefully Curated Listings
        </h2>
        <p className="box-content  lg:pr-4 lg:pl-1 lg:mr-14 text-center">
          Riofin meticulously selects warehouse properties, ensuring they meet
          diverse client needs and investment objectives, providing transparency
          and reliability in the buying process.
        </p>
      </div>

      {/* Mobile view box 1 */}
      <div className=" text-black flex flex-col items-center justify-center lg:hidden  border shadow-xl pt-4 pb-4 pr-8 pl-8  md:pt-4 md:pb-4 ">
        <img src={Image1} className=" " alt="Image 1" />
        <h2 className="box-title lg:pr-8 lg:mr-10 mt-2 text-center sm:text-center md:text-center font-bold text-[#216DFD]  ">
          Carefully Curated Listings
        </h2>
        <p className="box-content  lg:pr-4 lg:pl-1 lg:mr-10 text-center">
          Riofin meticulously selects warehouse properties, ensuring they meet
          diverse client needs and investment objectives, providing transparency
          and reliability in the buying process.
        </p>
      </div>

      {/* Box 2 */}
      <div className=" text-black flex flex-col items-center justify-center border-none hidden  md:hidden lg:block ">
        <img src={Image2} className="lg:hidden" alt="Image 2" />
        <h2 className="box-title lg:pr-4 lg:mr-0 lg:mb-2 text-center sm:text-center md:text-center font-bold text-[#216DFD]">
          Client Engagement
        </h2>
        <p className="box-content ml-8 mr-8  text-center">
          Clients actively participate in the buying process, contributing to
          the selection and negotiation of warehouse properties, becoming
          integral stakeholders in their chosen transactions.
        </p>
      </div>

      {/* Mobile view box 2 */}
      <div className="text-black flex flex-col items-center justify-center lg:hidden  border shadow-xl pt-4 pb-4  md:pt-4 md:pb-4 ">
        <img src={Image2} className="" alt="Image 2" />
        <h2 className="box-title lg:pr-4 lg:mr-0 mt-2 text-center sm:text-center md:text-center font-bold text-[#216DFD]">
          Client Engagement
        </h2>
        <p className="box-content ml-8 mr-8  text-center">
          Clients actively participate in the buying process, contributing to
          the selection and negotiation of warehouse properties, becoming
          integral stakeholders in their chosen transactions.
        </p>
      </div>

      {/* Box 3 */}
      <div className=" text-black flex flex-col ml-8 items-center justify-center border-none hidden  md:hidden lg:block ">
        <img src={Image3} className="lg:hidden" alt="Image 3" />
        <h2 className="box-title lg:pr-0 lg:mr-0 lg:mb-2 text-center sm:text-center md:text-center font-bold text-[#216DFD] ">
          Transparent Transaction Process
        </h2>
        <p className="box-content ml-8 mr-8 text-center">
          Chosen warehouses are transparently listed on our platform, offering
          detailed insights into each property's potential, empowering clients
          to make informed decisions with confidence.
        </p>
      </div>
      {/* Mobile view box 3 */}
      <div className="text-black flex flex-col items-center justify-center lg:hidden  border shadow-xl pt-4 pb-4  md:pt-4 md:pb-4">
        <img src={Image3} className="" alt="Image 2" />
        <h2 className="box-title lg:pr-4 lg:mr-0 mt-2 text-center sm:text-center md:text-center font-bold text-[#216DFD]">
          Transparent Transaction Process
        </h2>
        <p className="box-content ml-8 mr-8  text-center">
          Chosen warehouses are transparently listed on our platform, offering
          detailed insights into each property's potential, empowering clients
          to make informed decisions with confidence.
        </p>
      </div>

      {/* Box 4 */}
      <div className=" text-black flex flex-col items-center justify-center border-none hidden  md:hidden lg:block">
        <img src={Image4} className="lg:hidden" alt="Image 4" />
        <h2 className="box-title lg:pl-20 lg:mr-0 lg:mb-2 text-center sm:text-center md:text-center font-bold text-[#216DFD] ">
          Expert Guidance
        </h2>
        <p className="box-content ml-16 mr-1 text-center">
          {" "}
          Riofin provides expert guidance throughout the buying process,
          offering market insights, strategic advice, and negotiation support to
          optimize outcomes and ensure client satisfaction.
        </p>
      </div>
      {/* Mobile view box 4 */}
      <div className="text-black flex flex-col items-center justify-center lg:hidden  border shadow-xl pt-4 pb-4  md:pt-4 md:pb-4">
        <img src={Image4} className="" alt="Image 2" />
        <h2 className="box-title lg:pr-4 lg:mr-0 mt-2 text-center sm:text-center md:text-center font-bold text-[#216DFD]">
          Expert Guidance
        </h2>
        <p className="box-content ml-8 mr-8  text-center">
          Riofin provides expert guidance throughout the buying process,
          offering market insights, strategic advice, and negotiation support to
          optimize outcomes and ensure client satisfaction.
        </p>
      </div>
    </div>
  );
};

export default SectionWithIconBoxes3;
