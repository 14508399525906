import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Components/Home/Home';
import Navbar, { NavbarWithMegaMenu } from './subcomponents/Navbar';
import Footer from './Components/Home/Footer';
import Aboutus from './Components/Aboutus/About';
// import Bl from './Components/Blog/Blog1';
import Faq from './Components/Faq/Faq';
import Assets from './Components/Rio-assets/Aseets';
import Twosection from './Components/Form enquiry/Twosection';
import Support from './Components/Support/Support';
import Fractional from './Components/Fractional/Fractional';
import Rio from './Components/Rioivestor/Rio';
import Assestsleasing from './Components/Assestsleasing/Assestsleasing';
import SignUpForm from './Components/Login/Signup';
import SignUp from './Components/Login/Signup1';
import Login from './Components/Login/Login';
import Contact from './Components/Contact/Contact';
// import Blog1 from './Components/Blog/Blog1';
import Blog from './Components/Blog/Blog';
// import BlogPage from './Components/Blog/resources/support/Blog1';
import Form from './Components/Home/Form1';
import SellYourWarehouse from './Components/Sell your warehouse/SellYourWarehouse';
import Banner from './Components/Buy warehouse/Banner';
import Main from './Components/Rental warehouse/Main';
import AssetMain from './Components/Warehouse Asset Management/AssetMain';
import BlogPage from './Components/Blog/Blog1';

// import Register from './admin/register';
// import Assestleasing from './Components/Assestsleasing/Assestsleasing';
// import Assestsleasing from './Components/Assestsleasing/Assestsleasing';
// import Fractional from './Components/Fractional/Fractional';
//  import Twosection from './Components/Form enquiry/Twosection';
// import Assets from './Components/Rio-assets/Aseets';
//  import Support from './Components/Support/Support';
// import Rio from './Components/Rioivestor/Rio';



function App() {
  return (
    <>

      <Navbar />
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/aboutus' element={<Aboutus />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/blog/:id' element={<BlogPage/>} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/contactus' element={<Contact />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signupform' element={<SignUpForm />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/investment' exact element={<Rio />} />
        <Route path='/resources/assets' element={<Assets />} />
        <Route path='/resources/enquiry' element={<Twosection />} />
        <Route path='/resources/support' element={<Support />} />
        <Route path='/resources/assetsleasing' element={<Assestsleasing />} />
        <Route path='/resources/fractional' element={<Fractional />} />
        <Route path='/form' element={<Form/>}/>
        <Route path='/resources/sellyourwarehouse' element={<SellYourWarehouse/>} />
        <Route path='/resources/rentalwarehouse' element={<Main />} />
        <Route path='/resources/warehouseasset' element={<AssetMain />} />
        <Route path='/resources/buywarehouse' element={<Banner />} />
      </Routes>
      <Footer />

    </>

  );
}

export default App;