import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faQuestion, faSackDollar, faHandshakeSimple } from '@fortawesome/free-solid-svg-icons';
import BackgroundImage1 from '../../assests/Invsbg.jpg';
import { Link } from 'react-router-dom';

function Investor() {
  const cardStyle1 = {
    backgroundImage: `url(${BackgroundImage1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <div className='bg-white py-10 align-middle w-full ' style={cardStyle1}>
      <h1 className='text-black font-bold text-center text-[26px] md:text-4xl '>
        Not an Investor?
      </h1>
      <div className='flex justify-end'>
        <div className='border-b-4 border-mygreen pb-4 mb-6 pl-[650px] overflow-hidden'></div>
      </div>

      <p className='text-black text-center px-2 md:px-32 text-[16px] md:text-2xl my-0 pb-6 md:pb-0 font-sans'>
        Our Teams stand ready to Help!
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-around mt-4 lg:mx-10 mx-0 lg:gap-2 md:gap-6 gap-8 pb-0  md:p-2 p-2">
        {/* First Icon Box */}
        <div className=" pt-4 bg-white  lg:m-12 md:m-10 border-[3px] lg:max-h-[100%] rounded-[6px] border-solid border-[black] shadow-[9px_9px]">
          <div className="flex items-center justify-center">
            <FontAwesomeIcon icon={faSackDollar}  className="text-blue text-xl w-10 h-10 object-cover p-5 rounded-full bg-[#fff] border-r-4 border-l-[1px] border-t-[1px] border-b-4 border-blue  shadow-lg pb-2 lg:pb-4 absolute flex" />
          </div>
          <div className="px-2 lg:px-6 py-2 lg:py-4 text-center">
            <h2 className="font-bold text-xl md:text-2xl mt-8 font-sans md:mt-16">Become A Seller</h2>
            <div className=''>
            <p className='text-black text-center  lg:max-h[20%] text-1xl mt-2 md:mt-5 max-h-fit'>
            Promote our deals to your network and earn a referral percentage on successful transactions.

              </p>
            </div>
          <Link to='/resources/enquiry'>  <button className='bg-mygreen font-bold text-black py-2 px-4 rounded-md mt-4 mb-10 border-black border-b-[3px] border-r-[3px] border-l-[1px] border-t-[1px] ease-linear transform hover:scale-125 transition duration-500'>
              Join Now
            </button></Link>
          </div>
        </div>

        {/* Second Icon Box */}
        <div className="  pt-4 bg-white  lg:m-12 md:m-10 border-[3px] lg:max-h-[100%] rounded-[6px] border-solid border-[black] shadow-[9px_9px]">
          <div className="flex items-center justify-center">
            <FontAwesomeIcon icon={faHandshakeSimple} className=" text-blue text-xl  w-10 h-10 object-cover p-5 rounded-full bg-[#fff] border-r-4 border-l-[1px] border-t-[1px] border-b-4 border-blue  shadow-lg pb-2 lg:pb-4 absolute flex" />
          </div>
          <div className="px-2 lg:px-6  py-2 lg:py-4 text-center">
            <h2 className="font-bold text-xl md:text-2xl mt-8 font-sans md:mt-16">Become A Partner</h2>
            
            <p className='text-black text-center text-1xl mt-2 md:mt-5 max-h-fit  '>
            Explore mutual opportunities for capitalizing together.
            </p>
            
            <Link to='/resources/enquiry'>  <button className='bg-mygreen font-bold text-black py-2 px-4 rounded-md mt-4 mb-10 border-black border-b-[3px] border-r-[3px] border-l-[1px] border-t-[1px] ease-linear transform hover:scale-125 transition duration-500'>
              Join Now
            </button></Link>
          </div>
        </div>

        {/* Third Icon Box */}
        <div className=" pt-4 bg-white  lg:m-12 md:m-10 border-[3px] lg:max-h-[100%] rounded-[6px] border-solid border-[black] shadow-[9px_9px]">
          <div className="flex items-center justify-center">
            <FontAwesomeIcon icon={faQuestion} className=" text-blue text-xl  w-10 h-10 object-cover p-5 rounded-full bg-[#fff] border-r-4 border-l-[1px] border-t-[1px] border-b-4 border-blue  shadow-lg pb-2 lg:pb-4 absolute flex" />
          </div>
          <div className="px-2 lg:px-6 py-2 lg:py-4 text-center">
            <h2 className="font-bold text-xl md:text-2xl mt-8 font-sans md:mt-16">Other Queries?</h2>
            <div className=''>
            <p className='text-black text-center  text-1xl mt-2 md:mt-5 max-h-fit'>
            If you are a firm in the MSME industry or a property developer interested in our business model, please reach out to us.
            </p>
            </div>
            <Link to='/resources/enquiry'>  <button className='bg-mygreen font-bold text-black py-2 px-4 rounded-md mt-4 mb-10 border-black border-b-[3px] border-r-[3px] border-l-[1px] border-t-[1px] ease-linear transform hover:scale-125 transition duration-500'>
              Join Now
            </button></Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Investor;
