import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { faAllergies, faGear, faHouse, faHouseLaptop, faStamp } from '@fortawesome/free-solid-svg-icons';
import Slider from 'react-slick';
import Elipse from '../../assests/Ellipse 121.png';

const Testimonials = ({ content, icon }) => {
  return (
    <div className="max-w-sm max-h-fit lg:max-h-fit rounded-[18px] overflow-hidden border-b-[8px] border-r-[8px] border-mygreen bg-gradient-to-b from-blue to-dlue  lg:p-4 p-5  lg:m-8 m-4 shadow-md relative text-white text-center">
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className="w-6 h-6 md:w-16 md:h-16 p-3 border-black border-r-2 border-b-2 lg:border-r-4 lg:border-b-4 lg:border-t-0 lg:border-l-0 icon-box bg-mygreen text-white rounded-[12px] mx-auto"
        />
      )}
      <p className="mt-2 text-sm md:mt-4">{content}</p>
    </div>
  );
};

const Weprovideslide = () => {
  const testimonials = [
    {
      icon: faStamp,
      content: 'Machinery & Equipment',
    },
    {
      icon: faAllergies,
      content: 'Commercial Real estate',
    },
    {
      icon: faHouse,
      content: 'Machinery & Equipment',
    },
    {
      icon: faHouseLaptop,
      content: 'Commercial Real estate',
    },
  ];

  const sliderRef = useRef(null);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, // Adjust the autoplay speed as needed
    customPaging: function (i) {
      return <div className="slick-dot"></div>; // Example styling for the custom dots
    },
    responsive: [
      {
        breakpoint: 1164,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="md:p-4 p-4 ">
      <div className="relative mx-auto p-4 lg:p-6 sm:px-10 lg:bg-black lg:ml-40 lg:mr-40 rounded-[18px] grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
        <img
          src={Elipse}
          alt="Ellipse"
          className="hidden lg:block absolute top-1/2 left-0 transform -translate-y-1/2 -translate-x-1/2 lg:left-4 lg:-translate-x-2/3"
        />

        {/* Right ellipse */}
        <img
          src={Elipse}
          alt="Ellipse"
          className="hidden lg:block absolute top-1/2 right-0 transform -translate-y-1/2 -translate-x-1/2 lg:right-[-80px] lg:-translate-x-1/2"
        />
        <Slider ref={sliderRef} {...sliderSettings}>
          {testimonials.map((testimonial, index) => (
            <Testimonials key={index} {...testimonial} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Weprovideslide;
