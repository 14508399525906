import React from "react";
import { FaPaperclip } from "react-icons/fa";

const Vision = () => {
  return (
    <div className="pb-5">
      <div className="bg-gradient-to-b from-blue-50 to-white ">
        <h1 className="flex pt-10 justify-center text-[26px]  md:text-4xl font-bold pb-3">
          Our Vision
        </h1>
        <div className="grid grid-cols-2">
          <div className=""></div>
          <div className="">
            <hr className="hro" />
          </div>
        </div>
        {/* <div className="grid grid-cols-1 p-2 md:grid-cols-2 lg:grid-cols-3 mx-2 md:mx-10 md:px-16 py-10 gap-10 ">
       
        <div className=" shadow-xl py-5 px-5 md:shadow-none md:py-0 md:px-0">
          <div className="grid grid-cols-2 mb-2 ">
            <FaPaperclip className='text-xl font-extralight' />
            <hr className='hrv mt-3 -ml-[6rem]' />
          </div>
          <h4 className='font-bold text-xl font-sans md:text-2xl'>Vision - 1</h4>
          <p className=' text-justify '>
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout
          </p>
        </div>
        
        

        <div className=" shadow-xl py-5 px-5 md:shadow-none md:py-0 md:px-0">
          <div className="grid grid-cols-2 mb-2">
            <FaPaperclip className='text-xl font-extralight' />
            <hr className='hrv mt-3 -ml-[6rem]' />
          </div>
          <h4 className='font-bold text-xl font-sans md:text-2xl'>Vision - 2</h4>
          <p className=' text-justify '>
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout
          </p>
        </div>

        
        <div className=" shadow-xl py-5 px-5 md:shadow-none md:py-0 md:px-0">
          <div className="grid grid-cols-2 mb-2">
            <FaPaperclip className='text-xl font-extralight' />
            <hr className='hrv mt-3 -ml-[6rem]' />
          </div>
          <h4 className='font-bold text-xl font-sans md:text-2xl'>Vision - 3</h4>
          <p className='text-justify'>
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout
          </p>
        </div>
      </div> */}
        <div className="py-5  w-[80%] mx-auto">
          <p className="text-justify">
            "To revolutionize the warehouse industry, empowering clients with
            seamless buying and selling experiences, while fostering financial
            prosperity and growth for all stakeholders."
          </p>
        </div>
      </div>
      <div className="bg-gradient-to-b from-blue-50 to-white ">
        <h1 className="flex pt-10 justify-center text-[26px]  md:text-4xl font-bold pb-3">
          Our Mission
        </h1>
        <div className="grid grid-cols-2">
          <div className=""></div>
          <div className="">
            <hr className="hro" />
          </div>
        </div>
        <div className="py-5 w-[80%] mx-auto">
         
          <p className="text-justify">
            "At Riofin, our mission is to serve as the premier partner in
            warehouse transactions, offering transparent and innovative
            solutions. We aim to streamline the buying and selling process,
            maximize value for our clients, and cultivate long-term success
            through expert asset management and strategic partnerships."
          </p>
        </div>
      </div>
    </div>
  );
};

export default Vision;
