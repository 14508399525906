import React, { useState } from 'react';
import { useEffect } from 'react';
import faqJson from '../../json/faq.json'
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { HiOutlineMinusSmall } from "react-icons/hi2";

const FaqComponent = () => {
  const [activeCategory, setActiveCategory] = useState('GENERAL');
  const [fillterFaq, setFillterFaq] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(null);
  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };
  const toggleAnswer = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };
  useEffect(() => {
    if (activeCategory === "GENERAL") {
      const faqArray = faqJson.filter((faq) => faq.faq_title === "GENERAL");
      setFillterFaq(faqArray);
      
    } else if (activeCategory === "REAL_ESTATE") {
      const faqArray = faqJson.filter(faq => faq.faq_title === "REAL_ESTATE");
      setFillterFaq(faqArray);
    } else {
      const faqArray = faqJson.filter(faq => faq.faq_title === "LEASING");
      setFillterFaq(faqArray);
    }
    
  }, [activeCategory]) 
  const data = fillterFaq.slice(0, 5);
  return (
    <>
    <div className='flex md:gap-10 md:flex-row  flex-col '>
        <div className='basis-3/6 justify-center md:mb-28  '>
          <div className='faqtype flex flex-col gap-2 md:gap-0'>
            <div className={`tag ${activeCategory === 'GENERAL' ? 'active' : ''}`}>
              <h1
                className='underline md:my-20 my-3 text-2xl cursor-pointer'
                onClick={() => handleCategoryClick('GENERAL')}
              >
              Buying Warehouse 
              </h1>
            </div>
            <div className={`tag ${activeCategory === 'REAL_ESTATE' ? 'active' : ''}`}>
              <h1
                className='underline md:my-20 my-3 text-2xl cursor-pointer'
                onClick={() => handleCategoryClick('REAL_ESTATE')}
              >
              Selling Warehouse 
              </h1>
            </div>
            <div className={`tag ${activeCategory === 'LEASING' ? 'active' : ''}`}>
              <h1
                className='underline md:my-20 my-3 text-2xl cursor-pointer'
                onClick={() => handleCategoryClick('LEASING')}
              >
                Warehouse Asset Management 
              </h1>
            </div>
          </div>
        </div>
        <div class='mb-6'>
          {data.map((value, index) => (
            <div key={index}>
              <p className='bg-sky-300 z-10 mt mt-14  question w-auto' onClick={() => toggleAnswer(index)} style={{ cursor: 'pointer' }}>
                <p>{(activeQuestion === index ? < HiOutlineMinusSmall className='icon transition-transform duration-300 ease-in-out' /> : <HiOutlinePlusSmall className='icon transition-transform  duration-3000' />)}</p>{value.qus}
              </p >
              {activeQuestion === index && <p className='answer -z-30 transition-all duration-1000 ease-in-out w-full'>
                {value.ans}</p>}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FaqComponent;
