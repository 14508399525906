import React from "react";
import home from "../../assests/SVG.png";
import clock from "../../assests/SVG (1).png";
import handshake from "../../assests/SVG (2).png";
import money from "../../assests/SVG (3).png";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

const Cards = () => {
  const sell = [
    {
      no: 1,
      title: "Get in Touch With Us",
      image: home,
      content:
        "Feel free to reach out to us by filling out our online form. Provide us with some details about your property, and we'll be happy to assist you further.",
    },
    {
      no: 2,
      title: "Get an Appointment",
      image: clock,
      content:
        "Once you've reached out to us, we'll get in touch to arrange a convenient time to visit your warehouse and share an offer.",
    },
    {
      no: 3,
      title: "Nod for the Offer",
      image: handshake,
      content:
        "Upon acceptance of our offer, we'll proceed to sign the contract and arrange a closing date according to your preference.",
    },
    {
      no: 4,
      title: "Riofin Assured",
      image: money,
      content:
        "On the closing day, we formally complete the transaction. Rest assured, with Riofin, you will receive your funds.",
    },
  ];
  return (
    <>
      <div className="bg-black mt-1">
        <div className="space-y-5">
          <h1 className="pt-10 px-10 md:text-4xl text-xl text-center text-white font-bold">
            How It Works
          </h1>
          <div className="w-full flex justify-end items-end">
            <hr className="w-1/2 h-0.5 bg-mygreen " />
          </div>
          <p className="text-white text-center">
            Buy your property fast with these 4 easy steps
          </p>
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 justify-evenly p-10 gap-10 lg:px-16">
          {sell.map((item, index) => (
            <div className="bg-white p-3 rounded-xl space-y-3">
              <div className="flex xl:flex-row lg:flex-col sm:flex-row flex-col items-center justify-between gap-3">
                <p className="text-white text-xl bg-blue rounded-full p-2 px-4 font-bold">
                  {item.no}
                </p>
                <p className="text-xl font-semibold">{item.title}</p>
                <img src={item.image} alt="" />
              </div>
              <p className="text-sm text-gray-400">{item.content}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="space-y-5">
        <h1 className="pt-10  text-4xl font-bold text-center">
          Why Choose Riofin for Your Purchase?
        </h1>
        <hr className="w-1/2 h-0.5 bg-mygreen " />
        <div className="flex md:flex-row flex-col p-10 gap-5 justify-center lg:px-32 px-10">
          <div className="bg-gradient-to-t from-[#C6FFC6] to-transparent border border-mygreen md:rounded-tl-2xl md:rounded-br-2xl md:rounded-bl-[50px] rounded-xl w-full p-5 space-y-5 drop-shadow-2xl">
            <div className="flex md:flex-row flex-row-reverse justify-end gap-10 items-center ">
              <p className="text-green-500 md:text-base text-xs font-semibold">
                What you see is what you get
              </p>
              <IoCheckmarkCircleOutline className="text-green-500 md:w-10 md:h-10 w-5 h-5 " />
            </div>
            <div className="flex md:flex-row flex-row-reverse justify-end gap-10 items-center ">
              <p className="text-green-500 md:text-base text-xs font-semibold">
                We'll Buy a warehouse without any delay
              </p>
              <IoCheckmarkCircleOutline className="text-green-500 md:w-10 md:h-10 homefunded:w-5 homefunded:h-5 w-7 h-7" />
            </div>
            <div className="flex md:flex-row flex-row-reverse justify-end gap-10 items-center ">
              <p className="text-green-500 md:text-base text-xs font-semibold">
                You&apos;ll get personalized service
              </p>
              <IoCheckmarkCircleOutline className="text-green-500 md:w-10 md:h-10 w-5 h-5" />
            </div>
            <div className="flex md:flex-row flex-row-reverse justify-end gap-10 items-center ">
              <p className="text-green-500 md:text-base text-xs font-semibold">
                You&apos;ll get quick closing
              </p>
              <IoCheckmarkCircleOutline className="text-green-500 md:w-10 md:h-10 w-5 h-5" />
            </div>
          </div>
          <div className="bg-gradient-to-t from-[#FFE2E2] to-transparent border border-red-500 md:rounded-tr-2xl md:rounded-br-[50px] md:rounded-bl-2xl rounded-xl w-full p-5 space-y-5 drop-shadow-2xl">
            <div className="flex flex-row gap-10 items-center ">
              <IoCheckmarkCircleOutline className="text-red-500 md:w-10 md:h-10 w-5 h-5" />
              <p className="text-red-500 md:text-base text-xs font-semibold">
                No third party invlovement
              </p>
            </div>
            <div className="flex flex-row  gap-10 items-center ">
              <IoCheckmarkCircleOutline className="text-red-500 md:w-10 md:h-10 w-5 h-5" />
              <p className="text-red-500 md:text-base text-xs font-semibold">
                No hidden charges
              </p>
            </div>
            <div className="flex flex-row  gap-10 items-center ">
              <IoCheckmarkCircleOutline className="text-red-500 md:w-10 md:h-10 w-5 h-5" />
              <p className="text-red-500 md:text-base text-xs font-semibold">
                No Undercut Price
              </p>
            </div>
            <div className="flex flex-row gap-10 items-center ">
              <IoCheckmarkCircleOutline className="text-red-500 md:w-10 md:h-10 w-5 h-5" />
              <p className="text-red-500 md:text-base text-xs font-semibold">
                No bypassing the network
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
