import React from 'react';
import About from '../../assests/aboutusNew.png';


const Aboutus = () => {
  return (
    <>
      <div className='bg-black text-white px-6 md:px-16 '>
        <div className='max-w-7xl mx-auto md:ml-10'>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className='flex flex-col'>
              <div className="my-5 lg:my-16">
                <hr className='hrd' />
                <h1 className='mt-3 text-2xl sm:text-xl lg:text-6xl   font-bold mb-4 md:mb-8'>About Us</h1>
                <p className='text-[16px] md:text-2xl md:w-full '>Dive into Our Vision, Meet the Team, and <br />
                  Discover What Drives Us
                </p>
              </div>
            </div>
            <div className='md:my-5 hidden md:block lg:flex justify-center md:justify-end h-72'>
              <img src={About} alt='no img' className='max-w-full md:ml-24 lg:mr-20  lg:max-w-full h-full md:max-w-10/11 md:mt-8 lg:mt-0' />
            </div>
          </div>
        </div>
      </div>
      {/* other components */}
      

    </>
  );
};

export default Aboutus;
