import React, { useState, useEffect } from 'react';
const Counter = () => {
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [count4, setCount4] = useState(0);

  useEffect(() => {
    const interval1 = setInterval(() => {
      setCount1((prevCount) => Math.min(prevCount + 1, 24));
    }, 50); // Update every 500 milliseconds (0.5 seconds)
    return () => clearInterval(interval1);
  }, []);

  useEffect(() => {
    const interval4 = setInterval(() => {
      setCount4((prevCount) => Math.min(prevCount + 1, 200));
    }, 5); // Update every 500 milliseconds (0.5 seconds)
    return () => clearInterval(interval4);
  }, []);

  useEffect(() => {
    const interval2 = setInterval(() => {
      setCount2((prevCount) => Math.min(prevCount + 1, 20));
    }, 50); // Update every 500 milliseconds (0.5 seconds)
    return () => clearInterval(interval2);
  }, []);

  useEffect(() => {
    const interval3 = setInterval(() => {
      setCount3((prevCount) => Math.min(prevCount + 1, 10));
    }, 50); // Update every 500 milliseconds (0.5 seconds)
    return () => clearInterval(interval3);
  }, []);

  return (
    <div className='flex flex-col md:flex-col lg:flex-row items-center justify-center md:mx-8 lg:my-8 md:my-10 my-0 mx-2  shadow-lg shadow-white pt-0 pb-6 md:pb-0 lg:pt-0 space-y-10 lg:space-y-0'>
      
      {/* Destop view */}
     

      <div className='hidden md:hidden lg:block  text-center mb-4 mt-0 lg:mb-0 lg:mr-0  md:mr-8  md:w-1/4'>
        <span className='text-4xl md:text-6xl font-bold text-darkBlue'>{count1}</span>
        <span className='text-4xl md:text-6xl font-bold text-blue ml-2 md:mr-2'>+</span>
        <p className='text-countertext font-semibold text-center text-xl md:text-[17px] mt-2'>Year's of <br />Experience</p>
      </div>
      <div className='hidden md:hidden lg:block text-center mb-4 mt-1 lg:mb-0 lg:mr-0  md:mr-8  md:w-1/4'>
        <span className='text-4xl md:text-6xl font-bold text-darkBlue text-center'>{count2}</span>
        <span className='text-4xl md:text-6xl font-bold text-blue ml-2 md:mr-2'>+</span>
        <p className='text-countertext font-semibold text-center text-xl md:text-[17px] mt-2'>Crores Tenant Rent<br/> PA</p>
      </div>
      <div className='hidden md:hidden lg:block text-center mb-4 mt-1 lg:mb-0 lg:mr-0  md:mr-8  md:w-1/4'>
        <span className='text-4xl md:text-6xl font-bold text-darkBlue text-center'>{count3}</span>
        <span className='text-4xl md:text-6xl font-bold text-blue ml-2 md:mr-2'>+</span>
        <p className='text-countertext font-semibold text-center text-xl md:text-[17px] mt-2'>Warehouse Fully <br /> Managed</p>
      </div>
      <div className=' hidden md:hidden lg:block text-center mb-4 mt-1 lg:mb-0 lg:mr-0  md:mr-8 md:w-1/4'>
        <span className='text-4xl md:text-6xl font-bold text-darkBlue text-center'>{count4}</span>
        <span className='text-4xl md:text-6xl font-bold text-blue ml-2 md:mr-2'>%</span>
        <p className='text-countertext font-semibold text-center text-xl md:text-[17px] mt-2'>YOY Market <br/> Demand</p>
      </div>
      
      {/* Mobile view */}
      
      
      <div className=' lg:hidden md:block text-center shadow-xl w-full mx-4 py-2 md:mx-4  '>
        <span className='text-4xl md:text-6xl font-bold text-darkBlue'>{count1}</span>
        <span className='text-4xl md:text-6xl font-bold text-blue ml-2 md:mr-2'>+</span>
        <p className='text-countertext font-semibold text-center text-xl md:text-[17px] mt-2'>Year' of Experience</p>
      </div>
      <div className=' lg:hidden md:block text-center shadow-xl w-full mx-4 py-2 md:mx-4  '>
        <span className='text-4xl md:text-6xl font-bold text-darkBlue text-center'>{count2}</span>
        <span className='text-4xl md:text-6xl font-bold text-blue ml-2 md:mr-2'>+</span>
        <p className='text-countertext font-semibold text-center text-xl md:text-[17px] mt-2'>Crores Successfully Raised</p>
      </div>
      <div className=' lg:hidden md:block text-center shadow-xl w-full mx-4 py-2 md:mx-4  '>
        <span className='text-4xl md:text-6xl font-bold text-darkBlue text-center'>{count3}</span>
        <span className='text-4xl md:text-6xl font-bold text-blue ml-2 md:mr-2'>+</span>
        <p className='text-countertext font-semibold text-center text-xl md:text-[17px] mt-2'>Warehouse Fully Managed</p>
      </div>
      <div className=' lg:hidden md:block text-center shadow-xl w-full mx-4 py-2 md:mx-4  '>
        <span className='text-4xl md:text-6xl font-bold text-darkBlue text-center'>{count4}</span>
        <span className='text-4xl md:text-6xl font-bold text-blue ml-2 md:mr-2'>%</span>
        <p className='text-countertext font-semibold text-center text-xl md:text-[17px] mt-2'>YOY Market Demand</p>
      </div>

    </div>

    
  );
};

export default Counter;