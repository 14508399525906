import React, { useEffect, useState } from "react";
import imeg from "../../assests/bulbs.png";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import SanityClient from "../../SanityClient";

const rioApiUrl = process.env.REACT_APP_API_URL;

const Sample = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const [item, setItem] = useState();
  const [blogs, setBlogs] = useState([]);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = blogs.slice(startIndex, endIndex);

  const totalPages = Math.ceil(blogs.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          `${rioApiUrl}/user/blogs`
        );
        const responseData = response.data?.blogs;

        if (!responseData || !Array.isArray(responseData)) {
          console.error("Invalid or empty response data");
          return;
        }

        setBlogs(responseData);
      } catch (error) {
        console.error("Error fetching blogs:", error.message);
      }
    };

    fetchBlogs();
  }, []);

  console.log("blog data", blogs);
  return (
    <>
      <div className="bg-white py-4 px-4 ">
        <h1 className="text-black text-2xl md:text-5xl lg:mr-20 font-sans  lg:ml-24 font-bold pt-8 md:pt-10 ml-4 md:ml-10">
          Investor's Academy
        </h1>
        <div className="lg:mr-[1090px] md:w-[330px]  lg:ml-auto mt-6 h-0.5 bg-mygreen  " />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-6  xl:px-28 md:px-10 ">
          {currentData.map((blog, index) => (
            <div
              key={index}
              className={` grid md:grid-cols-1 lg:grid-cols-2 card2 bg-white border-2 rounded-[18px] overflow-hidden border-b-[10px] border-r-[10px] border-black mt-${
                index === 2 ? 3 : 1
              } ${index === 2 ? "md:mr-2" : ""} w-full md:w-full md:px-0 `}
            >
              <img
                className="object-cover rounded-t-lg md:w-full h-fit md:h-[330px] w-full mt-0.5 "
                src={blog.img_url}
                alt={`Card ${index}`}
              />
              <div className="pl-2">
                <div className="flex pt-6">
                  <button className="text-sm rounded-lg p-1 md:w-28 w-32 h-10 bg-second text-black bg-slate-200 ">
                    {blog.author ? blog.author : "Author"}
                  </button>
                  <br />
                  <h3 className="text-sm text-third p-2 ">
                    {new Date(blog.updated_at).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </h3>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <h1 className="text-black mt-2 md:mt-3 ml-0 lg:h-[140px] md:mb-3 md:ml-0 lg:ml-0 md:px-0 text-xl md:text-xl font-bold">
                    {blog.heading}
                  </h1>
                  <Link
                    to={`/blog/${blog.id}`}
                    className="flex justify-start b-0 mt-10"
                  >
                    <button className="bg-mygreen font-bold text-black py-2 px-4 rounded-md mb-4 border-black border-b-[3px] border-r-[3px] border-l-[1px] border-t-[1px]">
                      Read More
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="text-white bg-white pt-14 flex justify-center">
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index + 1}
              onClick={() => setCurrentPage(index + 1)}
              className={`rounded-[50%] ${
                currentPage === index + 1
                  ? "bg-mygreen"
                  : "bg-gray hover:bg-gray-600"
              } ${
                index + 1 === totalPages
                  ? "waspect-square w-11 h-11"
                  : "aspect-square w-11 h-11"
              } text-black text-center ml-${
                index + 1 === 1 ? "0" : "2"
              } mt-auto mb-1`}
            >
              {index + 1}
            </button>
          ))}
        </div>

        <div className=" flex flex-row items-center justify-center mt-4">
          {currentPage < totalPages && (
            <button
              onClick={handleNextPage}
              className="bg-mygreen font-bold text-black py-2 px-4 ml-2 rounded-md"
            >
              Next
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Sample;
