import Slider from "react-slick";
import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../../assests/projects.png";
import Vector from "../../assests/Vector (2).png";
import { FaStar } from "react-icons/fa";

import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const CurrentProjects = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1390,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1184,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },

      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);

  const [activeTab, setActiveTab] = useState("DESCRIPTION");

  const renderContent = () => {
    switch (activeTab) {
      case "DESCRIPTION":
        return (
          <div className="text-white text-[12px] mb-4 text-justify">
            <p>
              <b>Description</b>
            </p>
            <p>
              Commercial warehouse located in the prime location of
              Sriperumbudur. Waiting to be fully funded.
            </p>
          </div>
        );
      case "NEAR BY":
        return (
          <div>
            <div className="text-white text-[12px] mb-4  text-justify">
              <p>
                <b>Tenant</b>
              </p>
              <p>
                Commercial warehouse located in the prime location of
                Sriperumbudur. Waiting to be fully funded.
              </p>
            </div>
          </div>
        );
      case "MAP":
        return (
          <div>
            <div className="text-white text-[12px] mb-4  text-justify">
              <p>
                <b>Map</b>
              </p>
              <p>
                Commercial warehouse located in the prime location of
                Sriperumbudur. Waiting to be fully funded.
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  const [activTab, setActivTab] = useState("DESCRIPTION");

  const renderConten = () => {
    switch (activTab) {
      case "DESCRIPTION":
        return (
          <div className="text-white text-[12px] mb-4 text-justify">
            <p>
              <b>Description</b>
            </p>
            <p>
              Commercial warehouse located in the prime location of
              Sriperumbudur. Waiting to be fully funded.
            </p>
          </div>
        );
      case "NEAR BY":
        return (
          <div>
            <div className="text-white text-[12px] mb-4  text-justify">
              <p>
                <b>Tenant</b>
              </p>
              <p>
                Commercial warehouse located in the prime location of
                Sriperumbudur. Waiting to be fully funded.
              </p>
            </div>
          </div>
        );
      case "MAP":
        return (
          <div>
            <div className="text-white text-[12px] mb-4  text-justify">
              <p>
                <b>Map</b>
              </p>
              <div>
                <p>
                  Commercial warehouse located in the prime location of
                  Sriperumbudur. Waiting to be fully funded.
                </p>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  const [actiTab, setActiTab] = useState("DESCRIPTION");

  const renderConte = () => {
    switch (actiTab) {
      case "DESCRIPTION":
        return (
          <div className="text-white text-[12px] mb-4 text-justify">
            <p>
              <b>Description</b>
            </p>
            <p>
              Commercial warehouse located in the prime location of
              Sriperumbudur. Waiting to be fully funded.
            </p>
          </div>
        );
      case "NEAR BY":
        return (
          <div>
            <div className="text-white text-[12px] mb-4  text-justify">
              <p>
                <b>Tenant</b>
              </p>
              <p>
                Commercial warehouse located in the prime location of
                Sriperumbudur. Waiting to be fully funded.
              </p>
            </div>
          </div>
        );
      case "MAP":
        return (
          <div>
            <div className="text-white text-[12px] mb-4  text-justify">
              <p>
                <b>Map</b>
              </p>
              <p>
                Commercial warehouse located in the prime location of
                Sriperumbudur. Waiting to be fully funded.
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="hidden lg:flex lg:mt-20 mb-10 w-full overflow-hidden">
      <div className="w-4/12">
        <h1 className="text-black font-bold text-left text-4xl relative pl-14">
          Exciting Ventures {<br className="md:block hidden" />} Ahead
          <div className="border-b-4  border-mygreen  md:absolute right-52  bottom-0 top-24 w-1/1 w-full font-sans"></div>
        </h1>

        <div className="flex  md:mx-12  mx-16 lg:mt-8 md:mt-8  items-center mb-2">
          <img src={Vector} className="" />
          <p className="text-slidevlue  ml-2 text-center text-base">
            Secure Your Spot Now
          </p>
        </div>
        <div className="flex  md:mx-12 mx-16 items-center">
          <img src={Vector} />
          <p className="text-slidevlue text-center ml-2 text-base">
            Unlock Warehouse Efficiency Today
          </p>
        </div>
      </div>

      <div className="lg:w-8/12 md:w-auto  ">
        <Slider {...settings}>
          {/* Slide 1 */}
          <div className="font-inter ">
            <div className="flex justify-end  z-10 "></div>
            <div className="flex flex-col    lg:flex-row ">
              <img
                src={img1}
                alt="Slide 1"
                className="md:h-[500px]  lg:w-40 md:w-[760px] h-[300px] w-full hidden   rounded-tl-[18px] rounded-bl-[8px] border-blue border-b-8"
              />
              <div className="bg-blue md:w-[780px] lg:w-auto rounded-br-[16px] rounded-tr-[16px] border-b-[8px] border-r-[8px]  border-blue">
                <div className=" bg-black  lg:w-[300px] sm:w-[500px] md:w-[760px] rounded-tr-[18px] rounded-br-[18px] h-full">
                  <div className="flex justify-end  ">
                    <button class="text-black  bg-[#00FF00] font-bold mx-2 p-2 text-xs rounded-lg">
                      Capital Protected
                    </button>
                    <button className="text-black  bg-[#00FF00] font-bold mx-2 p-2  text-xs rounded-lg">
                      Trending
                    </button>
                  </div>
                  <p className="text-mygreen px-4 text-xs font-bold md:mb-0 pt-9">
                    Real Estate 1
                  </p>
                  <div className="md:ml-0 px-4">
                    <h1 className="text-white text-lg md:text-1xl ">CRE-II</h1>
                    <p className="text-gray-200 text-sm mb-4">Mappedu</p>
                    <div className="flex items-end justify-between gap-1">
                      <div className="flex flex-col">
                        <div className="text-white text-sm">67%</div>
                        <div
                          className="mt-2"
                          style={{
                            width: "400%",
                            height: "5px",
                            backgroundColor: "#ddd",
                            borderRadius: "4px",
                            overflow: "hidden",
                            color: "#2272FF",
                          }}
                        >
                          <div
                            style={{
                              width: `67%`,
                              height: "100%",
                              backgroundColor: "green",
                              borderRadius: "4px",
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <p className="text-lg font-bold text-white">
                          ₹ 1.2 Crore
                        </p>
                      </div>
                    </div>
                    <br />

                    {/* Tab section 1*/}
                    <div>
                      <div className="flex absolute -m-4 ml-0   flex-row md:mr-3 mb-2 ">
                        <button
                          className={`text-xs text-white ${
                            activeTab === "DESCRIPTION"
                              ? "border-b-2  border-lightblue"
                              : ""
                          }`}
                          onClick={() => setActiveTab("DESCRIPTION")}
                        >
                          DESCRIPTION
                        </button>
                        <button
                          className={`px-5 text-xs text-white ml-1 ${
                            activeTab === "NEAR BY"
                              ? "border-b-2 border-lightblue"
                              : ""
                          }`}
                          onClick={() => setActiveTab("NEAR BY")}
                        >
                          TENANT
                        </button>
                        {/* <button
                          className={`px-2 text-xs text-white ml-1 ${
                            activeTab === "MAP"
                              ? "border-b-2 border-lightblue"
                              : ""
                          }`}
                          onClick={() => setActiveTab("MAP")}
                        >
                          MAP
                        </button> */}
                      </div>
                      <hr className="top-1 lg:mb-3 text-gray" />
                      {renderContent()}
                    </div>

                    <div className="flex  font-sans flex-row mb-0 text-white gap-2">
                      <p className="px-1 text-xs">ROI %</p>
                      <p className="pl-6 text-xs">TENURE</p>
                      <p className="pl-2 text-xs">MIN INVESTING</p>
                    </div>
                    <div className="flex gap-4 mt-2 md:mt-0 ">
                      <button className="bg-gray rounded-md px-2 py-1 text-xs text-gray-200 bg-[#26272a] ">
                        18% PA
                      </button>
                      <button className="bg-gray rounded-md px-3 py-1 text-xs text-gray-200 bg-[#26272a] ">
                        4 Yr
                      </button>
                      <button className="bg-gray rounded-md px-3 py-1 text-xs text-gray-200 bg-[#26272a] ">
                        1 Lakh
                      </button>
                    </div>
                    <br />
                    <button
                      className="bg-black border-mygreen w-full  border text-xs font-bold rounded-md p-0 text-white py-2 px-0 hover:bg-mygreen hover:text-black duration-300"
                      onClick={() =>
                        (window.location.href =
                          "https://riofinadmin.whytap.tech/")
                      }
                    >
                      INVEST NOW
                    </button>
                    <br />
                    <br />
                    <Link to="/form">
                      {" "}
                      <button className="bg-mygreen w-full  rounded-md text-white text-xs font-bold px-16 py-2 whitespace-nowrap mb-[21px] hover:scale-105 transition-transform duration-300 ease-in-out transform">
                        TALK TO US
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 2nd box */}

          <div className="font-inter">
            <div className="flex flex-col   lg:flex-row ">
              <img
                src={img1}
                alt="Slide 1"
                className="md:h-[500px] lg:w-40 md:w-[780px] h-[300px] w-full hidden md:block  rounded-tl-[18px] border-blue border-b-8 rounded-bl-[16px] "
              />
              <div className="bg-slidevlue md:w-[780px] lg:w-auto  rounded-br-[16px] rounded-tr-[16px] border-b-[8px] border-r-[8px]  border-blue">
                <div className=" bg-black h-full  lg:w-[300px] sm:w-[500px] md:w-[760px] rounded-tr-[18px] rounded-br-[18px]">
                  <div className="flex justify-end  md:ml-2 mb-6">
                    <button className="bg-mygreen text-xs font-bold p-2 rounded-md mb-1">
                      Capital Protected
                    </button>
                    <button className="bg-mygreen mx-2 text-xs p-2   font-bold rounded-md mb-1">
                      Trending
                    </button>
                  </div>
                  <p className="text-mygreen px-4 text-xs font-bold md:mb-0">
                    Real Estate 2
                  </p>
                  <div className="md:ml-0 px-4">
                    <h1 className="text-white text-lg md:text-1xl ">CRE-II</h1>
                    <p className="text-gray-200 text-sm mb-4">Mappedu</p>
                    <div className="flex items-end justify-between gap-1">
                      <div className="flex flex-col">
                        <div className="text-white text-sm">67%</div>
                        <div
                          className="mt-2"
                          style={{
                            width: "400%",
                            height: "5px",
                            backgroundColor: "#ddd",
                            borderRadius: "4px",
                            overflow: "hidden",
                            color: "#2272FF",
                          }}
                        >
                          <div
                            style={{
                              width: `67%`,
                              height: "100%",
                              backgroundColor: "green",
                              borderRadius: "4px",
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <p className="text-lg font-bold text-white">
                          ₹ 1.2 Crore
                        </p>
                      </div>
                    </div>
                    <br />

                    {/* Tab section 2 */}
                    <div>
                      <div className="flex absolute -m-4 ml-0   flex-row md:mr-3 mb-2 ">
                        <button
                          className={`text-xs text-white ${
                            activTab === "DESCRIPTION"
                              ? "border-b-2  border-lightblue"
                              : ""
                          }`}
                          onClick={() => setActivTab("DESCRIPTION")}
                        >
                          DESCRIPTION
                        </button>
                        <button
                          className={`px-5 text-xs text-white ml-1 ${
                            activTab === "NEAR BY"
                              ? "border-b-2 border-lightblue"
                              : ""
                          }`}
                          onClick={() => setActivTab("NEAR BY")}
                        >
                          TENANT
                        </button>
                        {/* <button
                          className={`px-2 text-xs text-white ml-1 ${
                            activTab === "MAP"
                              ? "border-b-2 border-lightblue"
                              : ""
                          }`}
                          onClick={() => setActivTab("MAP")}
                        >
                          MAP
                        </button> */}
                      </div>
                      {/* <hr className='top-1 lg:mb-3' /> */}
                      <hr className="top-1 lg:mb-3 text-gray" />
                      {renderConten()}
                    </div>

                    <div className="flex  font-sans flex-row mb-0 text-white gap-2">
                      <p className="px-1 text-xs">ROI %</p>
                      <p className="pl-6 text-xs">TENURE</p>
                      <p className="pl-2 text-xs">MIN INVESTING</p>
                    </div>
                    <div className="flex gap-4 mt-2 md:mt-0">
                      <button className="bg-gray rounded-md px-2 py-1 text-xs text-gray-200 bg-[#26272a] ">
                        18% PA
                      </button>
                      <button className="bg-gray rounded-md px-3 py-1 text-xs text-gray-200 bg-[#26272a] ">
                        4 Yr
                      </button>
                      <button className="bg-gray rounded-md px-3 py-1 text-xs text-gray-200 bg-[#26272a] ">
                        1 Lakh
                      </button>
                    </div>
                    <br />
                    <button
                      className="bg-black border-mygreen w-full hover:bg-mygreen hover:text-black duration-300  border text-xs font-bold rounded-md p-0 text-white py-2 px-0"
                      onClick={() =>
                        (window.location.href =
                          "https://riofinadmin.whytap.tech/")
                      }
                    >
                      INVEST NOW
                    </button>
                    <br />
                    <br />
                    <Link to="/form">
                      {" "}
                      <button className="bg-mygreen w-full  rounded-md text-white text-xs font-bold px-16 py-2 whitespace-nowrap mb-[21px] hover:scale-105 transition-transform duration-300 ease-in-out transform">
                        TALK TO US
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Slide 3 */}
          <div className="font-inter">
            <div>
              <div
                className="flex flex-col md:flex-row
     "
              >
                <img
                  src={img1}
                  alt="Slide 1"
                  className="h-[500px] w-40  object-cover rounded-tl-[18px] rounded-bl-[18px] border-blue  border-b-8"
                />
                <div className="bg-slidevlue rounded-br-[16px] rounded-tr-[16px] border-b-[8px] border-r-[8px]  border-blue">
                  <div className=" bg-black h-full w-[300px] rounded-tr-[18px] rounded-br-[18px]">
                    <div className="flex justify-end   md:ml-2 mb-6  ">
                      <button className="bg-mygreen  top-[-10px] text-xs font-bold p-2 rounded-md mb-1">
                        Capital Protected
                      </button>
                      <button className="bg-mygreen mx-2 text-xs p-2 font-bold rounded-md mb-1">
                        Trending
                      </button>
                    </div>
                    <p className="text-mygreen px-4 text-xs font-bold md:mb-0">
                      Real Estate 3
                    </p>
                    <div className="md:ml-0 px-4">
                      <h1 className="text-white text-lg md:text-1xl ">
                        CRE-II
                      </h1>
                      <p className="text-gray-200 text-sm mb-4">Mappedu</p>
                      <div className="flex items-end justify-between gap-1">
                        <div className="flex flex-col">
                          <div className="text-white text-sm">67%</div>
                          <div
                            className="mt-2"
                            style={{
                              width: "400%",
                              height: "5px",
                              backgroundColor: "#ddd",
                              borderRadius: "4px",
                              overflow: "hidden",
                              color: "#2272FF",
                            }}
                          >
                            <div
                              style={{
                                width: `67%`,
                                height: "100%",
                                backgroundColor: "green",
                                borderRadius: "4px",
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          <p className="text-lg font-bold text-white">
                            ₹ 1.2 Crore
                          </p>
                        </div>
                      </div>
                      <br />

                      {/* Tab section 3 */}
                      <div>
                        <div className="flex absolute -m-4 ml-0   flex-row md:mr-3 mb-2 ">
                          <button
                            className={`text-xs text-white ${
                              actiTab === "DESCRIPTION"
                                ? "border-b-2  border-lightblue"
                                : ""
                            }`}
                            onClick={() => setActiTab("DESCRIPTION")}
                          >
                            DESCRIPTION
                          </button>
                          <button
                            className={`px-5 text-xs text-white ml-1 ${
                              actiTab === "NEAR BY"
                                ? "border-b-2 border-lightblue"
                                : ""
                            }`}
                            onClick={() => setActiTab("NEAR BY")}
                          >
                            TENANT
                          </button>
                          {/* <button
                            className={`px-2 text-xs text-white ml-1 ${
                              actiTab === "MAP"
                                ? "border-b-2 border-lightblue"
                                : ""
                            }`}
                            onClick={() => setActiTab("MAP")}
                          >
                            MAP
                          </button> */}
                        </div>
                        <hr className="top-1 lg:mb-3 text-gray" />
                        {renderConte()}
                      </div>

                      <div className="flex flex-col font-sans md:flex-row mb-0 text-white gap-2">
                        <p className="px-1 text-xs">ROI %</p>
                        <p className="pl-6 text-xs">TENURE</p>
                        <p className="pl-2 text-xs">MIN INVESTING</p>
                      </div>
                      <div className="flex gap-4 mt-2 md:mt-0">
                        <button className="bg-gray rounded-md px-2 py-1 text-xs text-gray-200 bg-[#26272a] ">
                          18% PA
                        </button>
                        <button className="bg-gray rounded-md px-3 py-1 text-xs text-gray-200 bg-[#26272a] ">
                          4 Yr
                        </button>
                        <button className="bg-gray rounded-md px-3 py-1 text-xs text-gray-200 bg-[#26272a] ">
                          1 Lakh
                        </button>
                      </div>
                      <br />
                      <button
                        className="bg-black border-mygreen w-full hover:bg-mygreen hover:text-black duration-300 border text-xs font-bold rounded-md p-0 text-white py-2 px-0"
                        onClick={() =>
                          (window.location.href =
                            "https://riofinadmin.whytap.tech/")
                        }
                      >
                        INVEST NOW
                      </button>
                      <br />
                      <br />
                      <Link to="/form">
                        {" "}
                        <button className="bg-mygreen w-full  rounded-md text-white text-xs font-bold px-16 py-2 whitespace-nowrap mb-[21px] hover:scale-105 transition-transform duration-300 ease-in-out transform">
                          TALK TO US
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Add more slides with content as needed */}
        </Slider>
      </div>
    </div>
  );
};

export default CurrentProjects;
