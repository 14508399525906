import React, { useEffect, useState } from "react";
import Img2 from "../../assests/img3.png";
import Faqtt from "./Faqtt";
import SanityClient from "../../SanityClient";
import { Helmet } from "react-helmet";

const Faq = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [data, setData] = useState(null);
  useEffect(() => {
    const getdata = () => {
      SanityClient.fetch(
        `*[_type=="faq"]{
          metaTitle,
          metaDescriptions,
          MetaDate,
          FocusKeywords,
          MetaURL
        }`
      )
        .then((res) => {
          setData(res[0]);
          console.log(res, "faq");
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getdata();
  }, []);
  return (
    <>
      <Helmet>
        <title property="og:title">{data ? data.metaTitle : ""}</title>
        <meta
          property="og:description"
          content={data ? data.MetaDescription : ""}
        />
        <meta property="og:url" content={data ? data.MetaData : ""} />
        <meta name="keywords" content={data ? data.FocusKeyword : ""}></meta>
        <meta name="alldata" content={data ? data.MetaURL : ""}></meta>
      </Helmet>
      <div
        className="faq bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${Img2})` }}
      >
        <div className="font-inter">
          <hr className="faq_hr" />
          <h1 className="text-4xl md:text-5xl lg:text-7xl text-white py-4">
            FAQ'S
          </h1>
          <p className="text-white block md:hidden text-justify  pr-6 pb-7 text-xl">
            Find answers to common questions about Riofin's services, warehouse
            buying, selling, and asset management processes. Learn how we can
            help streamline your warehouse investment journey.
          </p>

          <p className="text-white hidden md:block text-justify mt-5 text-xl">
            Find answers to common questions about Riofin's services,
            <br />
            warehouse buying, selling, and asset management processes.
            <br />
            Learn how we can help streamline your warehouse
            <br />
            investment journey.
          </p>
        </div>

        <div className="flex flex-col md:flex-row md:w-full lg:w-3/4 xl:w-3/5 sm:w-1/2 mx-auto px-5">
          <Faqtt></Faqtt>
        </div>
      </div>
    </>
  );
};

export default Faq;
