import React from 'react';
import BackgroundImage from '../../assests/diverse.png';
import hand from '../../assests/handass.png';

const Dis = () => {
    const cardStyle1 = {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      };

  return (
  <div className='w-full md:py-32 py-14 'style={cardStyle1}>

       <div className="text-center text-white  mt-3"  >
          <h1 className="font-bold text-xl md:text-4xl lg:text-5xl mx-4 md:mx-6 lg:mx-12">
            Diversification of Asset Leasing
          </h1>
         <div className="bg-mygreen hidden lg:block h-0.5 w-7/12 absolute left-0 mb-6 mt-4" />

          <p className="mx-3 md:mx-6 lg:mx-12 text-[16px] md:text-2xl lg:text-2xl mt-4 md:mt-6 lg:mt-8">
          Diversifying assets through leasing offers a unique approach to balance risk and rewards while optimizing income streams

          </p>
       </div>

       <div className="flex flex-col md:flex-row items-center md:justify-center md:items-center lg:justify-evenly md:mx-4 lg:mx-8 mt-6 md:mt-8 lg:mt-12">
          <div className=" md:w-1/2 lg:w-auto px-2  md:mx-4 lg:mx-6 text-white">
            {renderEquipmentBlock(hand, "Risk Mitigation: Diversify assets for resilient portfolios.")}
            {renderEquipmentBlock(hand, "Steady Income Streams: Ensure stable, predictable cash flow.")}
            {/* {renderEquipmentBlock(hand, "Manufacturing and Production Equipment.")} */}
          </div>

          <div className=" md:w-1/2 lg:w-auto px-2  md:mx-4 lg:mx-6 text-white">
            {renderEquipmentBlock(hand, " Industry Exposure: Access diverse market opportunities.")}
            {renderEquipmentBlock(hand, "Optimized Returns: Enhance returns through strategic diversification.")}
            {/* {renderEquipmentBlock(hand, "Manufacturing and Production Equipment.")} */}
          </div>
        </div>
    <div>

    </div>
  </div>
  );
};
const renderEquipmentBlock = (imgSrc, text) => (
    <div className="flex items-center mt-3">
      <img
        src={imgSrc}
        alt="Your Alt Text"
        className="w-8 h-8 rounded-md mr-2 md:mr-4 "
      />
      <p>{text}</p>
    </div>
  );

export default Dis;
