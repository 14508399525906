import React, { useState } from 'react';
import backgroundImage from '../../assests/Rectangle 4681.png';
import { FaSearch } from 'react-icons/fa';

const Bannersection = () => {
  const [content, setContent] = useState('');

  const handleInputChange = (event) => {
    setContent(event.target.value);
  };

  return (
    <div className="relative hidden md:block mb-6 md:mt-1 lg:mt-0">
      {/* Background image */}
      <img
        src={backgroundImage}
        className="w-full h-full object-cover object-center inset-1"
        alt="Background"
      />

      {/* Overlay content */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
        <hr className="bg-mygreen mb-7 p-1 px-8 md:px-16 lg:px-40"></hr>
        <h1 className="font-bold font-sans text-[26px] md:text-4xl lg:text-5xl mb-5 text-black text-center">
          How can we help?
        </h1>
        {/* <div className="flex items-left bg-white rounded-full p-1 mt-4 max-w-md mx-auto lg:max-w-xl lg:p-2">
  <input
    type="search"
    placeholder="Tell us about the issue"
    className="text-black md:mx-40 lg:ml-0 border-none  focus:ring-0  text-[18px]"
    style={{ textAlign: 'left' }}
  />
  <FaSearch className='text-gray-500 mt-1'/>
</div> */}



      </div>
    </div>
  );
};

export default Bannersection;
