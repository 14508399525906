import React from "react";
import Image5 from "../../assests/pa-5.png";
import Image6 from "../../assests/pa-6.png";
import Image7 from "../../assests/pa-7.png";
import Image8 from "../../assests/pa-8.png";

const WarehouseManagement1 = () => {
  return (
    <div className="mx-auto px-4 sm:px-6 mb-4   lg:px-0 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-0 lg:grid-cols-4  gap-4">
      {/* Box 5 */}
      <div className=" text-black flex flex-col items-center justify-center border-none hidden  md:hidden lg:block ">
        <img src={Image5} className="lg:hidden" alt="Image 1" />
        <h2 className="box-title lg:pr-8 lg:mr-10 lg:mb-2 text-center sm:text-center md:text-center font-bold text-[#216DFD] ">
          Comprehensive Asset Evaluation
        </h2>
        <p className="box-content  lg:pr-4 lg:pl-1 lg:mr-14 text-center">
          Riofin conducts thorough evaluations of warehouse assets, assessing
          factors such as location, condition, and potential for value
          appreciation, ensuring optimal management strategies for clients.
        </p>
      </div>
      {/* Mobile view box 5 */}
      <div className=" text-black flex flex-col items-center justify-center lg:hidden  border shadow-xl pt-4 pb-4 pr-8 pl-8  md:pt-4 md:pb-4">
        <img src={Image5} className="" alt="Image 1" />
        <h2 className="box-title lg:pr-8 lg:mr-10 lg:mb-2 text-center sm:text-center md:text-center font-bold text-[#216DFD] ">
          Comprehensive Asset Evaluation
        </h2>
        <p className="box-content  lg:pr-4 lg:pl-1 lg:mr-10 text-center">
          Riofin conducts thorough evaluations of warehouse assets, assessing
          factors such as location, condition, and potential for value
          appreciation, ensuring optimal management strategies for clients.
        </p>
      </div>

      {/* Box 6 */}
      <div className=" text-black flex flex-col  items-center justify-center border-none hidden  md:hidden lg:block ">
        <img src={Image7} className="lg:hidden" alt="Image 3" />
        <h2 className="box-title lg:pr-0 lg:mr-0 lg:mb-2 text-center sm:text-center md:text-center font-bold text-[#216DFD] ">
          Tenant Management Expertise
        </h2>
        <p className="box-content ml-6 mr-8 text-center">
          With expertise in tenant management, Riofin ensures efficient and
          effective tenant placement, lease negotiation, and ongoing
          communication, maximizing occupancy rates and rental income for
          clients.
        </p>
      </div>
      {/* Mobile view box 6 */}
      <div className="ext-black flex flex-col items-center justify-center lg:hidden  border shadow-xl pt-4 pb-4  md:pt-4 md:pb-4 ">
        <img src={Image6} className="" alt="Image 2" />
        <h2 className="box-title lg:pr-4 lg:mr-0 mt-2 text-center sm:text-center md:text-center font-bold text-[#216DFD]">
          Tenant Management Expertise
        </h2>
        <p className="box-content ml-8 mr-8  text-center">
          With expertise in tenant management, Riofin ensures efficient and
          effective tenant placement, lease negotiation, and ongoing
          communication, maximizing occupancy rates and rental income for
          clients.
        </p>
      </div>
      {/* Box 7 */}
      <div className=" text-black flex flex-col ml-8 items-center justify-center border-none hidden  md:hidden lg:block ">
        <img src={Image7} className="lg:hidden" alt="Image 3" />
        <h2 className="box-title lg:pr-0 lg:mr-0 lg:mb-2 text-center sm:text-center md:text-center font-bold text-[#216DFD] ">
          Proactive Maintenance Solutions
        </h2>
        <p className="box-content ml-8 mr-8 text-center">
          Riofin implements proactive maintenance strategies to upkeep warehouse
          properties, addressing issues promptly and ensuring optimal
          functionality, safety, and value preservation for clients.
        </p>
      </div>
      {/* Mobile view box 7 */}
      <div className="text-black flex flex-col items-center justify-center lg:hidden  border shadow-xl pt-4 pb-4  md:pt-4 md:pb-4">
        <img src={Image7} className="" alt="Image 2" />
        <h2 className="box-title lg:pr-4 lg:mr-0 mt-2 text-center sm:text-center md:text-center font-bold text-[#216DFD]">
          Proactive Maintenance Solutions
        </h2>
        <p className="box-content ml-8 mr-8  text-center">
          Riofin implements proactive maintenance strategies to upkeep warehouse
          properties, addressing issues promptly and ensuring optimal
          functionality, safety, and value preservation for clients.
        </p>
      </div>
      {/* Box 8 */}
      <div className=" text-black flex flex-col items-center justify-center border-none hidden  md:hidden lg:block">
        <img src={Image8} className="lg:hidden" alt="Image 4" />
        <h2 className="box-title lg:pl-20 lg:mr-0 lg:mb-2 text-center sm:text-center md:text-center font-bold text-[#216DFD] ">
          Transparent Financial Reporting
        </h2>
        <p className="box-content ml-16 mr-1 text-center">
          Riofin provides transparent financial reporting to clients, offering
          detailed insights into income, expenses, and overall performance of
          warehouse assets, fostering trust and accountability in asset
          management.
        </p>
      </div>
      {/* Mobile view box 8 */}
      <div className="text-black flex flex-col items-center justify-center lg:hidden  border shadow-xl pt-4 pb-4  md:pt-4 md:pb-4">
        <img src={Image8} className="" alt="Image 2" />
        <h2 className="box-title lg:pr-4 lg:mr-0 mt-2 text-center sm:text-center md:text-center font-bold text-[#216DFD]">
          Transparent Financial Reporting
        </h2>
        <p className="box-content ml-8 mr-8  text-center">
          Riofin provides transparent financial reporting to clients, offering
          detailed insights into income, expenses, and overall performance of
          warehouse assets, fostering trust and accountability in asset
          management.
        </p>
      </div>
    </div>
  );
};

export default WarehouseManagement1;
