import React, { useEffect } from "react";
import Icon1 from "../../assests/h-icon (2).png";
import Icon2 from "../../assests/hicon (1).png";
import Icon3 from "../../assests/h-icon.png";
import BackgroundImage1 from "../../assests/card1.png";
import BackgroundImage2 from "../../assests/card2.png";
import BackgroundImage3 from "../../assests/group-people.png";
import Button1 from "../../assests/h- icon _circle check_.png";
import Button2 from "../../assests/h- icon _circle check_.png";
import Button3 from "../../assests/h- icon _circle check_.png";
import Aos from "aos";
import "aos/dist/aos.css";

function RiofinAssets() {
  const cardStyle1 = {
    backgroundImage: `url(${BackgroundImage1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "370px",
    height: "392px",
  };

  const cardStyle2 = {
    backgroundImage: `url(${BackgroundImage2})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "370px",
    height: "392px",
  };

  const cardStyle3 = {
    backgroundImage: `url(${BackgroundImage3})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "370px",
    height: "392px",
  };
  const cardStyle4 = {
    backgroundImage: `url(${BackgroundImage1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%", // Set to full width on mobile
    height: "320px",
  };

  const cardStyle5 = {
    backgroundImage: `url(${BackgroundImage2})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%", // Set to full width on mobile
    height: "320px",
  };

  const cardStyle6 = {
    backgroundImage: `url(${BackgroundImage3})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%", // Set to full width on mobile
    height: "320px",
  };

  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);

  return (
    <div className="bg-gradient-to-b from-blue to-indigo-800 pt-10  md:pb-8">
      <h1 className="text-white font-bold text-center text-[26px] font-sans md:text-4xl  pl-7 pr-7 ">
        What Is RIOFIN ASSETS?
      </h1>
      <div
        className="md:ml-[650px]   md:mr-auto mt-3 h-0.5 bg-mygreen hidden md:block"
        data-aos="zoom-in-right"
      />
      <p className="text-white text-center  px-4 md:px-32 md:text-2xl  mt-6   font-sans">
        At Riofin: Streamlining Warehouse Transactions, Seamlessly Matching
        Owners and Tenants.
      </p>
      <div
        className="hidden md:flex flex-col lg:flex-row md:flex-col items-center justify-center mt-10 p-4 gap-4 md:gap-8"
        data-aos="fade-up"
      >
        {/* ... Your card components ... */}
        {/* First Image Box */}
        <div
          className=" md:w-1/3 relative bg-gray-800 rounded-[16px] overflow-hidden border-b-8 border-r-8 border-black mb-8 md:mb-0 md:h-[250px] h-[300px] w-[100px]"
          style={cardStyle1}
        >
          {/* Content */}
          <div className="md:mt-6">
            <div className="mb-4 text-center">
              <img
                src={Icon1}
                alt="Card 1"
                className="w-10 mb-3 mt-3 h-10 md:w-16 md:h-16 mx-auto rounded"
              />
              <h2 className="text-xl  font-sans font-bold text-black">
                Revolutionizing Warehouse <br /> Management
              </h2>
            </div>
            {/* Description */}
            <p className="text-gray-800 text-sm text-center mb-4 px-5">
              Riofin pioneers innovative strategies, seamlessly connecting
              warehouse buyers and sellers, and efficiently matching tenants
              with warehouse owners, forging unique pathways for financial
              growth and diversification.
            </p>
            <img
              src={Button1}
              alt="button1"
              className="w-10 mb-3 mt-3 h-10 ab md:w-16 md:h-16 mx-auto rounded absolute bottom-0 left-1/2 -translate-x-1/2"
            />
          </div>
        </div>

        {/* Second Card */}
        <div
          className="w-full md:w-1/3 bg-gray-800 rounded-[16px] relative overflow-hidden border-b-8 border-r-8 border-black mb-8 md:mb-0 md:h-[400px]"
          style={cardStyle2}
        >
          {/* Content */}
          <div className="md:mt-6">
            <div className="mb-4 text-center">
              <img
                src={Icon2}
                alt="Card 2"
                className="w-10 mb-3 mt-3 h-10 md:w-16 md:h-16 mx-auto rounded"
              />
              <h2 className="text-xl  font-sans font-bold text-black">
                Breaking Boundaries Beyond
                <br /> Traditional Assets
              </h2>
            </div>
            {/* Description */}
            <p className="text-gray-800 font- text-sm text-center mb-4 px-5">
              Blazing Trails in Unconventional Opportunities for Unmatched
              Financial Growth and Diversification.
            </p>
            <img
              src={Button2}
              alt="button2"
              className="w-10 mb-3 mt-3 h-10 md:w-16 md:h-16 mx-auto rounded absolute bottom-0 left-1/2 -translate-x-1/2"
            />
          </div>
        </div>

        {/* Third Card */}
        <div
          className="w-full md:w-1/3 bg-gray-800 relative rounded-[16px] overflow-hidden border-b-8 border-r-8 border-black md:h-[400px]"
          style={cardStyle3}
        >
          {/* Content */}
          <div className="md:mt-6">
            <div className="mb-5 text-center">
              <img
                src={Icon3}
                alt="Card 3"
                className="w-10 mb-3 mt-3 h-10 md:w-16 md:h-16 mx-auto rounded"
              />
              <h2 className="text-xl  font-sans font-bold text-black">
                Join our Strong Network of 20+
                <br className="md:block hidden" /> Warehouse Clients
              </h2>
            </div>
            {/* Description */}
            <p className="text-gray-800 font- text-sm -mt-1 text-center mb-4 px-5">
              Embrace the movement! Be part of our community ushering in a new
              era of warehouse success with Riofin.
            </p>
            <img
              src={Button3}
              alt="button3"
              className="w-10 mb-3 mt-3 h-10 md:w-16 md:h-16 mx-auto rounded absolute bottom-0 left-1/2 -translate-x-1/2"
            />
          </div>
        </div>
      </div>
      <div className="md:hidden flex flex-col md:flex-row items-center justify-center mt-10 p-4 gap-4 md:gap-8">
        {/* mobile view */}

        {/* ... Your card components ... */}
        {/* First Image Box */}
        <div
          className=" md:w-1/3 bg-gray-800 rounded-lg overflow-hidden border-b-8 border-r-8 border-black mb-8 md:mb-0 md:h-[400px] h-[300px] w-[100px]"
          style={cardStyle4}
        >
          {/* Content */}
          <div className="md:mt-6">
            <div className="mb-4 text-center">
              <img
                src={Icon1}
                alt="Card 1"
                className="w-10 mb-3 mt-3 h-10 md:w-16 md:h-16 mx-auto rounded"
              />
              <h2 className="text-xl md:text-2xl font-sans font-bold text-black">
                Pioneers in
                <br /> Alternative Investments
              </h2>
            </div>
            {/* Description */}
            <p className="text-gray-800 pl-5 pr-5 text-justify mb-4">
              The process of managing a task through its life cycle. It involves
              planning, testing, tracking.
            </p>
            <img
              src={Button1}
              alt="button1"
              className="w-14 mb-3 mt-3 h-14 md:w-16 md:h-16 mx-auto rounded"
            />
          </div>
        </div>
        {/* Second Card */}
        <div
          className="w-full md:w-1/3 bg-gray-800 rounded-lg overflow-hidden border-b-8 border-r-8 border-black mb-8 md:mb-0 md:h-[400px]"
          style={cardStyle5}
        >
          {/* Content */}
          <div className="md:mt-6">
            <div className="mb-4 text-center">
              <img
                src={Icon2}
                alt="Card 2"
                className="w-10 mb-3 mt-3 h-10 md:w-16 md:h-16 mx-auto rounded"
              />
              <h2 className="text-xl md:text-2xl font-sans font-bold text-black">
                Think Beyond <br />
                Traditional Assets
              </h2>
            </div>
            {/* Description */}
            <p className="text-gray-800 pl-5 pr-5  text-justify mb-4">
              The technology by which a process or procedure is performed with
              minimal human assistance.
            </p>
            <img
              src={Button2}
              alt="button2"
              className="w-14 mb-3 mt-3 h-14 md:w-16 md:h-16 mx-auto rounded"
            />
          </div>
        </div>
        {/* Third Card */}
        <div
          className="w-full md:w-1/3 bg-gray-800 rounded-md overflow-hidden border-b-8 border-r-8 border-black md:h-[400px]"
          style={cardStyle6}
        >
          {/* Content */}
          <div className="md:mt-6">
            <div className="mb-5 text-center">
              <img
                src={Icon3}
                alt="Card 3"
                className="w-10 mb-3 mt-3 h-10 md:w-16 md:h-16 mx-auto rounded"
              />
              <h2 className="text-xl md:text-2xl font-bold font-sans text-black">
                Join 3,000+
                <br className="md:block hidden" /> Investors
              </h2>
            </div>
            {/* Description */}
            <p className="text-gray-800 pl-5 pr-5 text-justify  mb-4">
              Creating a plan to spend your money This spending planner is
              called a budgeting tool for business.
            </p>
            <img
              src={Button3}
              alt="button3"
              className="w-14 mb-3 mt-3 h-14 md:w-16 md:h-16 mx-auto rounded"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RiofinAssets;
