import React from "react";
import image1 from "../../assests/buywarehouse.png";
import image2 from "../../assests/Rectangle 4371.png";
import image3 from "../../assests/Rectangle 4372.png";

const CardSection = () => {
  return (
    <>
      <div className="bg-gradient-to-b from-[#2272FF] to-[#0D03C3]  shadow-xl shadow-black">
        <h1 className="p-10 text-white text-4xl font-bold text-center">
          Prominent Zones in Chennai
        </h1>
        <hr className="w-1/2 h-0.5 bg-mygreen" />
        <div className="p-10 flex flex-wrap justify-evenly items-center gap-10">
          <div className="w-80 h-80  relative rounded-xl border-b-8 border-r-8 border-black overflow-hidden ">
            <div className=" ">
              <img src={image1} alt="" className="w-full h-full object-cover" />
            </div>
            <div className="bg-white absolute leawidth:h-44 h-fit bottom-0 p-5 rounded-t-xl">
              <h1 className="text-blue text-center  font-bold text-xl">
                Northern zone
              </h1>
              <p className="text-xs font-semibold text-justify leading-6 homefunded:px-5 px-2">
                Crucial for efficient distribution to north markets, optimizing
                supply chain reach and customer satisfaction.
              </p>
            </div>
          </div>
          <div className=" w-80 homefunded:h-80 h-[350px] relative rounded-xl border-b-8 border-r-8 border-black overflow-hidden ">
            <div className="">
              <img src={image2} alt="" className="w-full h-full " />
            </div>
            <div className="bg-white absolute sm:h-44 bottom-0 p-5 rounded-t-xl">
              <h1 className="text-blue text-center font-bold text-xl">
                Southern zone
              </h1>
              <p className="text-xs font-semibold text-justify leading-6 homefunded:px-5 px-2">
                Access to ports facilitates seamless global distribution and
                import-export operations, enhancing market reach and
                flexibility.
              </p>
            </div>
          </div>
          <div className=" w-80 homefunded:h-80 h-[350px] relative rounded-xl border-b-8 border-r-8 border-black overflow-hidden ">
            <div className="">
              <img src={image3} alt="" className="w-full h-full " />
            </div>
            <div className="bg-white absolute bottom-0 sm:h-44 p-5 rounded-t-xl">
              <h1 className="text-blue text-center font-bold text-xl">
                Western zone
              </h1>
              <p className="text-xs font-semibold text-justify leading-6 homefunded:px-4 px-2">
                Gateway to major industrial hubs, ensuring proximity to
                manufacturing centers and efficient logistics operations for
                rapid market response.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardSection;
