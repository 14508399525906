import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaInfinity,
  FaLinkedin,
  FaLinkedinIn,
  FaFacebookMessenger,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const year = new Date();
  return (
    <footer className="bg-[#03003C] text-white">
      <section className="lg:flex grid lg:justify-evenly md:px-10 px-5 lg:px-0  py-12">
        <div className="pb-10 lg:pb-0">
          <p className="text-xl font-bold pb-5">Riofin Assets</p>
          <p>
            Pioneers in Alternative <br /> Investments
          </p>
          <div className="flex mt-3 md:mt-5 lg:mt-5">
            <a href="">
              <FaLinkedinIn className="mr-2" />
            </a>
            <a href="">
              <FaFacebookMessenger className="mr-2" />
            </a>
            <a href="">
              <FaTwitter className="mr-2" />
            </a>
            <a href="">
              <FaInfinity className="mr-2" />
            </a>
          </div>
        </div>

        <ol className="leading-9 pb-10 lg:pb-0">
          <li className="text-xl font-bold pb-3">Company</li>
          <li>
            <Link to="/aboutus">About Us</Link>
          </li>
          <li>
            <Link to="/investment">Opportunities</Link>
          </li>
          <li>
            {" "}
            <Link to="/faq">FAQ</Link>
          </li>
          <li>
            <Link to="blog">Blog</Link>
          </li>
        </ol>

        <ol className="leading-9 pb-10 lg:pb-0">
          <li className="text-xl font-bold pb-3">Resources</li>
          <li>
            <Link to="/resources/assets">Our USP</Link>
          </li>
          <li>
            <Link to="/resources/assetsleasing">Asset Leasing</Link>
          </li>
          <li>
            <Link to="/resources/fractional">Fractional Ownership</Link>
          </li>
          <li>
            <Link to="/resources/enquiry">Raise Capital</Link>
          </li>
          <li>
            <Link to="/resources/sellyourwarehouse">Sell Your Warehouse</Link>
          </li>
          <li>
            <Link to="/resources/support">Support</Link>
          </li>
        </ol>

        <div className="pb-10 lg:pb-0">
          <p className="text-xl font-bold pb-5">Join Our Newsletter</p>
          <form action="" className="pb-5">
            <ol className="flex flex-col sm:flex-row ">
              <li>
                <label htmlFor="email" className="sr-only">
                  Email Address
                </label>
                <input
                  type="mail"
                  id="email"
                  name="email"
                  className="h-12 md:w-60 w-full pl-5 mb-5 border border-gray-300 rounded-md text-black focus:outline-none focus:ring focus:ring-blue-500"
                  placeholder="Your Email Address"
                  required
                />
              </li>
              <li>
                <button
                  type="submit"
                  className="h-12 w-32 sm:-ml-3 bg-[#00FF00] text-black font-bold"
                >
                  Subscribe
                </button>
              </li>
            </ol>
          </form>
          <p className="opacity-35">
            * Will send you weekly updates for your better <br /> finance
            management.
          </p>
        </div>
      </section>

      <hr className="bg-white h-0.5" />

      <section>
        <p className=" text-center py-5">
          Copyright @ Riofin Assets {year.getFullYear()}. All Rights Reserved .
        </p>
      </section>
    </footer>
  );
};

export default Footer;
