import React, { useEffect, useState } from "react";
import build from "../../assests/build.png";

// export default function Banner() {

//   return (
//     <>

//     <div className="w-full lg:-mt-7 h-full">
//       <div className="w-full lg:h-screen h-full  relative">
//         <img
//           src={build}
//           className="w-full h-full absolute -z-10 top-0 left-0"
//         ></img>
//         <div className="bg-black bg-opacity-75 w-full h-full absolute top-0 left-0 -z-10"></div>
//         <div className="w-full h-full flex lg:flex-row gap-5 justify-between px-8 py-5  lg:px-20 items-center flex-col">
//           <div className="lg:w-1/2 w-full flex flex-col gap-5 text-white text-center lg:text-start">
//             <h1 className="md:text-[44px] 2xl:text-[55px] font-roboto  sm:text-[36px] text-[25px]  font-semibold md:leading-[60px]">
//               Chennai Warehouse Rental Solutions
//             </h1>
//             <p className="md:text-base text-[10px] 2xl:text-[20px] leading-[25px] font-roboto">
//               Our team provides an advisory approach striving to find the right
//               options by scanning the market till there are no options left.
//             </p>
//             <p className="font-semibold text-base 2xl:text-[20px] font-roboto">
//               Starting from 10,000 sqft. and beyond
//             </p>
//           </div>
//           <div className="lg:w-1/2 w-full flex lg:justify-end justify-center">
//             <form className="bg-[#D5D2D0] mb-5 flex flex-col gap-5 md:w-[65%] 2xl:w-[75%] homefunded:w-[90%] w-[95%]  border-2 border-mygreen rounded-xl sm:p-5 p-3">
//               <h1 className="sm:text-xl text-base text-blue font-bold text-center ">
//                 Locate Your Warehouse
//               </h1>
//               <div className="flex flex-col  w-[95%] mx-auto gap-1">
//                 <label className="text-xs font-semibold">Full Name</label>
//                 <input
//                   type="text"
//                   placeholder="Full Name"
//                   className="w-full inputs px-3 py-2 outline-mygreen border border-mygreen outline-1 rounded-lg"
//                 />
//               </div>
//               <div className="flex flex-col  w-[95%] mx-auto gap-1">
//                 <label className="text-xs font-semibold ">Phone Number</label>
//                 <input
//                   type="text"
//                   placeholder="+919999999999"
//                   className="w-full inputs px-3 py-2 outline-mygreen border border-mygreen outline-1 rounded-lg"
//                 />
//               </div>
//               <div className="flex flex-col  w-[95%] mx-auto gap-1">
//                 <label className="text-xs font-semibold">How can we help you?</label>
//                 <textarea
//                   rows={5}
//                   type="text"
//                   placeholder="Description"
//                   className="w-full inputs px-3 py-2 outline-mygreen border border-mygreen outline-1 rounded-lg"
//                 />
//               </div>
//               <button className="bg-mygreen font-semibold drop-shadow-lg text-sm rounded-lg w-fit px-8 py-2 mx-auto">
//                 Submit
//               </button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//     </>
//   );
// }

export default function Banner() {
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    description: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form submission tasks (e.g., send data to backend)
    console.log("Form data submitted:", formData);
    setIsSubmitted(true);
  };

  return (
    <>
      <div className="w-full lg:-mt-7 h-full">
        <div className="w-full lg:h-screen h-full relative">
          <img
            src={build}
            className="w-full h-full absolute -z-10 top-0 left-0"
            alt="Background"
          />
          <div className="bg-black bg-opacity-75 w-full h-full absolute top-0 left-0 -z-10"></div>
          <div className="w-full h-full flex lg:flex-row gap-5 justify-between px-8 py-5 lg:px-20 items-center flex-col">
            <div className="lg:w-1/2 w-full flex flex-col gap-5 text-white text-center lg:text-start">
              <h1 className="md:text-[44px] 2xl:text-[55px] font-roboto sm:text-[36px] text-[25px] font-semibold md:leading-[60px]">
                Premium Warehouse Rental Solutions
              </h1>
              <p className="md:text-base text-[10px] 2xl:text-[20px] leading-[25px] font-roboto">
                Our team provides an advisory approach striving to find the
                right options by scanning the market till there are no options
                left.
              </p>
              <p className="font-semibold text-base 2xl:text-[20px] font-roboto">
                Starting from 10,000 sqft. and beyond
              </p>
            </div>
            <div className="lg:w-1/2 w-full flex lg:justify-end justify-center">
              {isSubmitted ? (
                <div className="bg-[#D5D2D0] mb-5 flex flex-col gap-5 md:w-[65%] 2xl:w-[75%] homefunded:w-[90%] w-[95%] border-2 border-mygreen rounded-xl sm:p-5 p-3 text-center">
                  <h1 className="sm:text-xl text-base text-blue h-full font-bold">
                    Thank you! Our team will contact you within 24 hours.
                  </h1>
                  <button
                    onClick={() => {
                      setIsSubmitted(false);
                    }}
                    className="bg-blue text-white font-semibold w-fit mx-auto rounded-md px-3 py-2"
                  >
                    Contact Again
                  </button>
                </div>
              ) : (
                <form
                  onSubmit={handleSubmit}
                  className="bg-[#D5D2D0] mb-5 flex flex-col gap-5 md:w-[65%] 2xl:w-[75%] homefunded:w-[90%] w-[95%] border-2 border-mygreen rounded-xl sm:p-5 p-3"
                >
                  <h1 className="sm:text-xl text-base text-blue font-bold text-center">
                    Locate Your Warehouse
                  </h1>
                  <div className="flex flex-col w-[95%] mx-auto gap-1">
                    <label className="text-xs font-semibold">Full Name</label>
                    <input
                      type="text"
                      name="fullName"
                      placeholder="Full Name"
                      value={formData.fullName}
                      onChange={handleChange}
                      className="w-full inputs px-3 py-2 outline-mygreen border border-mygreen outline-1 rounded-lg"
                    />
                  </div>
                  <div className="flex flex-col w-[95%] mx-auto gap-1">
                    <label className="text-xs font-semibold">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      name="phoneNumber"
                      placeholder="+91 9999999999"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      className="w-full inputs px-3 py-2 outline-mygreen border border-mygreen outline-1 rounded-lg"
                    />
                  </div>
                  <div className="flex flex-col w-[95%] mx-auto gap-1">
                    <label className="text-xs font-semibold">
                      How can we help you?
                    </label>
                    <textarea
                      rows={5}
                      name="description"
                      placeholder="Description"
                      value={formData.description}
                      onChange={handleChange}
                      className="w-full inputs px-3 py-2 outline-mygreen border border-mygreen outline-1 rounded-lg"
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-mygreen font-semibold drop-shadow-lg text-sm rounded-lg w-fit px-8 py-2 mx-auto"
                  >
                    Submit
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
