import React from "react";
import Bannersection from "./Bannersection";
import Form from "./Form";
import SanityClient from "../../SanityClient";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import GridSection from "./GridSection"
import GridSection2 from "./GridSection2";
import GridSection3 from "./GridSection3";
import BoxSection from "./Form";

const Twosection = () => {
  const [data,setData] = useState(null);
  useEffect(()=>{
    const getdata = () =>{
      SanityClient.fetch(`*[_type=="raisecapital"]{
          mataTitle,
          metaDescriptions,
          MetaDate,
          FocusKeywords,
          MetaURL
        }`).then((res)=>{
          setData(res[0]);
          console.log(res);
        }).catch((err)=>{
          console.log(err);
        })
    };
    getdata();
  },[])

  return (
    <>
      <Helmet>
        <title property="og:title">{data ? data.mataTitle : ""}</title>
        <meta
          property="og:description"
          content={data ? data.MetaDescription : ""}
        />
        <meta property="og:url" content={data ? data.MetaData : ""} />
        <meta name="keywords" content={data ? data.FocusKeyword : ""}></meta>
        <meta name="alldata" content={data ? data.MetaURL : ""}></meta>
      </Helmet>
      <Bannersection />
      <GridSection/>
      <GridSection2 />
      <GridSection3 />
      <BoxSection />
    </>
  );
};

export default Twosection;
