import React,{useState,useEffect} from "react";
import Bannersection from "./Bannersection";
import Card from "./Card";
import Supporttable from "./Supporttable";
import MobileBanner from "./Mobilebanner";
import SanityClient from "../../SanityClient";
import { Helmet } from "react-helmet";
const Support = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const getdata = () => {
      SanityClient.fetch(
        `*[_type=="support"]{
          metaTitle,
          metaDescriptions,
          MetaData,
          FocusKeyword,
          MetaURL,
        }`
      )
        .then((res) => {
          setData(res[0]);
          console.log(res, "support");
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getdata();
  }, []);
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  },[])
  return (
    <>
      <Helmet>
        <title property="og:title">{data ? data.metaTitle : ""}</title>
        <meta
          property="og:description"
          content={data ? data.MetaDescription : ""}
        />
        <meta property="og:url" content={data ? data.MetaData : ""} />
        <meta name="keywords" content={data ? data.FocusKeyword : ""}></meta>
        <meta name="alldata" content={data ? data.MetaURL : ""}></meta>
      </Helmet>
      <Bannersection />
      <MobileBanner />
      <Card />
      {/* <Supporttable/> */}
    </>
  );
};

export default Support;
