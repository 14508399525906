import React from "react";
import CountUp from "react-countup";

const Countup = () => {
  const data = [
    {
      count: "30",
      countContent:"+",
      title: "Clients",
    },
    {
      count: "3",
      countContent:"+ MILLION",
      title: "Sq.ft.",
    },
    {
      count: "100",
      countContent:"+",
      title: "Warehouses",
    },
  ];
  return (
    <>
      <div className="bg-gradient-to-t from-[#0D03C3] to-[#2272FF] text-white p-8">
        <div className="flex flex-wrap justify-evenly gap-5 items-center">
          {data.map((item, index) => (
            <div className="flex flex-col w-44 items-center justify-center">
              <p className="flex flex-row items-center font-bold text-3xl">
                <CountUp end={item.count} duration={5} />
                <p>{item.countContent}</p>
              </p>
              <p>{item.title}</p>
            </div>
          ))}
        </div>
        {/* <div>
          <p className="flex flex-row items-center font-bold text-3xl">
            <CountUp end={3} duration={5} />
            <p>+ MILLION</p>
          </p>
          <p>Sq.ft.</p>
        </div>
        <div>
          <p className="flex flex-row items-center font-bold text-3xl">
            <CountUp end={100} duration={5} />
            <p>+</p>
          </p>
          <p>Warehouses</p>
        </div> */}
      </div>
    </>
  );
};

export default Countup;
