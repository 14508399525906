import React, { useEffect } from 'react'
import Fractionalownership from './Fractional ownership'
import Fractionalcontent from './Fractional content'
import Weprovide from './We provide'
import Weprovideslide from './Weprovide slider';


const Fractional = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [])
  return (
   <>
   <Fractionalownership/>
   <Fractionalcontent/>
   <Weprovide/>
   <Weprovideslide/>
   </>
  )
}

export default Fractional