import React from 'react';
import image1 from '../../assests/img1ass.png';
import image2 from '../../assests/img2ass.png';
import image3 from '../../assests/img3ass.png';

const WhyInvest = () => {
  return (
    <div className='text-center w-full lg:pt-10'>
      <h1 className='font-bold text-center text-xl md:text-4xl mt-16 lg:mx-60'>Why invest in an Asset Backed Leasing?</h1>
      <div className=" bg-mygreen hidden lg:block h-0.5 w-6/12 absolute right-0 my-2 "/>

      <div className="mx-auto px-2 sm:px-4 grid grid-cols-1 md:gap-3 md:grid-cols-2 lg:grid-cols-3 gap-4 my-5 ">

          {/* box 1 */}
        <div className='transform transition-transform duration-300 hover:scale-110 '>
          <div className="relative bg-black   m-4 lg:m-14 border-[3px] rounded-[0px] border-none sm:shadow-blue sm:shadow-[10px_10px]">
            <div className="flex items-center justify-center">
              <img
                src={image1}
                alt="Your Alt Text"
                className="w-full h-full object-cover sm:block hidden"
              />
            </div>
            <div className="overlay"></div>
            <div className="px-3 lg:px-6 sm:py-2 lg:py-4 space-y-5 sm:space-y-0 py-4">
              <h1 className='text-mygreen text-center font-bold text-[20px] mx-5'>Predictable returns</h1>
              <div className="text-white text-center text-[15px]"> Gain the advantage of pre-agreed monthly payments for the full lease tenure, offering stability and financial security
</div>
            </div>
          </div>
        </div>

  {/* box 2 */}
        <div className='transform transition-transform duration-300 hover:scale-110 lg:-mt-4'>
        <div className="relative bg-black   m-4 lg:m-14 border-[3px] rounded-[0px] border-none sm:shadow-blue sm:shadow-[10px_10px]">
          <div className="flex items-center justify-center ">
            <img
              src={image2}
              alt="Your Alt Text"
              className="w-full h-full object-cover  sm:block hidden"></img>
          </div>
          <div className="overlay lg:mt-0 mt-0 "></div>
          <div className="px-3 lg:px-6 sm:py-2 lg:py-8 space-y-5 sm:space-y-0 py-4">
            <h1 className='font-bold text-mygreen text-center  text-[20px] mx-5'>Strong Collateral</h1>
            <div className="text-white text-center  text-[15px] ">Offers enhanced security and stability for your financial portfolio..</div>
          </div>
        </div>
        </div>


         {/* box 3 */}
        <div className='transform transition-transform duration-300 hover:scale-110'>
        <div className="relative bg-black   m-4 lg:m-14 border-[3px] rounded-[0px] border-none sm:shadow-blue sm:shadow-[10px_10px]">
            <div className="flex items-center justify-center">
              <img
                src={image3}
                alt="Your Alt Text"
                className="w-full h-full object-cover sm:block hidden"
              />
            </div>
            <div className="overlay"></div>
            <div className="px-3 lg:px-6 sm:py-2 lg:py-4 text-[20px] space-y-5 sm:space-y-0 py-4">
              <h1 className='font-bold text-center text-mygreen mx-8'>Tax Benefits</h1>
              <p className="text-white text-center text-[15px]">Secures your financial future but also brings potential tax advantages.</p>
            </div>
          </div>
        </div>
      
    </div>
    </div>
  );
};

export default WhyInvest;
