import React from "react";
import img from "../../assests/Line 3.png";
import img1 from "../../assests/puzass.png";
import img2 from "../../assests/puzzass2.png";
import img3 from "../../assests/Line 2.png";

import Puzzle from "../../assests/Asset-puzzle.png";
const PuzzleSctn1 = () => {
  return (
    <div className="hidden md:hidden lg:block w-full">
      <section className="container mx-auto   p-4 lg:p-8">
        <div className="grid grid-cols-1 sm:grid-cols-5 gap-4">
          <div className="bg-white p-4 rounded-md mt-10 mx-12">
            <h1 className="text-5xl font-bold mr-2 mt-24 text-blue">01</h1>
            <img src={img} className="ml-5 " />
          </div>
          <div className="bg-white p-4 rounded-md  shadow-2xl ">
            <p className="text-[#12c612] font-semibold text-center">
              Business Track Records
            </p>
            <p className="text-center mt-4 text-[#9e9199]  ">
              Lessee's Management Background Check: Assessing the Top-Level
              Management's Track Record and Evaluating the Credibility of
              Promoters and Management.
            </p>
          </div>
          <div className="bg-white  mx-20 mt-40 rounded-md">
            <h1 className="text-5xl font-bold text-blue">03</h1>
            <img src={img} className="ml-5" />
          </div>
          <div className="bg-white p-4 bg-opacity-75 rounded-md shadow-2xl mt-2">
            <p className="text-[#12c612]  font-semibold text-center">
              Experienced Organisation
            </p>
            <p className="text-center  mt-4 text-[#9e9199] ">
              Diverse expertise in finance, marketing, and operations. A proven
              organizational track record in consistently delivering
              high-quality products.
            </p>
          </div>
          <div className="bg-white mt-40 mx-20 item-centre">
            <h1 className="text-5xl font-bold text-blue">05</h1>
            <img src={img} className="ml-5" />{" "}
          </div>
        </div>

        <div>
          <img src={Puzzle} alt="" />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-5 gap-4 mb-5">
          <div className="bg-white p-3 rounded-md shadow-2xl">
            <p className="text-[#295494]  font-semibold text-center">
              Due Diligence
            </p>
            <p className="text-center mt-3 text-[#9e9199]">
              At Riofin, we meticulously conduct due diligence to3 identify and
              filter MSME industries with the potential for higher profit
              generation.
            </p>
          </div>
          <div className="bg-white p-4 mx-20 mb-20 rounded-md">
            <img src={img3} className="ml-5" />
            <h1 className="text-5xl mb-20 font-bold text-mygreen">02</h1>
          </div>
          <div className="bg-white p-3 rounded-md shadow-2xl">
            <p className="text-[#295494]  font-semibold text-center">
              Order Book size
            </p>
            <p className="text-center mt-3 text-[#9e9199]">
              {" "}
              Demonstrating robust demand for products and services, providing
              clear visibility into future earnings. The business has maintained
              a consistent track record for a minimum of 2-5 years.
            </p>
          </div>
          <div className="bg-white p-4 mx-20 mb-20 rounded-md">
            <img src={img3} className="ml-5" />
            <h1 className="text-5xl font-bold  text-mygreen">04</h1>
          </div>
          <div className="bg-white p-3 rounded-md shadow-2xl">
            <p className="text-[#295494]  font-semibold text-center">
              Lease Profile
            </p>
            <p className="text-center mt-3 text-[#9e9199]">
              Lessee's Top-Level Management Background: Assessing the Track
              Record and Credibility of Promoters and Management.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PuzzleSctn1;
