import React from "react";
import Image1 from "../../assests/pa-1.png";
import Image2 from "../../assests/pa-2.png";
import Image3 from "../../assests/pa-3.png";
import Image4 from "../../assests/pa-4.png";
import Img1 from "../../assests/a-Arc 2.png";
import Img2 from "../../assests/a-Arc 4.png";

const SectionWithIconBoxes = () => {
  return (
    <>
      {" "}
      <div className=" hidden md:hidden mt-10 lg:block">
        <h1 className="text-center text-2xl font-bold">Buying a warehouse</h1>
        <div className="mx-auto px-4   lg:px-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-7 gap-4">
          {/* Box 1 */}

          <div className="icon-box p-2 sm:p-3 md:p-4 lg:p-9">
            <img
              src={Image1}
              alt="Your Alt Text"
              className="object-cover p-2 rounded-lg w-28 h-28"
            />
          </div>

          {/* Box 2 */}
          <div className="">
            <img
              src={Img1}
              alt="Your Alt Text"
              className="p-2  pt-8 max-w-full"
            />
          </div>

          {/* Box 3 */}
          <div className="icon-box text-center p-4 sm:p-6 md:p-8 lg:p-10">
            <img
              src={Image2}
              alt="Your Alt Text"
              className="object-cover p-2 rounded-lg  w-28 h-28"
            />
          </div>
          {/* Box 4 */}
          <div className="">
            <img src={Img2} alt="Your Alt Text" className=" pt-24 max-w-full" />
          </div>

          {/* Box 5*/}
          <div className="icon-box text-center p-2 sm:p-4 md:p-6 lg:p-8">
            <img
              src={Image3}
              alt="Your Alt Text"
              className="object-cover p-2 rounded-lg   w-28 h-28"
            />
          </div>
          {/* Box 6 */}
          <div className="">
            <img src={Img1} alt="Your Alt Text" className=" pt-8 max-w-full" />
          </div>

          {/* Box 7 */}
          <div className="icon-box text-center p-4 sm:p-6 md:p-8 lg:p-9">
            <img
              src={Image4}
              alt="Your Alt Text"
              className="object-cover p-2 rounded-lg   w-28 h-28"
            />
          </div>

          {/* Add more boxes as needed */}
        </div>
      </div>
    </>
  );
};

export default SectionWithIconBoxes;
