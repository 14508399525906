import React from "react";
import yourImage from "../../assests/fract.jpg";
import yourImg1 from "../../assests/image3.png";
import yourImg2 from "../../assests/image3.png";
import yourImg3 from "../../assests/image3.png";
import { AiFillThunderbolt } from "react-icons/ai";

const Fractionalcontent = () => {
  return (
    <div className="bg-gradient-to-r  from-blue to-indigo-800 align-middle">
      <div className="lg:flex lg:flex-row-reverse">
        {/* Right Column (Image) - Visible on Larger Screens */}
        <div className="mx:auto ">
          <img
            src={yourImage}
            alt="Your Image"
            className="lg:max-w-full h-full  md:w-full"
          />
        </div>

        {/* Left Column (Content) */}
        <div className="lg:w-3/4 p-3 ">
          {/* Point one */}
          {/* <div className="flex lg:items-center lg:mb-4  ">
          <div className=" md:w-20 md:h-12 pt-1 md:pt-9 lg:pt-0  ">
             <AiFillThunderbolt className='text-white' />
            </div>
            <div className="  mb-6 ">
              <p className="text-white px-2 md:mt-0 lg:pt-10 md:pt-8 text-justify">Fractional 
              ownership is aninvestment approach in which the cost of an asset is split between individual shareholders. All the shareholders split the benefits of 
              the asset, such as income sharing, reduced rates, and usage rights.</p>
            </div>
          </div> */}

          {/* Point two */}
          {/* <div className="flex mb-4">
          <div className=" md:w-20 md:h-12 pt-1 ">
             <AiFillThunderbolt className='text-white' />
            </div>
            <div className=" mb-5">
              <p className="text-white  px-2 text-justify">RIOFIN identifies various investment opprtunities in the said 3 sector and select esteemed projects by through Due diligence in finance, Operation, Management and complliance.</p>
            </div>
          </div> */}

          {/* Point three */}
          {/* <div className="flex mb-4">
          <div className=" md:w-20 md:h-12 pt-1 ">
             <AiFillThunderbolt className='text-white' />
            </div>
            <div className=" mb-5">
              <p className="text-white  px-2 text-justify">Then RIOFIN forms SPV(Special Purpose Vehicle) in the form LLP(Limited Liability Partnership) for investment pools. Investors funds will be considered as contribution to the LLP and profits will be shared as returns (Post tax returns).</p>
            </div>
          </div> */}

          <div className="flex mb-4">
            <div className=" md:w-20 md:h-12 pt-1 ">
              <AiFillThunderbolt className="text-white" />
            </div>
            <div className=" mb-5">
              <p className="text-white  px-2 text-justify">
                {" "}
                Collective Investment Opportunity :  Fractional ownership
                enables a group of investors to pool their resources and
                collectively invest in high-value assets such as real estate,
                luxury items, or investment-grade assets.
              </p>
            </div>
          </div>

          <div className="flex mb-4">
            <div className=" md:w-20 md:h-12 pt-1 ">
              <AiFillThunderbolt className="text-white" />
            </div>
            <div className=" mb-5">
              <p className="text-white  px-2 text-justify">
                Diversification of Investments :  Investors benefit from
                diversification by holding a fractional share in various assets.
                This approach helps spread risk across different asset classes
                and reduces exposure to the potential volatility of a single
                investment.
              </p>
            </div>
          </div>
          <div className="flex mb-4">
            <div className=" md:w-20 md:h-12 pt-1 ">
              <AiFillThunderbolt className="text-white" />
            </div>
            <div className=" mb-5">
              <p className="text-white  px-2 text-justify">
                Access to Premium Assets :  Fractional ownership provides access
                to premium assets that may be financially out of reach for an
                individual investor. This democratization of ownership allows
                participants to enjoy the benefits of owning valuable assets
                without the need for a substantial upfront investment.
              </p>
            </div>
          </div>
          <div className="flex mb-4">
            <div className=" md:w-20 md:h-12 pt-1 ">
              <AiFillThunderbolt className="text-white" />
            </div>
            <div className=" mb-5">
              <p className="text-white  px-2 text-justify">
                Shared Responsibilities and Costs :  Participants share both the
                costs and responsibilities associated with asset ownership,
                including maintenance, insurance, and management. This shared
                model makes owning and managing high-value assets more feasible
                and cost-effective.
              </p>
            </div>
          </div>
          <div className="flex mb-4">
            <div className=" md:w-20 md:h-12 pt-1 ">
              <AiFillThunderbolt className="text-white" />
            </div>
            <div className=" mb-5">
              <p className="text-white  px-2 text-justify">
                Potential Income and Usage Rights :  Depending on the agreement,
                fractional owners may have the opportunity to earn income
                through rentals or usage rights, enhancing the overall return on
                investment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fractionalcontent;
