import React, { useEffect, useState } from "react";
import yourImage from "../../assests/Video.png";
import yourImg1 from "../../assests/num1.png";
import yourImg2 from "../../assests/num2.png";
import yourImg3 from "../../assests/num3.png";
import yourThumbnailImage from "../../assests/riofin1.png";
import "./Video.css";

import { PlayCircle } from "feather-icons-react/build/IconComponents";

import Aos from "aos";
import "aos/dist/aos.css";

const HowInvest = () => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);

  const [isVideoPlaying, setVideoPlaying] = useState(false);

  const handlePlayClick = () => {
    setVideoPlaying(true);
  };

  return (
    <div className="bg-gradient-to-b from-blue to-indigo-800 p-8 md:p-8 align-middle h-[100%]">
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 md:gap-0 md:p-5 ">
        {/* Left Column (Image) */}
        <div className="" data-aos="flip-right">
          <div className="md:mb-10">
            <h1 className="text-white font-bold md:text-left text-center relative text-[26px] md:text-4xl font-sans">
              How to Get in touch with {<br className="md:block hidden" />}{" "}
              Riofin Assets
              {/* <div className='border-b-4 border-mygreen pb-4 mb-6 md:absolute right-0 md:right-52 md:ml-[540px] bottom-0 top-24 w-full font-sans'></div> */}
              <hr
                className="border-b-3 p-0.5 border-mygreen pb-4 mb-6 h-0.5 md:absolute right-0 md:right-[420px] md:ml-[740px] bottom-0 top-24 w-full font-sans"
                data-aos="zoom-in-right"
              />
            </h1>
          </div>

          {/*points one */}
          <div className="flex  mb-4">
            <div className="w-14 h-8 md:w-12 md:h-12  lg:mb-16 rounded-full overflow-hidden">
              <img
                src={yourImg1}
                alt="Your Alt Text"
                className="w-full h-full "
              />
            </div>
            <div className="ml-4 mb-5">
              <h2 className="md:text-xl text-[18px] font-bold text-white">
                Create Account
              </h2>
              <p className="text-white opacity-80 text-[15px]">
                Create an account by clicking the sign up.{" "}
                {<br className="md:block hidden" />}Enter your KYC details and
                verify.
              </p>
            </div>
          </div>
          {/*points two */}
          <div className="flex mb-4">
            <div className="w-14 h-8 md:w-12 md:h-12 lg:mb-16 rounded-full overflow-hidden">
              <img
                src={yourImg2}
                alt="Your Alt Text"
                className="w-full h-full "
              />
            </div>
            <div className="ml-4 mb-5">
              <h2 className="md:text-xl text-[18px] font-bold text-white">
                Connect with Riofin
              </h2>
              <p className="text-white opacity-80 text-[15px]">
                Choose your preferred service—selling,{" "}
                {<br className="md:block hidden" />} buying, or asset management
              </p>
            </div>
          </div>

          {/*points three */}
          <div className="flex  mb-4">
            <div className="w-16 h-8 md:w-12 md:h-12 lg:mb-12 rounded-full overflow-hidden">
              <img
                src={yourImg3}
                alt="Your Alt Text"
                className="w-full h-full "
              />
            </div>
            <div className="ml-4">
              <h2 className="md:text-xl text-[18px] font-bold text-white">
                Contact Us: Take the Next Step
              </h2>
              <p className="text-white opacity-80 text-[15px]">
                Connect with our executives {<br className="md:block hidden" />}
                to finalize your deal with Riofin.
              </p>
            </div>
          </div>
        </div>

        {/* Right Column (Text and Button) */}
        <div
          className=" text-center md:text-left lg:text-right  mt-4 mb-12  "
          data-aos="flip-left"
        >
          <div className="flex text-right justify-end">
            <button
              className="hidden lg:block  lg:text-right  bg-mygreen font-bold text-black py-2 px-4  lg:mb-10  rounded-md border-black border-b-[3px] border-r-[3px] border-l-[1px] border-t-[1px] ease-linear transform hover:scale-125 transition duration-500"
              onClick={() =>
                (window.location.href = "https://riofinadmin.whytap.tech")
              }
            >
              Get Started Now
            </button>
          </div>

          <div className="lg:mb-16 w-[100%]  lg:h-[80%] h-[90%] overflow-hidden relative">
            {!isVideoPlaying ? (
              <>
                <img
                  src={yourThumbnailImage}
                  alt="Video Thumbnail"
                  className="w-full h-[80%] cursor-pointer  border-mygreen border-r-8 border-b-8 rounded-[26px]"
                  onClick={handlePlayClick}
                />
                <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-[40%] left-[50%]">
                  <PlayCircle
                    className="w-16 h-16 text-white cursor-pointer"
                    onClick={handlePlayClick}
                  />
                </div>
              </>
            ) : (
              <div className="iframe-container ">
                <iframe
                  title="YouTube video player"
                  width="786"
                  height="417"
                  object-fit="cover"
                  className="rounded-lg"
                  src="https://www.youtube.com/embed/nw9t2xx3A4k?si=DDLO51wKe6RLmmPB"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </div>

          <button
            onClick={() =>
              (window.location.href = "https://riofinadmin.whytap.tech")
            }
            className=" lg:hidden  bg-mygreen font-bold text-black py-2 px-4  rounded-md sm:mt-0 mt-4 mb-10 border-black border-b-[3px] border-r-[3px] border-l-[1px] border-t-[1px] ease-linear transform hover:scale-125 transition duration-500"
          >
            Get Started Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default HowInvest;
