import React, { useEffect, useRef, useState } from "react";
import backgroundImage from "../../assests/E-Rectangle 4453.png";
import rectangle4431 from "../../assests/E-Rectangle 4431.png";
import { Link as ScrollLink, Element } from "react-scroll";

const Bannersection = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    companyWebsite: "",
    financingType: "",
    stakeHeld: "",
    annualRevenue: "",
    cashRunway: "",
    whereHeard: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log("Form submitted:", formData);
  };

  return (
    <>
      <div className="relative">
        {/* Background image */}
        <img
          src={backgroundImage}
          className="w-full lg:h-[600px] md:h-[400px] h-[200px] object-cover"
          alt="Background"
        />

        {/* Overlay content */}
        <div className="absolute inset-0 flex flex-col items-center justify-center text-white z-10 mb-3 ">
          <hr className="bg-mygreen  md:mb-7 p-1 px-8 md:px-28 mt-14 "></hr>
          <h1 className="font-bold text-xl md:text-4xl lg:text-5xl mb-3 md:mb-5 text-black text-center">
            RE Ventures
          </h1>
          <p className="text-sm md:text-xl lg:text-2xl mb-3 md:mb-6 text-black text-center w-3/4">
            Unlock Profitable Investments with Riofin : Explore exclusive
            opportunities in property flipping and bank auction properties with
            Riofin. Maximize returns with expert guidance and strategic
            insights.
          </p>
          <p className="text-black font-bold text-xl pb-5">Start Investing Today</p>
          <div className="max-w-md text-center">
            {/* <button
              className="join-us text-black bg-mygreen px-8 py-2  rounded-lg md:ml-2 mb-12 font-bold "
              // onClick={() => {
              //   // scrollToFormSection();
              //   console.log('Button clicked');
              // }}
            >
              <a href="#form">
              Join Us
              </a>
            </button> */}
            <ScrollLink
              to="form"
              smooth={true}
              offset={-50}
              duration={500}
              className="join-us text-black bg-mygreen px-8 py-2 rounded-lg md:ml-2 mb-12 font-bold cursor-pointer"
            >
              Talk to us now
            </ScrollLink>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row" id="contact">
        {/* Image Section */}
        <div className="lg:w-2/4 md:w-2/3">
          <img src={rectangle4431} alt="no img" className="w-full h-full" />
        </div>

        {/* Form Section */}
        <div className="lg:w-2/4 md:w-2/3" id="form">
          <form className="max-w-md mx-auto mt-8 p-4" onSubmit={handleSubmit}>
            <h1 className="font-bold text-black text-3xl text-center lg:text-left">
              Contact Form
            </h1>
            <div className="bg-mygreen mb-5 p-0.5 mr-40 px-4 md:px-18 md:mr-40" />

            <div className="grid grid-cols-1 gap-6 p-0">
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                className="mt-4 p-2 w-full bg-formbg"
                required
              />
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className="p-2 w-full bg-formbg"
                required
              />
              <input
                type="text"
                id="companyName"
                name="companyName"
                placeholder="Company Legal Name"
                value={formData.companyName}
                onChange={handleChange}
                className="p-2 w-full bg-formbg"
                required
              />
              <input
                type="text"
                id="companyWebsite"
                name="companyWebsite"
                placeholder="Company Website Link"
                value={formData.companyWebsite}
                onChange={handleChange}
                className=" p-2 w-full bg-formbg"
                required
              />
              <input
                type="text"
                id="financingType"
                name="financingType"
                placeholder="Type Of Financing"
                value={formData.financingType}
                onChange={handleChange}
                className=" p-2 w-full bg-formbg"
                required
              />
              <input
                type="text"
                id="stakeHeld"
                name="stakeHeld"
                placeholder="Stake held by VC/PE investors"
                value={formData.stakeHeld}
                onChange={handleChange}
                className=" p-2 w-full bg-formbg"
                required
              />
              <input
                type="text"
                id="annualRevenue"
                name="annualRevenue"
                placeholder="Annual Revenue Rate"
                value={formData.annualRevenue}
                onChange={handleChange}
                className="p-2 w-full bg-formbg"
                required
              />
              <input
                type="text"
                id="cashRunway"
                name="cashRunway"
                placeholder="Cash Runway"
                value={formData.cashRunway}
                onChange={handleChange}
                className=" p-2 w-full bg-formbg"
                required
              />
              <input
                type="text"
                id="whereHeard"
                name="whereHeard"
                placeholder="Where did you hear about us?"
                value={formData.whereHeard}
                onChange={handleChange}
                className=" p-2 w-full bg-formbg"
                required
              />
            </div>

            {/* Submit button */}
            <div className="mt-6 lg:text-right text-center">
              <button
                type="submit"
                className="bg-mygreen text-black px-8 py-2 rounded-lg font-bold"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Bannersection;
