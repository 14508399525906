// Tab.js

import React from "react";
import TabsComponent from "./TabComponent";
import yourImage from "../../assests/ac.jpg";
import StockImg from "../../assests/stock.jpg";
import BondImg from "../../assests/bond.jpg";
import GoldImg from "../../assests/partner.jpg";
import Right from "../../assests/right.png";
import Wrong from "../../assests/wrong.png";

const Tab = () => {
  return (
    <div className="flex justify-center">
      {/* Tabs Component */}
      <TabsComponent items={items} />
    </div>
  );
};

export default Tab;

const items = [
  {
    title: "Acquisition Expert",
    content: (
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 p-4  ">
        {/* Left Column (Image) */}
        <div className="">
          <img
            src={yourImage}
            alt="Your Image"
            className="w-full h-96 object-cover  border-[1px]  rounded-[6px] border-solid border-[black] shadow-[2px_2px]"
          />
        </div>

        {/* Right Column (Text) */}
        <div className="md:pl-6 mt-5">
          <h1 className="md:text-2xl text-[20px] font-bold text-black mb-4">
            Acquisition Expert
          </h1>

          <p className="text-start space-y-2">
            <p>
              Discover Prime Warehouses with Riofin: Seamless Transactions,
              Expert Guidance. Elevate Your Investment Portfolio with our
              Tailored Solutions. Experience Efficiency, Security, and Growth
              Potential, Today.
            </p>
            {/* <p> <span className="font-semibold">Positive Aspect:</span> Safe-haven, stores value.</p>
            <p>
            <span className="font-semibold">Negative Aspect:</span> No active income, subject to market price
              fluctuations.
            </p> */}
          </p>
          {/* <div className="flex items-center mb-2 mt-3">
            <img src={Right} className="mr-2" />
            <p>Predictable and stable returns.</p>
          </div>
          <div className="flex items-center mb-2 mt-2">
            <img src={Wrong} className="mr-2" />
            <p>Lacks flexibility and potential for higher
            returns.</p>
          </div> */}

          <button
            className="bg-mygreen font-bold text-black py-2 px-4 rounded-md mt-4 border-black border-b-[3px] border-r-[3px] border-l-[1px] border-t-[1px] ease-linear transform hover:scale-105 transition duration-500"
            onClick={() =>
              (window.location.href = "https://riofinadmin.whytap.tech/")
            }
          >
            Get Started with Riofin
          </button>
        </div>
      </div>
    ),
  },
  {
    title: "Maximize Sale",
    content: (
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2  gap-4 p-4 ">
        {/* Left Column (Image) */}
        <div>
          <img
            src={StockImg}
            alt="Your Image"
            className="w-full h-96 object-cover border-[1px]  rounded-[6px] border-solid border-[black] shadow-[2px_2px]"
          />
        </div>

        {/* Right Column (Text) */}
        <div className="pl-0 md:pl-6 mt-5">
          <h1 className="md:text-2xl text-[20px] font-bold text-black mb-4">
            Maximize Sale
          </h1>

          <p className="text-start space-y-1">
            <p>
              Unlock Maximum Value: Sell Your Warehouse with Riofin. Expert
              Guidance, Seamless Transactions. Elevate Your Returns with our
              Tailored Solutions. Experience Efficiency, Security, and
              Profitability, Today.
            </p>
            {/* <p> Positive Aspect: Safe-haven, stores value.</p>
            <p>
              Negative Aspect: No active income, subject to market price
              fluctuations.
            </p> */}
          </p>
          {/* <div className="flex items-center mb-2 mt-3">
            <img src={Right} className="mr-2" />
            <p>Potential for high returns.</p>
          </div>
          <div className="flex items-center mb-2 mt-2">
            <img src={Wrong} className="mr-2" />
            <p>Volatile, subject to market fluctuations.</p>
          </div> */}

          <button
            className="bg-mygreen font-bold text-black py-2 px-4 rounded-md mt-4 border-black border-b-[3px] border-r-[3px] border-l-[1px] border-t-[1px] ease-linear transform hover:scale-105 transition duration-500"
            onClick={() =>
              (window.location.href = "https://riofinadmin.whytap.tech")
            }
          >
            Get Started with Riofin
          </button>
        </div>
      </div>
    ),
  },
  {
    title: "Management Partner",
    content: (
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2  gap-4 p-4 ">
        {/* Left Column (Image) */}
        <div>
          <img
            src={GoldImg}
            alt="Your Image"
            className="w-full h-96 object-cover border-[1px]  rounded-[6px] border-solid border-[black] shadow-[2px_2px]"
          />
        </div>

        {/* Right Column (Text) */}
        <div className="pl-0 md:pl-6 mt-5">
          <h1 className="md:text-2xl text-[20px] font-bold text-black mb-4">
            Management Partner
          </h1>

          <div className="text-start space-y-1">
            <p>
              Optimize Your Warehouse Assets: Trust Riofin for Expert
              Management. From Tenancy to Transactions, We Ensure Efficiency,
              Profitability, and Peace of Mind. Let Us Elevate Your Portfolio
              Management.
            </p>
            {/* <p> Positive Aspect: Safe-haven, stores value.</p>
            <p>
              Negative Aspect: No active income, subject to market price
              fluctuations.
            </p> */}
          </div>
          {/* <div className="flex items-center mb-2 mt-3">
            <img src={Right} className="mr-2" />
            <p>Safe-haven, stores value.</p>
          </div>
          <div className="flex items-center mb-2 mt-2">
            <img src={Wrong} className="mr-2" />
            <p> No active income, subject to market price fluctuations.</p>
          </div> */}

          <button
            className="bg-mygreen font-bold text-black py-2 px-4 rounded-md mt-4 border-black border-b-[3px] border-r-[3px] border-l-[1px] border-t-[1px] ease-linear transform hover:scale-105 transition duration-500"
            onClick={() =>
              (window.location.href = "https://riofinadmin.whytap.tech")
            }
          >
            Get Started with Riofin
          </button>
        </div>
      </div>
    ),
  },
  // {
  //   title: "Bond",
  //   content: (
  //     <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 p-4 ">
  //       {/* Left Column (Image) */}
  //       <div>
  //         <img
  //           src={BondImg}
  //           alt="Your Image"
  //           className="w-full h-auto border-[2px]  rounded-[6px] border-solid border-[black] shadow-[2px_2px]"
  //         />
  //       </div>

  //       {/* Right Column (Text) */}
  //       <div className="pl-0 md:pl-6 mt-5">
  //         <h1 className="md:text-2xl text-[20px] font-bold text-black mb-4">
  //           Bond
  //         </h1>

  //         <div className="text-justify space-y-1">
  //           <p>
  //             Description: Traditional safe-haven. Stores value but lacks income
  //             generation and faces market price fluctuations.
  //           </p>
  //           <p> Positive Aspect: Safe-haven, stores value.</p>
  //           <p>
  //             Negative Aspect: No active income, subject to market price
  //             fluctuations.
  //           </p>
  //         </div>
  //         <div className="flex items-center mb-2 mt-3">
  //           <img src={Right} className="mr-2" />
  //           <p>Ranks high in providing safer returns</p>
  //         </div>
  //         <div className="flex items-center mb-2 mt-2">
  //           <img src={Wrong} className="mr-2" />
  //           <p>Offers one of the lowest returns</p>
  //         </div>

  //         <button
  //           className="bg-mygreen font-bold text-black py-2 px-4 rounded-md mt-4 border-black border-b-[3px] border-r-[3px] border-l-[1px] border-t-[1px] ease-linear transform hover:scale-105  transition duration-500"
  //           onClick={() =>
  //             (window.location.href = "https://riofinadmin.whytap.tech/")
  //           }
  //         >
  //           Get Started with Riofin
  //         </button>
  //       </div>
  //     </div>
  //   ),
  // },
];
