// App.js

import React, { useState } from "react";
import "./flip.css";
import img1 from "../../assests/bank1.jpg";
import img2 from "../../assests/bank2.jpg";
import img3 from "../../assests/bank3.jpg";

const GridSection2 = () => {
  const cards = [
    {
      id: 1,
      frontImage: img1,
      backContent:
        "Acquire Below Market Value: Investors purchase properties below market value, often distressed or undervalued.",
    },
    {
      id: 2,
      frontImage: img2,
      backContent:
        "Strategic Acquisition: Properties acquired undergo strategic improvements to maximize value and appeal.",
    },
    {
      id: 3,
      frontImage: img3,
      backContent:
        "Profitable Return: Post-renovation, properties are swiftly resold at higher prices, ensuring profitable returns for investors.",
    },
  ];

  const [flippedCards, setFlippedCards] = useState([]);

  const handleFlip = (cardId) => {
    setFlippedCards((prevFlippedCards) => {
      if (prevFlippedCards.includes(cardId)) {
        return prevFlippedCards.filter((id) => id !== cardId);
      } else {
        return [...prevFlippedCards, cardId];
      }
    });
  };

  return (
    <div className="App w-full">
      <div className="text-center">
        <h1 className="text-black mt-4 font-bold text-xl md:text-4xl relative">
          Bank Auction Properties
          <div className="border-b-4 border-[#04ef00] md:absolute right-0 absolute   bottom-0 top-12 w-full md:w-1/2 font-sans mt-2"></div>
        </h1>
      </div>
      <div className="w-full flex justify-center items-center my-16 text-center">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {cards.map((card) => (
            <div
              key={card.id}
              className={`flip-card ${
                flippedCards.includes(card.id) ? "flipped" : ""
              }`}
            >
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img src={card.frontImage} alt={`Card ${card.id} Front`} />
                  <button
                    className="flip-button"
                    onClick={() => handleFlip(card.id)}
                  ></button>
                </div>
                <div className="flip-card-back">
                  <div className="card-content">{card.backContent}</div>
                  <button
                    className="flip-button"
                    onClick={() => handleFlip(card.id)}
                  ></button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GridSection2;
