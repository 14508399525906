import React, { useEffect } from "react";
import NationImg from "../../assests/nation.png";
import Aos from "aos";
import "aos/dist/aos.css";

const Nation = () => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);

  return (
    <div className="flex flex-col-reverse lg:flex-row md:flex-col-reverse bg-gradient-to-b pl-4 lg:pl-20  from-purple-900 via-sky-700 to-purple-900 md:pt-10 md:pb-10 p-4 overflow-hidden">
      {/* Left Side */}
      <div
        className="md:w-full pl-4 md:pl-10 pt-8 md:pt-20"
        data-aos="zoom-in-right"
      >
        <h1 className="text-lg md:text-2xl font-bold mb-2 md:mb-4 text-mygreen ">
          Hi,
        </h1>
        <h2 className="text-[26px] md:text-5xl text-white font-bold mb-2 md:mb-4">
          We Have Partners {<br className="md:block hidden" />}
          <span className="text-3xl md:hidden text-[#00ff00] ">Nationwide</span>
        </h2>
        <h1 className="text-3xl md:text-5xl font-bold mb-2 md:mb-4 text-[#00ff00] md:block hidden">
          Nationwide
        </h1>
        <p className="text-left text-white mr-7 text-justify justify-left text-xl mb-2 md:mb-4">
          An extensive network of warehouse owners, sellers, tenants, and more
          across the nation, spanning diverse regions and markets.
        </p>
        <p className="text-white text-justify text-xl mb-2">Join Our Community of Smart Partners.</p>
        <div className="flex">
          <h1 className="text-5xl md:text-7xl font-bold mb-2 md:mb-4 text-white">
            3000{" "}
          </h1>
          <p className="text-5xl md:text-7xl font-bold mb-2 md:mb-4 text-mygreen">
            +
          </p>
        </div>
        <h2 className="text-2xl md:text-4xl font-bold mb-2 md:mb-4 text-mygreen">
          Smart Users
        </h2>
      </div>

      {/* Right Side */}
      <div className="md:w-full p-4 md:pl-10  " data-aos="zoom-in-left">
        <img
          src={NationImg} // Replace with your image URL
          alt="Map"
          className="w-510 h-570 rounded-md md:w-full lg:w-4/5 xl:w-4/5"
        />
      </div>
    </div>
  );
};

export default Nation;
