import React from "react";
import cardicon04 from "../../assests/cardicon04.png";
import cardicon03 from "../../assests/cardicon03.png";
import cardicon02 from "../../assests/cardicon02.png";
import cardicon01 from "../../assests/cardicon01.png";

function Cards() {
  return (
    <>
      <div
        style={{ backgroundImage: "url('/blackthemebg.png')" }}
        className="flex flex-col py-8 gap-3 bg-cover w-full bg-no-repeat mt-[0.9px]"
      >
        <h1 className="font-extrabold text-3xl text-center text-white">
          Features
        </h1>
        <div className="flex justify-end">
          <p className="h-0.5 w-1/2 bg-mygreen"></p>
        </div>

        <div className="flex  flex-wrap justify-center gap-5 px-3 py-7">
          <div
            className="bg-white flex flex-col rounded-xl w-[250px] h-52 justify-start gap-3
        "
          >
            <div className="flex w-full justify-end py-5">
              <div className="flex flex-row gap-3 rounded-l-full bg-blue w-[95%] items-center h-fit">
                {/* <PiBuildingsLight
                size={40}
                className=" text-lightblue bg-white border-mygreen border rounded-full p-1 shadow drop-shadow-2xl"
              /> */}
                <img
                  src={cardicon04}
                  alt=""
                  className="bg-white border-mygreen border-2 rounded-full p-1 shadow drop-shadow-2xl"
                />
                <h1 className="h-fit text-center  text-white text-sm font-medium">
                  Reliable Tenant Acquisition
                </h1>
              </div>
            </div>

            <p className="text-[#757575] text-sm text-center px-5">
              We find and secure high-quality tenants to ensure your warehouse
              remains occupied and profitable.
            </p>
          </div>

          <div className="bg-white flex flex-col rounded-xl w-[250px] h-52 justify-start gap-3">
            <div className="flex w-full justify-end py-5">
              <div className="flex flex-row gap-3 rounded-l-full bg-blue w-[95%] items-center h-fit">
                {/* <PiBuildingsLight
                size={40}
                className=" text-lightblue bg-white border-mygreen border rounded-full p-1 shadow drop-shadow-2xl"
              /> */}
                <img
                  src={cardicon03}
                  alt=""
                  className="bg-white border-mygreen border-2 rounded-full p-1 shadow drop-shadow-2xl"
                />

                <h1 className="h-fit text-start text-white text-sm font-medium">
                  Comprehensive Tenant Maintenance
                </h1>
              </div>
            </div>

            <p className="text-[#757575] text-sm text-center px-5">
              We handle all aspects of tenant maintenance, ensuring satisfaction
              and retention with prompt and effective service.
            </p>
          </div>

          <div className="bg-white flex flex-col rounded-xl w-[250px] h-52 justify-start gap-3">
            <div className="flex w-full justify-end py-5">
              <div className="flex flex-row gap-3 rounded-l-full bg-blue w-[95%] items-center h-fit">
                {/* <PiBuildingsLight
                size={40}
                className=" text-lightblue bg-white border-mygreen border rounded-full p-1 shadow drop-shadow-2xl"
              /> */}
                <img
                  src={cardicon02}
                  alt=""
                  className="bg-white border-mygreen border-2 rounded-full p-1 shadow drop-shadow-2xl"
                />

                <h1 className="h-fit text-center  text-white text-sm font-medium">
                  Financial Transparency
                </h1>
              </div>
            </div>

            <p className="text-[#757575] text-sm text-center px-5">
              We provide clear and detailed financial reporting, giving you
              complete visibility into your warehouse’s performance and
              profitability.
            </p>
          </div>

          <div className="bg-white flex flex-col rounded-xl w-[250px] h-52 justify-start gap-3">
            <div className="flex w-full justify-end py-5">
              <div className="flex flex-row gap-3 rounded-l-full bg-blue w-[95%] items-center h-fit">
                {/* <PiBuildingsLight
                size={40}
                className=" text-lightblue bg-white border-mygreen border rounded-full p-1 shadow drop-shadow-2xl"
              /> */}
                <img
                  src={cardicon01}
                  alt=""
                  className="bg-white border-mygreen border-2 rounded-full p-1 shadow drop-shadow-2xl"
                />

                <h1 className="h-fit text-center  text-white text-sm font-medium">
                  Expert Management
                </h1>
              </div>
            </div>

            <p className="text-[#757575] text-sm text-center px-5">
              Leverage our team’s extensive experience and industry knowledge to
              optimize your warehouse assets and maximize returns.
            </p>
          </div>
        </div>
      </div>

      
    </>
  );
}

export default Cards;
