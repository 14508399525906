import React, { useEffect, useState } from "react";
import RiofinAssets from "./RiofinAssets";
import HowInvest from "./HowInvest";
import Tab from "./Tab";
import Founders from "./Founders";
import Story from "./Story";
import Banner from "./Banner";
import Nation from "./Nation";
import CallToAction from "./CallToAction";
import Testimonials from "./Testimonials";
import Counter from "./Counter";
import CurrentProjects from "./CurrentProjects";
import CurrentProjects1 from "./Currentproject1";
import CurrentProjects2 from "./Currentproject2";
import Blog from "./Blog";
// import Funded from './Funded';
import Funded1 from "./Funded1";
import TimeLine from "./TimeLine";
import SanityClient from "../../SanityClient";
import { Helmet } from "react-helmet";

// import Rio from '../Rioivestor/Rio';

const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [data, setData] = useState(null);
  useEffect(() => {
    const getdata = () => {
      SanityClient.fetch(
        `*[_type=="home"]{
          metaTitle,
          metaDescriptions,
          MetaData,
          FocusKeyword,
          MetaURL
        }`
      )
        .then((res) => {
          setData(res[0]);
          console.log(res, "home");
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getdata();
  }, []);
  return (
    <>
      <Helmet>
        {/* <title property="og:title">{data ? data.metaTitle : ""}</title> */}
        <meta
          property="og:description"
          content={data ? data.MetaDescription : ""}
        />
        <meta property="og:url" content={data ? data.MetaData : ""} />
        <meta name="keywords" content={data ? data.FocusKeyword : ""}></meta>
        <meta name="alldata" content={data ? data.MetaURL : ""}></meta>
      </Helmet>
      <Banner />
      <Counter />
      <RiofinAssets />
      <CurrentProjects />
      <CurrentProjects2 />
      <Tab />
      <HowInvest />
      {/* <Founders /> */}
      <TimeLine />
      <Nation />
      <Funded1 />
      <Testimonials />
      <Blog />
      <CallToAction />
      {/* <Rio />  */}
    </>
  );
};

export default Home;
