import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import background1 from '../../assests/Frame 15.png';
import background2 from '../../assests/v2.png';
import background3 from '../../assests/v3.png';
import background4 from '../../assests/Loginrec.png';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assests/Riologo.png'

const SignUp = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [otp, setOtp] = useState('');
   
  
    return (
  
      <div className="relative">
        
          <Carousel className="" autoPlay={true} interval={5000} infiniteLoop={true} showStatus={false} showThumbs={false}>
            <div>
              <img src={background1} className="object-cover object-center" alt="" />
            </div>
            <div>
              <img src={background2} className="object-cover object-center" alt="" />
            </div>
            <div>
              <img src={background3} className="object-cover object-center" alt="" />
            </div>
          </Carousel>
       
  
        <div className=" flex   md:absolute  inset-0 items-center  lg:p-40 md:p-20 p-4">
        <div className="title-container hidden md:flex flex-col  items-center sm:pt-4 md:pt-6 lg:pt-8 sm:h-64 md:h-[470px] lg:h-[620px] w-full lg:w-1/2" style={{ backgroundImage: `url(${background4})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', position: 'relative' }}>
    <p className="font-bold" style={{ position: 'absolute', bottom: '0', color: 'white', padding: '8%', fontWeight: 'bold'  }}>Unlock your financial potential with RioFin, your trusted partner in smart investments.</p>
    <img src={Logo} className="lg:h-20 md:h-10" alt="" />
  </div>
  <div className="bg-white  w-full lg:w-1/2  md:pt-2 lg:pt-8 h-fit rounded">
            <form className="lg:p-4 md:p-2 ">
              <div className=" flex justify-end ">
                  <Link to="/login">
                    <button className="py-2 px-6 lg:text-md md:text-sm  font-bold bg-black text-white rounded-lg">
                      Log In
                    </button>
                  </Link>
                  <Link to="/signupform">
                    <button className="py-2 px-6 lg:text-md md:text-sm    font-bold  bg-mygreen text-black rounded-lg">
                      Sign Up
                    </button>
                  </Link>
                </div>
                <div className="lg:mb-8 lg:mt-9">
                <label className="block text-gray-700lg:text-sm md:text-xs p-2 font-bold lg:mb-2" htmlFor="country">
                  Country
                </label>
                <input
                  className="shadow-none appearance-none  border-b border-gray-500 w-full lg:text-sm md:text-xs py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                  id="country"
                  type="text"
                  placeholder="Enter Your Country"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />

                <label className="block text-gray-700 p-2 lg:text-sm md:text-xs  font-bold mb-2" htmlFor="state">
                  State
                </label>
                <input
                  className="shadow-none appearance-none border-b lg:text-sm md:text-xs border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                  id="state"
                  type="text"
                  placeholder="Enter Your State"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />

                <label className="block text-gray-700 p-2 lg:text-sm md:text-xs font-bold mb-2" htmlFor="city">
                  City
                </label>
                <input
                  className="shadow-none appearance-none border-b border-gray-500 w-full lg:text-sm md:text-xs  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                  id="city"
                  type="text"
                  placeholder="Enter Your City"
                  value={referralCode}
                  onChange={(e) => setReferralCode(e.target.value)}
                />

                <label className="block text-gray-700 p-2 lg:text-sm md:text-xs  font-bold mb-2" htmlFor="address">
                  Address
                </label>
                <input
                  className="shadow-none appearance-none border-b border-gray-500 w-full lg:text-sm md:text-xs  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                  id="address"
                  type="text"
                  placeholder="Enter Your Address"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />

                <label className="block text-gray-700 p-2 lg:text-sm md:text-xs  font-bold mb-2" htmlFor="pincode">
                  Pincode
                </label>
                <input
                  className="shadow-none appearance-none border-b border-gray-500 w-fulllg:text-sm md:text-xs  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                  id="pincode"
                  type="tel"
                  placeholder="Enter Your Pincode"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
              </div>
              <div className="flex justify-end gap-4 pt-4 ">
                <div className="flex items-center rounded-lg gap-4 bg-black py-2 px-6 ">
                  <Link to='/signupform'>
                    <button className="text-white font-bold">
                      Go back
                    </button>
                  </Link>
                  {/* <img src={Next} className="w-4 h-4 " alt="Next Icon" /> */}
                </div>

                <Link to='/signup1'>
                  <button className="text-white font-bold bg-black py-2 px-6  rounded-lg">
                    Signup
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    
  );
}

export default SignUp;
