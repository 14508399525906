import React, { useEffect,useState } from "react";
import Aboutus from "./AboutUs";
import Vision from "./Vision";
import Founders from "./Founderss";
import Achievements from "./Achievements";
import Dedicate from "./Dedicate";
import SanityClient from "../../SanityClient";
import { Helmet } from "react-helmet";

const About = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [data, setData] = useState(null);
  useEffect(() => {
    const getdata = () => {
      SanityClient.fetch(
        `*[_type=="aboutus"]{
          metaTitle,
          metaDescriptions,
          MetaData,
          FocusKeyword,
          MetaURL
        }`
      )
        .then((res) => {
          setData(res[0]);
          console.log(res, "about");
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getdata();
  }, []);
  return (
    <>
      <Helmet>
        <title property="og:title">{data ? data.metaTitle : ""}</title>
        <meta
          property="og:description"
          content={data ? data.MetaDescription : ""}
        />
        <meta property="og:url" content={data ? data.MetaData : ""} />
        <meta name="keywords" content={data ? data.FocusKeyword : ""}></meta>
        <meta name="alldata" content={data ? data.MetaURL : ""}></meta>
      </Helmet>
      <Aboutus />
      <Dedicate />
      <Vision />
      {/* <Founders /> */}
      <Achievements />
    </>
  );
};

export default About;
