import React from 'react';
import Contact from '../../assests/Contact.png';


const Aboutus = () => {
  return (
    <>
      <div className='bg-black text-white w-full px-2 md:px-16'>
      <div className='max-w-7xl mx-auto md:ml-10'>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className='flex flex-col'>
              <div className="my-5 lg:my-16">
                <hr className='hrd' />
                <h1 className='mt-3 text-2xl sm:text-xl lg:text-6xl   font-bold mb-4 md:mb-8'>Contact Us</h1>
                <p className='text-[16px] md:text-2xl md:w-full '> Have questions or inquiries? We're here to assist you. Reach out to us.
                </p>
              </div>
            </div>
            <div className='md:my-0 hidden md:block lg:flex justify-center   md:justify-end'>
              <img src={Contact} alt='no img' className='max-w-full lg:max-w-72 lg:h-72 md:max-w-60 md:h-60 md:-mt-8' />
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default Aboutus;