import React from "react";
import person from "../../assests/person.png";
import { FaCircle } from "react-icons/fa";

const Contact = () => {
  return (
    <>
      <div className="w-full mt-10">
        <div
          style={{ backgroundImage: `url(${person})` }}
          className="w-full relative flex flex-col justify-center items-center bg-no-repeat bg-cover h-[350px]"
        >
          <div className="bg-black w-full h-full absolute  top-0 left-0 bg-opacity-50"></div>
          <div className="flex flex-col z-10  items-center gap-10 w-[90%]">
            <p className="lg:text-6xl sm:text-4xl text-2xl text-center font-semibold text-white">
              Contact Us Today For a Quick, Obligation-Free Cash Offer
            </p>
            <a href="#contact">
              <div className="bg-mygreen flex flex-row justify-between  items-center rounded-full gap-3 w-52 px-6 py-3 sm:px-6 sm:py-3">
                <FaCircle size={20} className="text-white drop-shadow-lg  " />
                <p className="uppercase font-bold drop-shadow-lg text-white">
                  Get In Touch
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
