import React from "react";

function BoxSection() {
  return (
    <article className="bg-white flex justify-center items-center m-10">
      <section className="bg-gradient-to-r from-[#2272FF] to-[#0D03C3] rounded-xl shadow-xl shadow-black flex flex-col gap-5 items-center justify-center sm:px-20 sm:py-8 p-5">
        <h2 className="sm:text-xl md:text-2xl lg:text-3xl text-center text-white font-semibold">
          Explore Riofin's Range of Available Warehouse Spaces for Rent!
        </h2>
        <a href="#contact">
          <p className="font-bold bg-mygreen cursor-pointer py-2 px-2 sm:py-4 sm:px-5 sm:text-base text-[10px]">
            Talk To Our Warehouse Specialist
          </p>
        </a>
      </section>
    </article>
  );
}

export default BoxSection;
