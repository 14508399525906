import React from "react";
import asset from "../../assests/asset.png";
import back from "../../assests/backbg.png";
import Consultation from "../../assests/Consultation.png";
import Strategy from "../../assests/Strategy.png";
import Implementation from "../../assests/Implementation.png";
import Support from "../../assests/Supportusimg.png";
function Howitworks() {
  return (
    <div>
      <div
        style={{ backgroundImage: `url(${back})` }}
        className="w-full h-full flex flex-col gap-5 py-5 pb-10"
      >
        <h1 className="text-3xl font-bold text-center h-[6vh] md:h-[8vh] relative">
          How It Works
          <p className="w-[50%] h-[2px] bg-mygreen absolute bottom-0 left-0"></p>
        </h1>
        <div className="flex flex-wrap -mt-5 gap-10 lg:gap-5 justify-evenly items-center p-5">
          <div className="w-[250px] flex gap-1 flex-col h-[350px] sideshadow rounded-lg border-2 border-black bg-white relative">
            <p className="px-3 py-2 rounded-full left-1 top-2 bg-mygreen absolute">
              1
            </p>
            <img
              className="h-[50%] w-full rounded-b-lg"
              src={Consultation}
            ></img>
            <p className="text-lg font-bold text-center">Consultation</p>
            <p className="px-4 text-gray-600 text-base text-center">
              Schedule a consultation with our team to discuss your specific
              needs and goals for warehouse asset management.
            </p>
          </div>
          <div className="w-[250px] flex gap-1 flex-col hometimeline1:mt-20 h-[350px] sideshadow rounded-lg border-2 border-black bg-white relative">
            <p className="px-3 py-2 rounded-full left-1 top-2 bg-mygreen absolute">
              2
            </p>
            <img className="h-[50%] w-full rounded-b-lg" src={Strategy}></img>
            <p className="text-lg font-bold text-center">
              Strategy Development
            </p>
            <p className="px-4 text-gray-600 text-base text-center">
              We develop a customized management plan tailored to optimize your
              warehouse operations and maximize returns.
            </p>
          </div>
          <div className="w-[250px] flex gap-1 flex-col h-[350px] sideshadow rounded-lg border-2 border-black bg-white relative">
            <p className="px-3 py-2 rounded-full left-1 top-2 bg-mygreen absolute">
              3
            </p>
            <img
              className="h-[50%] w-full rounded-b-lg"
              src={Implementation}
            ></img>
            <p className="text-lg font-bold text-center">Implementation</p>
            <p className="px-4 text-gray-600 text-base text-center">
              Our team implements the management plan, taking care of tenant
              acquisition, maintenance, and financial management.
            </p>
          </div>
          <div className="w-[250px] hometimeline1:mt-20 flex gap-1 flex-col h-[370px] sideshadow rounded-lg border-2 border-black bg-white relative">
            <p className="px-3 py-2 rounded-full left-1 top-2 bg-mygreen absolute">
              4
            </p>
            <img className="h-[50%] w-full rounded-b-lg" src={Support}></img>
            <p className="text-lg font-bold text-center">Ongoing Support</p>
            <p className="px-4 text-gray-600 text-base text-center">
              We provide continuous support and regular updates, ensuring your
              satisfaction and the optimal performance of your warehouse.
            </p>
          </div>
        </div>
        <a href="#contact">
          <p className="md:w-[30%] w-[60%] sideshadow text-white cursor-pointer bg-blue py-5 mx-auto text-center text-xl rounded-2xl font-semibold">
            Talk To Us Now
          </p>
        </a>
      </div>
    </div>
  );
}

export default Howitworks;
