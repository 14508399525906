import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import WaveSurfer from 'wavesurfer.js';
import styled from 'styled-components';
import {FaPause, FaPlay } from 'react-icons/fa';

const Waveform = ({ audio }) => {
    const containerRef = useRef();
    const waveSurferRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        const initWaveSurfer = () => {
            const waveSurfer = WaveSurfer.create({
                container: containerRef.current,
                responsive: true,
                barWidth: 1,
                barHeight: 5,
                cursorWidth: 0,
                height :30,
            });

            waveSurfer.load(audio);
            waveSurfer.on('ready', () => {
                waveSurferRef.current = waveSurfer;
              
            });

            waveSurfer.on('error', (error) => {
                console.error('WaveSurfer error:', error);
            });

            return () => {
                waveSurfer.destroy();
            };
        };

        if (!waveSurferRef.current) {
            initWaveSurfer();
        } else {
            // Handle re-render logic if needed
        }
    }, [audio]);

    const togglePlayPause = () => {
        if (waveSurferRef.current) {
            waveSurferRef.current.playPause();
            setIsPlaying(waveSurferRef.current.isPlaying());
        }
    };

    return (
        <div className="bg-white rounded-lg p-2 mt-4 ">
  <WaveSurferWrap>
    <button onClick={togglePlayPause} type="button" className="text-blue ">
      {isPlaying ? <FaPause /> : <FaPlay />}
    </button>
    <div ref={containerRef} className="progress rounded-lg  " />
  </WaveSurferWrap>
</div>

    );
};

Waveform.propTypes = {
    audio: PropTypes.string.isRequired,
};

const WaveSurferWrap = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;  
`;


export default Waveform;
