import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import person from "../../assests/personTest.png";
import ornaments from "../../assests/Ornament.png";
function Testimonals() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="w-full py-5 overflow-hidden">
      <div className="lg:w-[80%] xl:w-[65%] h-full   mx-auto relative">
        <img
          className="absolute bottom-0 lg:flex hidden -left-[90px]"
          src={ornaments}
          alt=""
        />
        <img
          className="absolute top-0 rotate-180 lg:flex hidden -z-10 -right-[90px]"
          src={ornaments}
          alt=""
        />
        <Slider className="w-full h-full" {...settings}>
          <div className="bg-white w-full lg:h-[380px] xl:h-[340px] 2xl:h-[400px]   lg:border-2 lg:border-blue rounded-xl overflow-hidden">
            <div className="w-full h-full flex lg:flex-row flex-col">
              <div className="lg:w-[30%] z-10 homefunded:w-[300px] w-[220px] mx-auto h-full">
                <img
                  src={person}
                  className="w-full h-full rounded-lg lg:rounded-none object-center shadow-xl lg:shadow-none"
                  alt=""
                />
              </div>
              <div className="lg:w-[70%] h-full lg:-mt-0 -mt-2 md:-mt-5 bg-white  w-[85%] mx-auto lg:p-8 ">
                <div className="w-full rounded-xl flex flex-col gap-2 h-full lg:p-0 p-3 lg:border-0 border-2 border-blue">
                  <p>April 14, 2022</p>
                  <h1 className="text-2xl font-semibold">Ramesh Iyer</h1>
                  <p className="  text-sm text-justify 2xl:text-base ">
                    Riofin provided an outstanding warehouse rental solution
                    that surpassed our expectations. Their team thoroughly
                    understood our requirements, offering strategic advice that
                    perfectly matched our business objectives. They efficiently
                    secured an optimal warehouse location and negotiated
                    favorable lease terms, ensuring a smooth process from start
                    to finish. Their in-depth market analysis provided
                    invaluable insights, enabling us to make well-informed
                    decisions. Throughout our partnership, Riofin demonstrated
                    professionalism and a strong commitment to client
                    satisfaction. We highly recommend Riofin for their
                    exceptional service and ability to deliver customized
                    warehouse solutions that enhance operational efficiency and
                    support business expansion.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white w-full lg:h-[380px] xl:h-[340px] 2xl:h-[400px]   lg:border-2 lg:border-blue rounded-xl overflow-hidden">
            <div className="w-full h-full flex lg:flex-row flex-col">
              <div className="lg:w-[30%] z-10 homefunded:w-[300px] w-[220px] mx-auto h-full">
                <img
                  src={person}
                  className="w-full h-full rounded-lg lg:rounded-none object-center shadow-xl lg:shadow-none"
                  alt=""
                />
              </div>
              <div className="lg:w-[70%] h-full lg:-mt-0 -mt-2 md:-mt-5 bg-white  w-[85%] mx-auto lg:p-8 ">
                <div className="w-full rounded-xl flex flex-col gap-2 h-full lg:p-0 p-3 lg:border-0 border-2 border-blue">
                  <p>April 14, 2022</p>
                  <h1 className="text-2xl font-semibold">Ramesh Iyer</h1>
                  <p className="  text-sm text-justify 2xl:text-base">
                    Riofin provided an outstanding warehouse rental solution
                    that surpassed our expectations. Their team thoroughly
                    understood our requirements, offering strategic advice that
                    perfectly matched our business objectives. They efficiently
                    secured an optimal warehouse location and negotiated
                    favorable lease terms, ensuring a smooth process from start
                    to finish. Their in-depth market analysis provided
                    invaluable insights, enabling us to make well-informed
                    decisions. Throughout our partnership, Riofin demonstrated
                    professionalism and a strong commitment to client
                    satisfaction. We highly recommend Riofin for their
                    exceptional service and ability to deliver customized
                    warehouse solutions that enhance operational efficiency and
                    support business expansion.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white w-full lg:h-[380px] xl:h-[340px] 2xl:h-[400px]   lg:border-2 lg:border-blue rounded-xl overflow-hidden">
            <div className="w-full h-full flex lg:flex-row flex-col">
              <div className="lg:w-[30%] z-10 homefunded:w-[300px] w-[220px] mx-auto h-full">
                <img
                  src={person}
                  className="w-full h-full rounded-lg lg:rounded-none object-center shadow-xl lg:shadow-none"
                  alt=""
                />
              </div>
              <div className="lg:w-[70%] h-full lg:-mt-0 -mt-2 md:-mt-5 bg-white  w-[85%] mx-auto lg:p-8 ">
                <div className="w-full rounded-xl flex flex-col gap-2 h-full lg:p-0 p-3 lg:border-0 border-2 border-blue">
                  <p>April 14, 2022</p>
                  <h1 className="text-2xl font-semibold">Ramesh Iyer</h1>
                  <p className="  text-sm text-justify 2xl:text-base">
                    Riofin provided an outstanding warehouse rental solution
                    that surpassed our expectations. Their team thoroughly
                    understood our requirements, offering strategic advice that
                    perfectly matched our business objectives. They efficiently
                    secured an optimal warehouse location and negotiated
                    favorable lease terms, ensuring a smooth process from start
                    to finish. Their in-depth market analysis provided
                    invaluable insights, enabling us to make well-informed
                    decisions. Throughout our partnership, Riofin demonstrated
                    professionalism and a strong commitment to client
                    satisfaction. We highly recommend Riofin for their
                    exceptional service and ability to deliver customized
                    warehouse solutions that enhance operational efficiency and
                    support business expansion.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='bg-white w-full lg:h-[420px]   lg:border-2 lg:border-blue rounded-xl overflow-hidden'>
            <div className='w-full h-full flex lg:flex-row flex-col'>
                <div className='lg:w-[30%] z-10 homefunded:w-[300px] w-[220px] mx-auto h-full'>
                    <img src={person} className='w-full h-full rounded-lg lg:rounded-none object-center shadow-xl lg:shadow-none' alt="" />
                </div>
                <div className='lg:w-[70%] h-full lg:-mt-0 -mt-2 md:-mt-5 bg-white  w-[85%] mx-auto lg:p-8 '>
                    <div className='w-full rounded-xl flex flex-col gap-3 h-full lg:p-0 p-3 lg:border-0 border-2 border-blue'>
                        <p>April 14, 2022</p>
                        <h1 className='text-2xl font-semibold'>Ramesh Iyer</h1>
                        <p className='lg:text-lg  text-xs text-justify 2xl:text-lg'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.</p>
                    </div>

                </div>


            </div>
            </div>
                 <div className='bg-white w-full lg:h-[420px] xl:h-[380px]   lg:border-2 lg:border-blue rounded-xl overflow-hidden'>
            <div className='w-full h-full flex lg:flex-row flex-col'>
                <div className='lg:w-[30%] z-10 homefunded:w-[300px] w-[220px] mx-auto h-full'>
                    <img src={person} className='w-full h-full rounded-lg lg:rounded-none object-center shadow-xl lg:shadow-none' alt="" />
                </div>
                <div className='lg:w-[70%] h-full lg:-mt-0 -mt-2 md:-mt-5 bg-white  w-[85%] mx-auto lg:p-8 '>
                    <div className='w-full rounded-xl flex flex-col gap-3 h-full lg:p-0 p-3 lg:border-0 border-2 border-blue'>
                        <p>April 14, 2022</p>
                        <h1 className='text-2xl font-semibold'>Ramesh Iyer</h1>
                        <p className='lg:text-lg  text-xs text-justify 2xl:text-lg'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.</p>
                    </div>

                </div>


            </div>
            </div> */}
          {/* <div className='bg-white   border-2 border-blue rounded-xl'>
            <div className='w-full h-full flex lg:flex-row flex-col'>
                <div className='w-[30%] h-full'>
                    <img src={person} className='w-full h-full object-center' alt="" />
                </div>
                <div className='w-[70%] p-8'>
                    <div className='w-full flex flex-col gap-2 h-full'>
                        <p>April 14, 2022</p>
                        <h1 className='text-2xl font-semibold'>Ramesh Iyer</h1>
                        <p className='lg:text-[13px] 2xl:text-lg'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.</p>
                    </div>

                </div>


            </div>
            </div>
            <div className='bg-white   border-2 border-blue rounded-xl'>
            <div className='w-full h-full flex lg:flex-row flex-col'>
                <div className='w-[30%] h-full'>
                    <img src={person} className='w-full h-full object-center' alt="" />
                </div>
                <div className='w-[70%] p-8'>
                    <div className='w-full flex flex-col gap-2 h-full'>
                        <p>April 14, 2022</p>
                        <h1 className='text-2xl font-semibold'>Ramesh Iyer</h1>
                        <p className='lg:text-[13px] 2xl:text-lg'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.</p>
                    </div>

                </div>


            </div>
            </div> */}
        </Slider>
      </div>
    </div>
  );
}

export default Testimonals;
