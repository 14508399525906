import React, { useState } from 'react';

const Form1 = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    city: '',
    investAmount: '',
    preferredLanguage: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data:', formData);
    // Add your form submission logic here
  };

  
  return (
    <div className="flex justify-center items-center mt-3 mb-3">
      <form className="bg-gray-100 ml-5 mr-5 p-5 md:p-10 mt-0 mb-0 shadow-xl rounded-xl w-full md:max-w-lg" onSubmit={handleSubmit}>
        <h1 className='font-bold text-center text-2xl mb-5'>
          Talk To Us
        </h1>
        <label className="block mb-2">
          Name:
          <input
            className="w-full p-2 border border-gray-300 rounded mt-1"
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block mb-2">
          Email:
          <input
            className="w-full p-2 border border-gray-300 rounded mt-1"
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block mb-2">
          Mobile Number:
          <input
            className="w-full p-2 border border-gray-300 rounded mt-1"
            type="text"
            name="mobileNumber"
            placeholder="Mobile Number"
            value={formData.mobileNumber}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block mb-2">
          City:
          <input
            className="w-full p-2 border border-gray-300 rounded mt-1"
            type="text"
            name="city"
            placeholder="City"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block mb-2">
          Invest Amount:
          <input
            className="w-full p-2 border border-gray-300 rounded mt-1"
            type="text"
            name="investAmount"
            placeholder="Invest Amount"
            value={formData.investAmount}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block mb-2">
          Preferred Language:
          <textarea
            className="w-full p-2 border border-gray-300 rounded mt-1"
            name="preferredLanguage"
            placeholder="Preferred Language"
            value={formData.preferredLanguage}
            onChange={handleChange}
            required
          />
        </label>

        <button type="submit" className='bg-green-500 text-white py-2 px-10 font-bold rounded mt-4'>Submit</button>
      </form>
    </div>
  );
};

export default Form1;
