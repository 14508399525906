import React from 'react';

const Weprovide = () => {
    return (
        <div className='p-6 md:p-16 '>
            <div className='grid grid-cols-1 lg:grid-cols-2 md:grid-cols-0  gap-4'>
                <div className='mb-4 md:mb-0'>
                    <div className='lg:mt-4'>
                        <h1 className='text-black font-bold text-left text-xl md:text-4xl relative'>
                            We Provide&nbsp;
                            <br className="hidden md:block" />
                            Fractional Ownership in  
                            <div className='border-b-4 mb-[-10px] border-mygreen pt-2 md:pt-4 pb-4 md:absolute right-0 md:left-[-70px] bottom-0  w-full md:w-1/1 font-sans'></div>
                            </h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Weprovide;
