import React from "react";
import image1 from "../../assests/requirement.png";
import image2 from "../../assests/property.png";
import image3 from "../../assests/property_visit.png";
import image4 from "../../assests/Negotiation.png";
import image5 from "../../assests/paper_work.png";
import arrow1 from "../../assests/Arc 10.png";
import arrow2 from "../../assests/Arc 1 (1).png";
import { FaLongArrowAltDown } from "react-icons/fa";

const Warehouse = () => {
  return (
    <>
      <div className="space-y-5">
        <h1 className="text-center text-3xl pt-10 font-bold">
          Pan India Warehouse Network
        </h1>
        <div className="flex justify-end ">
          <hr className="h-0.5 w-1/2 bg-mygreen mr-0" />
        </div>
        <p className="text-center md:w-[83%] w-full mx-auto leading-loose text-gray-600 px-10 py-5">
          Explore our Pan India Warehouse Network for ideal storage solutions
          across the country. From urban hubs to strategic locations, we offer
          state-of-the-art facilities tailored to your business needs, ensuring
          seamless logistics operations nationwide. Discover your perfect
          warehouse today.
        </p>
      </div>
      <div>
        <h1 className="text-blue text-2xl text-center font-semibold">
          Step by Step Process
        </h1>
        <div className="grid md:grid-cols-9 sm:grid-cols-5 md:gap-0 gap-3 homefunded:grid-cols-3 place-items-center p-10 lg:px-32">
          <div className="flex flex-col justify-center items-center">
            <img src={image1} alt="" className="w-24 h-24" />
            <p className="text-center">Requirement Gathering</p>
          </div>
          <div className="homefunded:block hidden">
            <img src={arrow1} alt="" className="-mt-20" />
          </div>
          <div className="homefunded:hidden block">
            <FaLongArrowAltDown className="h-20  text-mygreen" />
          </div>
          <div className="flex flex-col justify-center items-center">
            <img src={image2} alt="" className="w-24 h-24" />
            <p className="text-center">Property Shortlisting</p>
          </div>
          <div className="sm:block hidden">
            <img src={arrow2} alt="" className="mt-28 " />
          </div>
          <div className="homefunded:hidden block">
            <FaLongArrowAltDown className="h-20  text-mygreen" />
          </div>
          <div className="flex flex-col justify-center items-center">
            <img src={image3} alt="" className="w-24 h-24" />
            <p className="text-center">Property Visit</p>
          </div>
          <div className="homefunded:block hidden">
            <img src={arrow1} alt="" className="-mt-20 md:block sm:hidden" />
          </div>
          <div className="homefunded:hidden block">
            <FaLongArrowAltDown className="h-20  text-mygreen" />
          </div>
          <div className="flex flex-col justify-center items-center">
            <img src={image4} alt="" className="w-24 h-24" />
            <p className="text-center">Negotiation</p>
          </div>
          <div className="homefunded:block hidden">
            <img src={arrow2} alt="" className="mt-28 sm:block hidden" />
          </div>
          <div className="homefunded:hidden block">
            <FaLongArrowAltDown className="h-20  text-mygreen" />
          </div>
          <div className="flex flex-col justify-center items-center">
            <img src={image5} alt="" className="w-24 h-24" />
            <p className="text-center">Property Handover</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Warehouse;
