import React, { useState } from 'react';
import { RiCheckDoubleLine } from 'react-icons/ri';
import { AiFillDollarCircle } from "react-icons/ai";
import { TbMapPinPin } from "react-icons/tb";
import { RiTeamFill } from "react-icons/ri";


const cardContent = [
  {
    title: 'Maximize Operational Efficiency',
    description: ' Streamline your warehouse operations with professional asset management, ensuring optimal space utilization, inventory accuracy, and seamless logistics to boost productivity and reduce costs.',
    icon: RiCheckDoubleLine,
  },
  // Add content for the other three cards here
  {
    title: '  Enhance Inventory Control',
    description: 'Gain precise control over your inventory with advanced tracking and management systems, minimizing stock discrepancies and enhancing order fulfillment accuracy.',
    icon:TbMapPinPin,
  },
  {
    title: 'Cost-Effective Solutions',
    description: 'Reduce overhead costs by optimizing warehouse layout, implementing energy-efficient practices, and negotiating better lease terms, all through expert asset management.',
    icon: RiTeamFill,
  },
  {
    title: '  Improve Risk Management',
    description: 'Protect your assets with robust security measures, regular maintenance schedules, and compliance with industry standards, mitigating potential risks and ensuring business continuity.',
    icon: AiFillDollarCircle,
  },
];

const Realestate = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className="bg-assetcard md:h-[800px] lg:h-[650px] py-20 mt-6 p-4 ">
      <h1 className="font-bold text-center text-black text-xl md:text-4xl mb-10 md:mt-0">
      Why Opt for Warehouse Asset Management?
      </h1>

      <div className="md:px-10 px-5 py-7 grid grid-cols-1 md:grid-cols-0 sm:grid-cols-2  lg:grid-cols-4 gap-4 lg:gap-8 ">
        {cardContent.map((card, index) => (
          <div className="group" key={index}>
            <div
              className="bg-white group-hover:bg-blue pt-4 rounded-[18px] overflow-hidden shadow-white"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div className="flex items-center justify-center">
                {hoveredIndex === index ? (
                  React.createElement(card.icon, { size: 72 , className: 'text-white' })
                ) : (
                  React.createElement(card.icon, { size: 72, className: 'text-blue' })
                )}
              </div>
              <div className="px-3 lg:px-3 py-2 lg:py-4 sm:h-60 lg:h-80 xl:h-72 ">
                <div className="text-white max-h-fit text-center pt-2 pb-2 pr-1 pl-1 lg:pt-4 lg:pb-4 lg:pr-1 lg:pl-1">
                  <h1 className="text-black group-hover:text-white font-bold font-sans text-xl mb-3 ">{card.title}</h1>
                  <p className="text-black group-hover:text-white text-sm px-1 py-1 justify-center">{card.description}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Realestate;
