import React, { useState, useEffect } from "react";
import Achieve from "../../assests/Desktop - 3.png";

const Achievements = () => {
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);

  useEffect(() => {
    const interval1 = setInterval(() => {
      setCount1((prevCount) => Math.min(prevCount + 1, 24));
    }, 50);
    return () => clearInterval(interval1);
  }, []);

  useEffect(() => {
    const interval2 = setInterval(() => {
      setCount2((prevCount) => Math.min(prevCount + 1, 20));
    }, 50);
    return () => clearInterval(interval2);
  }, []);

  useEffect(() => {
    const interval3 = setInterval(() => {
      setCount3((prevCount) => Math.min(prevCount + 1, 10));
    }, 50);
    return () => clearInterval(interval3);
  }, []);

  return (
    <div className="relative flex md:mt-2 mb-10 mt-2 items-center justify-center">
      <img
        src={Achieve}
        className="w-full h-full object-cover object-center hidden lg:block "
        alt="Achievements"
      />
      <div className="lg:absolute top-1/2 left-1/2 transform lg:-translate-x-1/2 lg:-translate-y-1/2  w-full max-w-8xl mx-auto">
        <div className="mb-16">
          <h1 className="text-black font-bold md:text-center text-3xl lg:text-5xl text-center lg:text-right mr-4 md:mr-36 md:mt-0 mt-10">
            Achievements
          </h1>
          <div className="grid grid-cols-2">
            <div className=""></div>
            <div className="">
              <div className="bg-mygreen h-0.5 lg:w-[70%] w-full lg:ml-[30%] md:mr-48" />
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row md:justify-evenly lg:ml-56 text-center  ml-auto gap-4 mt-5 mb-5 lg:mb-28">
          <div className=" hidden md:hidden lg:block mb-4 pl-3 ">
            <span className="text-4xl md:text-6xl font-bold text-darkBluelg:w-1/4">
              {count1}
            </span>
            <span className="text-4xl md:text-6xl font-bold text-blue ml-2 md:mr-2">
              +
            </span>
            <p className="text-countertext text-xl md:text-1xl mt-2">
              Years Of <br />
              Experience
            </p>
          </div>

          <div className=" hidden md:hidden lg:block mb-4 pl-3">
            <span className="text-4xl md:text-6xl font-bold text-darkBlue md:w-1/4">
              {count2}
            </span>
            <span className="text-4xl md:text-6xl font-bold text-blue ml-2 md:mr-2">
              +
            </span>
            <p className="text-countertext text-xl md:text-1xl mt-2">
              Crores Tenant
              <br /> Rent PA
            </p>
          </div>

          <div className="hidden md:hidden lg:block mb-4 pl-3">
            <span className="text-4xl md:text-6xl font-bold text-darkBlue  lg:w-1/4">
              {count3}
            </span>
            <span className="text-4xl md:text-6xl font-bold text-blue ml-2 md:mr-2">
              +
            </span>
            <p className="text-countertext text-xl md:text-1xl mt-2">
              Warehouse Fully
              <br /> Managed
            </p>
          </div>

          <div className="hidden md:hidden lg:block mb-4  pl-3">
            <span className="text-4xl md:text-6xl font-bold text-darkBlue  lg:w-1/4">
              200
            </span>
            <span className="text-4xl md:text-6xl font-bold text-blue ml-2 md:mr-2">
              %
            </span>
            <p className="text-countertext text-xl md:text-1xl mt-2">
              YOY Market
              <br /> Demand
            </p>
          </div>

          {/* Mobile view */}

          <div className="">
            <div className=" lg:hidden md:block bg-white shadow-xl m-5 py-2">
              <span className="text-4xl md:text-6xl font-bold text-darkBlue">
                {count1}
              </span>
              <span className="text-4xl md:text-6xl font-bold text-blue ml-2 md:mr-2">
                +
              </span>
              <p className="text-countertext font-semibold text-center text-xl md:text-[17px] mt-2">
                Year' of Experience
              </p>
            </div>
            <div className=" lg:hidden md:block bg-white shadow-xl m-5 py-2  ">
              <span className="text-4xl md:text-6xl font-bold text-darkBlue text-center">
                {count2}
              </span>
              <span className="text-4xl md:text-6xl font-bold text-blue ml-2 md:mr-2">
                +
              </span>
              <p className="text-countertext font-semibold text-center text-xl md:text-[17px] mt-2">
                {" "}
                Crores Tenant Rent PA
              </p>
            </div>
            <div className=" lg:hidden md:block bg-white shadow-xl m-5 py-2  ">
              <span className="text-4xl md:text-6xl font-bold text-darkBlue text-center">
                {count3}
              </span>
              <span className="text-4xl md:text-6xl font-bold text-blue ml-2 md:mr-2">
                +
              </span>
              <p className="text-countertext font-semibold text-center text-xl md:text-[17px] mt-2">
                Warehouse Fully Managed
              </p>
            </div>
            <div className=" lg:hidden md:block bg-white shadow-xl m-5 py-2  ">
              <span className="text-4xl md:text-6xl font-bold text-darkBlue text-center">
                200
              </span>
              <span className="text-4xl md:text-6xl font-bold text-blue ml-2 md:mr-2">
                %
              </span>
              <p className="text-countertext font-semibold text-center text-xl md:text-[17px] mt-2">
                YOY Market Demand
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Achievements;
