import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-scroll";

const CallToAction = () => {
  useEffect(() => {
    Aos.init({
      duration: 800,
    });
  }, []);
  return (
    <div className="py-8 md:py-16  bg-black text-center text-white px-4 overflow-hidden border-[1px] border-white border-dotted">
      <div
        className="md:pt-16 md:px-8 md:mx-auto max-w-4xl rounded-[18px] overflow-hidden border-b-[2px] shadow-[6px_6px_white] bg-gradient-to-b from-blue to-indigo-800 p-8"
        data-aos="zoom-out-up"
      >
        <h2 className="text-2xl md:text-4xl font-bold mb-4">
          Let’s Enhance Your Warehouse Portfolio
        </h2>

        <button
          className="bg-mygreen font-bold text-sm md:text-[16px] text-black py-2 px-4 rounded-md mt-4 border-black border-b-[3px] border-r-[3px] border-l-[1px] border-t-[1px] ease-linear transform hover:scale-125 transition duration-500"
          onClick={() =>
            (window.location.href = "https://riofinadmin.whytap.tech")
          }
        >
          Talk To Our Warehouse Specialist
        </button>
      </div>
    </div>
  );
};

export default CallToAction;
