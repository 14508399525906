import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft, faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aos from "aos";
import "aos/dist/aos.css";

import Waveform from "./AudioTestimonail";
import audio1 from "../../audio/src_piano.mp3";

const rioApiUrl = process.env.REACT_APP_API_URL;

const TestimonialSection = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(-1);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await fetch(`${rioApiUrl}/user/testimonials`);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setTestimonials(data.testimonials);
      } catch (error) {
        console.error("Error fetching testimonials:", error.message);
      }
    };

    fetchTestimonials();
  }, []);

  const toggleExpanded = (index) => {
    setExpandedIndex(index === expandedIndex ? -1 : index);
  };

  const displayText = (description, index) =>
    expandedIndex === index
      ? description
      : description.split(" ").slice(0, 20).join(" ") + "...";

  const sliderRef = useRef(null);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1164,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div className="bg-footerblue py-8 md:py-12 lg:py-14 relative">
      <div className="pl-4">
      <button
  className="hidden md:block text-white font-bold mb-5 md:ml-20 border border-mygreen p-3 rounded-md md:mb-10"
  style={{ cursor: 'default' }}
>
  Trust is earned
</button>

      </div>
      <h1 className="text-white font-bold text-[24px] md:text-4xl pl-4 md:pl-20 mb-7 md:mb-4">
        Our Happy Clients About
        <br className="md:block hidden" /> Riofin Assets
      </h1>
      <hr className="bg-mygreen hidden lg:block h-1 lg:mr-[990px] lg:mb-4" data-aos="zoom-in-right" />
      <Slider ref={sliderRef} {...sliderSettings}>
  {testimonials.map((testimonial, index) => (
    <div key={index}>
      <div className="max-w-lg h-96 rounded-lg overflow-hidden border-b-2 shadow-[6px_6px_white] bg-gradient-to-b from-blue to-dlue p-4 md:p-8 md:ml-22 m-2 relative flex flex-col justify-between">
        <div>
          <p className="text-white" data-aos="fade-up">
            {displayText(testimonial.description, index)}
            <button
              onClick={() => toggleExpanded(index)}
              className="text-blue-500 underline ml-2"
            >
              {expandedIndex === index ? "Read Less" : "Read More"}
            </button>
          </p>
          <div className="flex items-center mt-2 md:mt-4" data-aos="fade-up">
            {testimonial.img_url && (
              <img
                src={testimonial.img_url}
                alt={`${testimonial.title}'s headshot`}
                className="rounded-full w-8 h-8 md:w-12 md:h-12 mr-2 md:mr-4"
              />
            )}
            <div>
              <h3 className="text-sm md:text-lg font-semibold text-white">{testimonial.title}</h3>
              <p className="text-xs md:text-sm text-white">{testimonial.extra_details}</p>
            </div>
          </div>
        </div>
        <div className="mt-4" data-aos="fade-up">
          
            <Waveform audio={audio1} />
     
        </div>
      </div>
    </div>
  ))}
</Slider>



      <div className="flex justify-center items-center md:absolute top-0 lg:mt-10 md:mt-16 mt-[5%] lg:top-40 right-4 md:right-16 ml-2 md:ml-8">
        <button className="" onClick={handlePrev}>
          <div className="w-6 h-6 md:w-8 md:h-8 mx-2" style={{ color: "white" }}>
            <FontAwesomeIcon icon={faCircleArrowLeft} style={{ fontSize: "2.0em" }} />
          </div>
        </button>
        <button className="" onClick={handleNext}>
          <div className="w-6 h-6 md:w-8 md:h-8 mx-2" style={{ color: "white" }}>
            <FontAwesomeIcon icon={faCircleArrowRight} style={{ fontSize: "2.0em" }} />
          </div>
        </button>
      </div>
    </div>
  );
};

export default TestimonialSection;
