import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import background1 from '../../assests/Frame 15.png';
import background2 from '../../assests/v2.png';
import background3 from '../../assests/v3.png';
import background4 from '../../assests/Loginrec.png';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assests/Riologo.png'

const Login = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState('');

  return (
    <div className=" relative">
      
      <Carousel className="" autoPlay={true} interval={5000} infiniteLoop={true} showStatus={false} showThumbs={false}>
        <div>
          <img src={background1} className="object-cover object-center" alt="" />
        </div>
        <div>
          <img src={background2} className="object-cover object-center" alt="" />
        </div>
        <div>
          <img src={background3} className="object-cover object-center" alt="" />
        </div>
      </Carousel>
      <div className=" flex mt-0 md:absolute inset-0 items-center p-4 lg:p-40 md:p-10 ">
      <div className="title-container  hidden md:flex flex-col items-center  md:pt-6 lg:pt-8  md:h-[280px] lg:h-[530px] w-full lg:w-1/2" style={{ backgroundImage: `url(${background4})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', position: 'relative' }}>
  <p className="font-bold" style={{ position: 'absolute', bottom: '0', color: 'white', padding: '8%', fontWeight: 'bold'  }}>Unlock your financial potential with RioFin, your trusted partner in smart investments.</p>
  <img src={Logo} className="lg:h-20 md:h-10" alt="" />
</div>


          <div className="bg-white lg:p-8 md:p-4   w-full lg:w-1/2 sm:pt-2 md:pt-4 lg:pt-6 h-fit  rounded">
          <form className="">
            <div className=" flex justify-end ">
              <Link to="/Login">
                <button className="py-2 px-6 mt-6  lg:text-md md:text-sm  font-bold bg-mygreen text-black  rounded-lg">
                  Log In
                </button>
              </Link>
              <Link to="/signupform">
                <button className="py-2 px-4 mt-6 lg:text-md md:text-sm  font-bold  bg-black text-white rounded-lg">
                  Sign Up
                </button>
              </Link>
            </div>

            <div className=" lg:pt-32 md:pt-6 pt-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="mobileNumber">
                Mobile Number
              </label>
              <input
                className="shadow-none lg:mb-10 md:mb-3 appearance-none border-b border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                id="mobileNumber"
                type="tel"
                placeholder="Enter Your Number"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                pattern="[0-9]{10}"
                required
              />

              <label className="block text-gray-700 text-sm font-bold lg:mb-2 md:mb-1 pt-2" htmlFor="otp">
                OTP
              </label>
              <input
                className="shadow-none appearance-none border-b border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                id="otp"
                type="tel"
                placeholder="Enter Your OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                pattern="[0-9]{10}"
                required
              />
            </div>

            <Link to='/signup'>
            <div className=" flex justify-end lg:pt-20 md:pt-3 pt-3">
      <button className='bg-black py-2 px-6 text-white font-bold text-center rounded-lg'>Sign In</button>
    </div>
            </Link>
          </form>
        </div>
      </div>
    </div>
      
     
  );
}

export default Login;
