import React, { useEffect, useState } from "react";

import SectionWithBoxes from "./page2.jsx";
import SectionWithIconBoxes3 from "./page3.jsx";
import Line from "./page4.jsx";
import Line2 from "./page5.jsx";
import Page6 from "./page6.jsx";

import Card from "./page8.jsx";

import Bannersection from "./Bannersection";
import Study from "./Study.jsx";
import Realestate from "./Realestate.jsx";

import Heading from "./Heading.jsx";
import Triangle from "./Triangle.jsx";
import MoreProfit from "./MoreProfit.jsx";
import WhatProperty from "./WhatProperty.jsx";
import AgricultureProps from "./AgricultureProps.jsx";
import WarehouseMangement from "./WarehouseMangement.jsx";
import WarehouseManagement1 from "./WarwhouseManagement1.jsx";
import SanityClient from "../../SanityClient.jsx";
import { Helmet } from "react-helmet";

const Aseets = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [data, setData] = useState(null);
  useEffect(() => {
    const getdata = () => {
      SanityClient.fetch(
        `*[_type=="ourUsp"]{
        metaTitle,
        metaDescriptions,
        MetaData,
        FocusKeyword,
        MetaURL
      }`
      )
        .then((res) => {
          setData(res[0]);
          console.log(res,"ourUsp");
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getdata();
  },[]);
  return (
    <>
      <Helmet>
        <title property="og:title">{data ? data.metaTitle : ""}</title>
        <meta
          property="og:description"
          content={data ? data.MetaDescription : ""}
        />
        <meta property="og:url" content={data ? data.MetaData : ""} />
        <meta name="keywords" content={data ? data.FocusKeyword : ""}></meta>
        <meta name="alldata" content={data ? data.MetaURL : ""}></meta>
      </Helmet>
      <Bannersection />
      <Study />
      <Realestate />
      <Triangle />
      <Heading />
      <SectionWithBoxes />
      <SectionWithIconBoxes3 />
      {/* <Line/> */}
      <Line2 />
      <Page6 />
      <WarehouseMangement />
      <WarehouseManagement1 />
      <MoreProfit />

      <Card />

      {/* <WhatProperty /> */}
      {/* <AgricultureProps /> */}
    </>
  );
};

export default Aseets;
