// App.js

import React, { useState } from "react";
import "./flip.css";
import img1 from "../../assests/flipping1.jpg";
import img2 from "../../assests/flipping2.jpg";
import img3 from "../../assests/a-list3.png";

const GridSection3 = () => {
  const cards = [
    {
      id: 1,
      frontImage: img1,
      title: "Expertise in Property Flipping:",
      backContent:
        "At Riofin, we excel in property flipping by identifying undervalued properties, strategically renovating them, and swiftly reselling for optimal returns. Our expertise ensures you capitalize on market opportunities effectively.",
    },
    {
      id: 2,
      frontImage: img2,
      title: "Specialization in Bank Auction Properties:",
      backContent:
        "Navigate bank auction properties with Riofin’s specialized knowledge. We guide you through the intricacies of acquiring distressed assets at advantageous prices, maximizing your investment potential with thorough market insights.",
    },
    {
      id: 3,
      frontImage: img3,
      title: "Comprehensive Support and Guidance:",
      backContent:
        "Benefit from Riofin’s comprehensive support throughout your property investment journey. From initial acquisition strategies to renovation planning and profitable resale, we provide tailored solutions to enhance your investment portfolio.",
    },
  ];

  const [flippedCards, setFlippedCards] = useState([]);

  const handleFlip = (cardId) => {
    setFlippedCards((prevFlippedCards) => {
      if (prevFlippedCards.includes(cardId)) {
        return prevFlippedCards.filter((id) => id !== cardId);
      } else {
        return [...prevFlippedCards, cardId];
      }
    });
  };

  return (
    <div className="App w-full">
      <div className="text-center">
        <h1 className="text-black mt-4 font-bold text-xl md:text-3xl relative">
          Why Choose Riofin for Property Flipping and Bank Auction Properties?
          <div className="border-b-4 border-[#04ef00] md:absolute left-0 absolute   bottom-0 top-12 w-full md:w-1/2 font-sans mt-2"></div>
        </h1>
      </div>
      <div className="w-full flex justify-center items-center my-16 text-center">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {cards.map((card) => (
            <div
              key={card.id}
              className={`flip-card ${
                flippedCards.includes(card.id) ? "flipped" : ""
              }`}
            >
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img src={card.frontImage} alt={`Card ${card.id} Front`} />
                  <button
                    className="flip-button"
                    onClick={() => handleFlip(card.id)}
                  ></button>
                </div>
                <div className="flip-card-back">
                  <h1 className="card-content">{card.title}</h1>
                  <div className="card-content -mt-5 sm:text-base text-xs">{card.backContent}</div>
                  <button
                    className="flip-button"
                    onClick={() => handleFlip(card.id)}
                  ></button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GridSection3;
