import React from "react";
import building from "../../assests/building.png";
import Testimonial from "../../assests/hero right.png";
import Banner1 from "../../assests/Banner (2).png";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <>
      <div className="relative mt-auto md:mt-14  lg:mt-auto md:full  flex flex-col md:flex-row  items-center bg-black md:py-48 lg:py-0">
        <img
          src={Banner1}
          className="w-full h-[500px] md:h-full object-cover object-center mt-20 md:mt-0 lg:block md:hidden"
          alt="Achievements"
        />

        <div className="absolute  bg-black md:flex-row w-full lg:w-auto  md:py-5 lg:py-0">
          <div className="flex-1  text-center lg:text-left px-4 md:px-28 py-8 md:py-0">
            <p className="text-mygreen font-bold font-customFont text-3xl md:text-2xl py-1">
              Explore the World of Warehouse Management
            </p>
            <h1 className="text-white font-customFont text-2xl md:text-5xl font-bold mt-1">
              We deal in Warehouse
            </h1>
            <div className="wrapper md:mt-2 my-5 w-full ">
              <div className="words mx-auto lg:mx-0 pl-0  overflow-hidden">
                <span className="font-customFont font-bold ">ACQUISITION</span>
                <span className="font-customFont font-bold">SALES</span>
                <span className="font-customFont font-bold">LEASING</span>
                <span className="font-customFont font-bold">MANAGEMENT</span>
              </div>
            </div>
            <p className="text-white text-lg md:text-2xl mt-2">
              Embark on a Pathway to Warehouse Wealth Management,
            </p>
            <p className="text-white text-lg md:text-2xl mt-2">
              Yielding Impressive Returns.
            </p>
            <Link to="/investment">
              {" "}
              <button className="bg-mygreen py-3 px-7 font-bold mt-4 md:mt-5 rounded-md">
                Explore Opportunities
              </button>
            </Link>
            <div className="flex flex-col md:flex-row items-center">
              <img
                src={Testimonial}
                className="py-3 md:mx-2 mt-5 md:mr-1 md:mt-5"
                alt="Testimonial"
              />
              <div className="text-white md:mt-10 px-3 md:px-0 md:ml-3 text-sm  md:text-center">
                <p className="hidden md:block mb-2 md:mb-0">
                  One of the best of their kind,
                  <br className="hidden md:inline" />
                  Recommend Riofin Assets.
                </p>
                <p className="md:hidden block mb-2 md:mb-0">
                  One of the best of their kind, Recommend Riofin Assets.
                </p>
                <p className="text-white md:text-right md:mt- md:ml-4">
                  -Raja
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-black md:hidden">
          <img src={building} />
        </div>
      </div>
    </>
  );
};

export default Banner;
