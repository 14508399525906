import React from 'react';
import real from '../../assests/Group 39518 (1).png';

const Triangle = () => {
  return (
    <div className="max-w-full">
      <img src={real} alt="Real Estate" className="w-full h-auto" />
    </div>
  );
};

export default Triangle;
