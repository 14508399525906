import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { IoCall } from "react-icons/io5";
import { TfiEmail } from "react-icons/tfi";
import { LiaTelegramPlane } from "react-icons/lia";
import { Link } from "react-router-dom";

const MapSection = () => {
  return (
    <div className="flex flex-col md:flex-row items-center">
      <div className="bg-blue md:w-full md:max-w-md lg:max-w-lg w-full h-200px] px-4 md:h-[400px]  ">
        <div className="  align-middle md:my-24 md:mx-10 mt-5 mb-5 px-2 ">
          <Link to="tel:+91 6374712624" target="_blank">
            <div className="text-white flex text-[17px] md:text-1xl   text-left  my-1 ">
              <IoCall size={26} className="text-white  mr-3" />
              +91 6374712624
            </div>
          </Link>
          <Link to="mailto:support@riofinassets.com" target="_blank">
            <div className="text-white flex text-[17px] md:text-1xl  text-left md:mt-10 my-5">
              <TfiEmail size={26} className="text-white  mr-3" />
              support@riofinassets.com
            </div>
          </Link>
          <Link to="https://www.google.com/maps/place/Riofin/@18.5613567,73.9422517,17z/data=!3m2!4b1!5s0x3bc2c3dfdb4670af:0x408f6ae11d5540b!4m6!3m5!1s0x3bc2c167140ce2e5:0x263d65360837153f!8m2!3d18.5613516!4d73.9448266!16s%2Fg%2F11s57fnfwt?entry=ttu" target="_blank">
            <div className="text-white flex  text-[17px] md:text-1xl text-left md:mt-10  my-1">
              <LiaTelegramPlane size={40} className="text-white mr-3" />
              #15A, 4th Floor, City Vista, Kharadi, Pune, Maharashtra - 411014
            </div>
          </Link>
        </div>
      </div>
      <div className="w-full">
        <iframe
          width="100%"
          height="400"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=riofin+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
          <a href="https://www.gps.ie/">gps systems</a>
        </iframe>
      </div>
    </div>
  );
};

export default MapSection;
