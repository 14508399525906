import React from "react";
import backgroundImage from "../../assests/a-banner@3x.png";

const Bannersection = () => {
  const cardStyle1 = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    <div className=" w-full  ">
      <div
        className=" items-center justify-center  w-full lg:py-60 md:py-32 py-20 "
        style={cardStyle1}
      >
        {/* Quote */}
        <h1 className="font-bold md:text-2xl text-xl text-black text-center py-2  px-5 md:px-20">
          At Riofin, we don't just manage warehouses; we cultivate
          opportunities, optimize efficiencies, and pave the way for a future
          where every asset thrives.
        </h1>
        <div className="text-center lg:text-left  lg:pl-[900px] ">
          <h1 className="text-black font-bold font-serif  md:text-2xl">
            - Riofin Assets
          </h1>
        </div>
      </div>
      {/* Additional text */}
      <h1 className="font-bold text-center text-[18px] md:text-4xl  my-3  mt-10">
        Riofin offers lucrative opportunities in the Warehouse segment.
      </h1>
      {/* Divider */}
      <div className="bg-mygreen md:mr-[400px] h-0.5 md:ml-auto mt-3" />
    </div>
  );
};

export default Bannersection;
