import React, { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { FaPlus, FaMinus } from "react-icons/fa6";

const Faq = () => {
  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const faq = [
    {
      qus: "Are there any hidden charges or fees associated with selling my warehouse to you in India?",
      ans: "No, there are absolutely no hidden charges or fees when you sell your warehouse to us. We believe in transparency and ensure a straightforward process without any unexpected costs.",
    },
    {
      qus: "How long does the selling process typically take for warehouses?",
      ans: "No, there are absolutely no hidden charges or fees when you sell your warehouse to us. We believe in transparency and ensure a straightforward process without any unexpected costs.",
    },
    {
      qus: "Will I need to make any repairs or renovations to my warehouse before selling it to you?",
      ans: "No, there are absolutely no hidden charges or fees when you sell your warehouse to us. We believe in transparency and ensure a straightforward process without any unexpected costs.",
    },
    {
      qus: "What documents do I need to provide when selling my warehouse to you?",
      ans: "No, there are absolutely no hidden charges or fees when you sell your warehouse to us. We believe in transparency and ensure a straightforward process without any unexpected costs.",
    },
    {
      qus: "How can I be sure that selling my warehouse to Riofin is the best option?",
      ans: "No, there are absolutely no hidden charges or fees when you sell your warehouse to us. We believe in transparency and ensure a straightforward process without any unexpected costs.",
    },
    {
      qus: "What if my warehouse is located in a remote area of India?",
      ans: "No, there are absolutely no hidden charges or fees when you sell your warehouse to us. We believe in transparency and ensure a straightforward process without any unexpected costs.",
    },
  ];
  return (
    <>
      <div className="bg-black overflow-hidden">
        <div className="lg:p-10 p-5 space-y-5">
          <h1 className="text-white text-3xl font-bold text-center">FAQs</h1>
          <div className="flex w-full justify-end ml-10">
            <hr className="w-1/2 h-0.5 bg-mygreen" />
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5 pt-5 items-start justify-center">
            {faq.map((item, index) => (
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                style={{ backgroundColor: "white", borderRadius: "5px" }}
                className={`${
                  expanded === `panel${index}` ? "h-full" : "sm:h-[70px]"
                } w-full`}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === `panel${index}` ? (
                      <FaMinus className="w-6 h-6 bg-lightblue rounded-sm text-white" />
                    ) : (
                      <FaPlus className="w-6 h-6 bg-gray-100" />
                    )
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>
                    <p className="text-sm sm:text-base">{item.qus}</p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{item.ans}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
        <div className="pb-7 flex flex-col justify-center items-center ">
          <a href="#contact">
            <button className="md:w-96 h-20 bg-blue rounded-xl border-r-8 border-b-8 border-black text-2xl  text-white font-bold ">
              Talk to Us Now
            </button>
          </a>
        </div>
      </div>
    </>
  );
};

export default Faq;
