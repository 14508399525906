import React from "react";
import Bck from "../../assests/team-people.jpg";
import { Link } from "react-router-dom";

const Dedicate = () => {
  return (
    <>
      <div className="p-2 md:p-10 text-black md:mr-8 md:ml-4">
        <h1 className="text-[26px] md:text-5xl font-sans font-bold lg:ml-12 mb-4 md:mb-8 leading-[40px] text-center md:text-left">
          Dedicated Teams <br />
          For your Dedicated Dreams.
        </h1>
        <img
          src={Bck}
          className="md:w-full md:h-[580px] w-full h-[300px] object-cover p-3  mb-6 rounded-lg"
          alt="background"
        />
      </div>
      <div className="md:text-center p-2  md:ml-5 md:mb-8 md:mr-0">
        <div className="grid grid-cols-1  md:grid-cols-2  ">
          <div className="border-2  ml-4 lg:ml-[86px] md:mr-[6px] md:ml-[40px] mr-4 lg:mb-0 md:mb-10 mb-10 lg:-mt-48  border-black bg-white rounded-2xl boxshadow">
            {/* <div className="border-2 pb-8 ml-[86px] mr-auto mb-10 -m-32 border-black bg-white rounded-2xl boxshadow"> */}
            <h1 className=" text-2xl md:text-4xl font-bold px-8 pt-4 font-sans pb-3 text-left mt-8">
              Why We Do This
            </h1>
            <hr className="hrw" />
            <p className="md:px-10 px-8 md:text-xl text-1xl text-justify pt-5 pb-8">
              Riofin passionately revolutionizes the buying and selling of
              warehouses, serving as your trusted partner throughout. Our
              commitment to swift deals and efficient warehouse asset management
              transforms investment possibilities, making them accessible to
              everyone. Join us in reshaping the warehouse market for a more
              inclusive and prosperous future.
            </p>
            <Link to="/blog">
              <button
                href=""
                className="  border-black border-b-[4px] border-r-[3px] border-l-[1px] border-t-[1px] rounded border  bg-mygreen md:py-2 py-2 px-4 text-sm  md:text-[16px] md:px-4 ml-8 mb-6  lg:ml-80 font-bold hover:ease-linear translate-x-0 transition duration-500"
              >
                Read our Blogs
              </button>
            </Link>
          </div>
          <div className="ml-2 lg:ml-12 md:ml-6 md:mr-12 lg:mb-2">
            <h1 className="text-2xl md:text-4xl text-center font-bold -mt-3 font-sans pb-3 lg:text-left md:text-center">
              Empowering Every Stage
            </h1>
            <hr className="hrh  mt-2 md:w-[90%]" />
            <p className="pr-4 pl-4 lg:pl-0 lg:pr-20 text-lg pt-2  text-left md:text-justify pb-5">
              Riofin partners with you through every phase of warehouse buying
              and selling. We offer transparent, innovative solutions and swift
              deal facilitation, empowering you to build a thriving, diverse
              portfolio for financial freedom.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dedicate;
