import React, { useEffect, useState } from "react";
import person from "../../assests/person.png";
import { FaRegCheckCircle } from "react-icons/fa";
import sales from "../../assests/buywarehouse.png";
import Cards from "./Cards";
import Contact from "./Cantact";
import Nation from "./Nation";
import Faq from "./Faq";
import Testimonal from "./Testimonial";
import BoxSection from "./Boxsection";
import SanityClient from "../../SanityClient";
import { Helmet } from "react-helmet";
import Form from "./Form";

function Banner() {
  const [data, setData] = useState(null);
  useEffect(() => {
    const getdata = () => {
      SanityClient.fetch(
        `*[_type=="buywarehouse"]{
          metaTitle,
          metaDescriptions,
          MetaData,
          FocusKeyword,
          MetaURL,
        }`
      )
        .then((res) => {
          setData(res[0]);
          console.log(res, "buy");
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getdata();
  }, []);
  return (
    <>
      <Helmet>
        <title property="og:title">{data ? data.metaTitle : ""}</title>
        <meta
          property="og:description"
          content={data ? data.MetaDescription : ""}
        />
        <meta property="og:url" content={data ? data.MetaData : ""} />
        <meta name="keywords" content={data ? data.FocusKeyword : ""}></meta>
        <meta name="alldata" content={data ? data.MetaURL : ""}></meta>
      </Helmet>
      <div className="flex flex-row items-center -mb-1 lg:h-[40vh] xl:h-[60vh]">
        <div className="p-10 lg:w-[45%] space-y-5">
          <p className="text-blue1 2xl:text-xl">Warehouse/Buy Warehouse</p>
          <h1 className="sm:text-3xl 2xl:text-4xl text-lg font-bold text-blue">
            Your Warehouse Awaits Fair Price, Easy Purchase!
          </h1>
          <hr className="w-1/2 h-0.5 bg-mygreen" />
          <div className="flex sm:flex-row flex-col md:gap-0 gap-3 lg:justify-between justify-around items-center">
            <div className="flex flex-col gap-3">
              <div className="flex flex-row items-center gap-3">
                <FaRegCheckCircle className="w-6 h-6 text-mygreen" />
                <p className="2xl:text-xl">No lengthy process</p>
              </div>
              <div className="flex flex-row items-center gap-3">
                <FaRegCheckCircle className="text-mygreen w-6 h-6" />
                <p className="2xl:text-xl">No delays</p>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex flex-row items-center gap-3">
                <FaRegCheckCircle className="text-mygreen w-6 h-6" />
                <p className="2xl:text-xl">No hidden charges</p>
              </div>
              <div className="flex flex-row items-center gap-3">
                <FaRegCheckCircle className="text-mygreen w-6 h-6" />
                <p className="2xl:text-xl">No Obligation</p>
              </div>
            </div>
          </div>
          <a href="#contact">
            <div className="w-full flex mt-5">
              <button className="text-white bg-blue p-3 lg:mx-0 mx-auto px-8 text-center rounded-full 2xl:text-lg">
                GET IN TOUCH
              </button>
            </div>
          </a>
        </div>
        <div className="lg:w-[55%] h-full lg:block hidden">
          <img src={sales} alt="" className="w-full h-full object-cover" />
        </div>
      </div>
      <Cards />
      <Contact />
      <Nation />
      <Faq />
      <Testimonal />
      <BoxSection />
      <Form />
    </>
  );
}

export default Banner;
