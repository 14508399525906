import React from "react";

const Form = () => {
  return (
    <>
      <div id="contact" className="bg-black text-white">
        <h1 className="text-4xl font-bold text-center p-5">Get In Touch</h1>
        <div className="flex justify-end">
          <hr className="w-1/2 h-0.5 bg-mygreen mb-5" />
        </div>
        <div className="w-1/2 mx-auto space-y-5">
          <div className="flex flex-row justify-center gap-5 items-center">
            <div className="flex flex-col justify-center w-full gap-1">
              <label htmlFor="">Name</label>
              <input
                type="text"
                className="border border-gray-300 w-full h-9 rounded-md"
              />
            </div>
            <div className="flex flex-col justify-center gap-1 w-full">
              <label htmlFor="">Phone No</label>
              <input
                type="text"
                className="border border-gray-300 w-full h-9 rounded-md"
              />
            </div>
          </div>
          <div className="flex flex-col justify-center gap-1">
            <label htmlFor="">Message</label>
            <textarea
              name=""
              id=""
              className="border border-gray-300 w-full rounded-md"
              rows={4}
            ></textarea>
          </div>
          <div className="w-full">
            <button className="bg-blue text-white p-2 px-5 rounded-md mb-5">Submit</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
