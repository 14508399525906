import React, { useEffect, useState } from 'react'
import Banner from './Banner'
import Howitworks from './Howitworks'
import Faq from './Faq'
import Testimonal from './Testimonial'
import Pricing from './Pricing'
import Benefits from './Benefits'
import Cards from './Cards'
import Cards2 from './Cards2'
import SanityClient from '../../SanityClient'
import { Helmet } from 'react-helmet'
import Contact from './Contact'

function AssetMain() {
  const [data,setData] = useState(null);
  useEffect(()=>{
    const getdata = () =>{
      SanityClient.fetch(`*[_type=="warehouseasset"]{
        metaTitle,
        metaDescriptions,
        MetaData,
        FocusKeyword,
        MetaURL
      }`).then((res)=>{
        setData(res[0]);
        console.log(res,"warehouseAsset");
      }).catch((err)=>{
        console.log(err);
      })
    };
    getdata();
  },[])
  return (
    <div>
        <Helmet>
        <title property="og:title">{data ? data.metaTitle : ""}</title>
        <meta
          property="og:description"
          content={data ? data.MetaDescription : ""}
        />
        <meta property="og:url" content={data ? data.MetaData : ""} />
        <meta name="keywords" content={data ? data.FocusKeyword : ""}></meta>
        <meta name="alldata" content={data ? data.MetaURL : ""}></meta>
      </Helmet>
        <Banner />
        <Cards />
        <Howitworks />
        <Testimonal />
        <Cards2 />
        <Benefits />
        {/* <Pricing /> */}
        <Faq />
        <Contact />
    </div>
  )
}

export default AssetMain