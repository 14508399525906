import React, { useEffect } from "react";
import Vector from "../../assests/Vector 714.png";
import Rectangle3 from "../../assests/Rectangle 3.png";
import Rectangle4 from "../../assests/Rectangle 4.png";
import Rectangle8 from "../../assests/Rectangle8.png";
import unique from "../../assests/system-uicons_scale.png";
import User from "../../assests/user 1 (1).png";
import left from "../../assests/bxs_quote-left.png";
import right from "../../assests/bxs_quote-left (1).png";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Funded1 = () => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);

  return (
    <>
      <div className="hidden lg:block  ">
        <div className="lg:flex md:items-center md:justify-between gap-8 md:px-10 lg:px-20 xl:px-32">
          <div></div>
          <h1 className="md:text-4xl text-[22px] md:ml-20 font-sans font-bold  md:mt-20  md:text-center">
            Successfully Managed
          </h1>
          <div className="md:flex md:items-center gap-3">
            <Link to="/investment">
              {" "}
              <p className="hidden text-xl md:mt-20 md:block">Explore all</p>
            </Link>
            <img
              src={Vector}
              alt="Explore All"
              className="w-6 h-6 md:mt-20 md:w-auto md:h-auto cursor-grab"
            />
          </div>
        </div>
        {/* <hr className="md:ml-[650px] md:mt-4 md:mr-auto mt-3 md:mb-20 h-0.5 bg-mygreen hidden md:block" /> */}
        <div
          className="md:ml-[650px] md:mt-4 md:mr-auto mt-3 md:mb-20 h-0.5 bg-mygreen  hidden md:block"
          data-aos="zoom-in-right"
        />
      </div>

      {/* tabsection */}
      <div className="lg:hidden sm:block  px-8 lg:px-20 xl:px-32">
        <h1 className="md:text-4xl text-[22px] font-bold text-center mt-6">
          Successfully Managed
        </h1>
        <div className="flex items-center justify-center mt-3">
          <p className="md:text-lg text-[20px]">Explore all</p>
          <img
            src={Vector}
            alt="Explore All"
            className="md:w-6 md:h-6 w-3 h-3 ml-2 cursor-grab"
          />
        </div>
        <hr className="bg-mygreen p-0.5 w-full" />
      </div>

      <div className="md:relative" data-aos="fade-up">
        {/* cards */}
        <div className="flex flex-wrap justify-center">
          <Link to="https://riofinadmin.whytap.tech/">
            <div className="bg-white hover:bg-black hover:bg-opacity-30 duration-300 rounded-xl md:max-w-sm  relative m-5 z-[10]">
              {/* Image at the top */}
              <img
                src={Rectangle3}
                alt="Image"
                className="w-full mb-4 bg-black opacity-50  rounded-xl "
              />
              {/* Centered button with green-500 background */}{" "}
              <div className="flex absolute homefunded:top-56 top-0 justify-center">
                <button className="bg-mygreen text-black font-bold py-2 px-4">
                  Fully managed
                </button>
              </div>
              {/* Heading and content at the bottom */}
              <div className="bg-white w-full h-48 -mt-4 py-3">
                <div className="mt-4 pl-8">
                  <h2 className="text-xl font-bold text-black">
                    Rio Global V LLP
                  </h2>
                  <p className="text-gray-900 mt-1">Near To Green Airport</p>
                </div>
                <hr className="h-0.5 my-5 md:mr-14 bg-black" />
                {/* Another button at the bottom */}
                <div className="mb-4 py-2 shadow-md md:mr-44 ml-8 mr-40 flex justify-center">
                  <img src={unique} className="w-6 h-6" />
                  <button className="bg-white text-black px-2 rounded-full ">
                    4.5 Crores
                  </button>
                </div>
              </div>
            </div>
          </Link>
          <Link to="https://riofinadmin.whytap.tech/">
            <div className="bg-white hover:bg-black hover:bg-opacity-30 duration-300 rounded-xl md:max-w-sm relative m-5 z-[10]">
              {/* Image at the top */}
              <img
                src={Rectangle8}
                alt="Image"
                className="w-full mb-4 bg-black opacity-50 rounded-xl"
              />
              {/* Centered button with green-500 background */}{" "}
              <div className="flex absolute homefunded:top-56 top-0 justify-center">
                <button className="bg-mygreen text-black font-bold py-2 px-4">
                  Fully managed
                </button>
              </div>
              {/* Heading and content at the bottom */}
              <div className="bg-white w-full h-48 -mt-4 py-3">
                <div className="mt-4 pl-8">
                  <h2 className="text-xl font-bold text-black">
                    Rio Global VI LLP
                  </h2>
                  <p className="text-gray-900 mt-1">
                    Prime Location To ITC Warehouse
                  </p>
                </div>
                <hr className="h-0.5 my-5 md:mr-14 bg-black" />
                {/* Another button at the bottom */}
                <div className="mb-4 py-2  md:mr-44 ml-8 mr-40 flex shadow-md justify-center">
                  <img src={unique} className="w-6 h-6" />
                  <button className="bg-white text-black px-2 rounded-full ">
                    2 Crores
                  </button>
                </div>
              </div>
            </div>
          </Link>
          <Link to="https://riofinadmin.whytap.tech/">
            <div className="bg-white hover:bg-black hover:bg-opacity-30 duration-300 rounded-xl md:max-w-sm x relative cover m-5 z-[10]">
              {/* Image at the top */}
              <img
                src={Rectangle4}
                alt="Image"
                className="w-full mb-4 bg-black opacity-50 rounded-xl"
              />
              {/* Centered button with green-500 background */}{" "}
              <div className="flex absolute homefunded:top-56 top-0 justify-center">
                <button className="bg-mygreen text-black font-bold py-2 px-4">
                  Fully managed
                </button>
              </div>
              {/* Heading and content at the bottom */}
              <div className="-mt-4 py-3 bg-white h-48">
                <div className="mt-4 pl-8">
                  <h2 className="text-xl font-bold text-black">
                    Multi Assets l
                  </h2>
                  <p className="text-gray-900 mt-1">Near To New Metro</p>
                </div>
                <hr className="h-0.5 my-5 md:mr-14 bg-black" />
                {/* Another button at the bottom */}
                <div className="mb-4 py-2 shadow-md  md:mr-44 ml-8 mr-40 w-sm flex justify-center">
                  <img src={unique} className="w-6 h-6" />
                  <button className="bg-white text-black px-2 rounded-full">
                    3 Crores
                  </button>
                </div>
              </div>
            </div>
          </Link>
        </div>

        {/* desktop view section */}
        <div className="  lg:relative lg:h-[200px] lg:mt-[-180px] flex flex-col md:flex-row md:pt-2  bg-footerblue text-white items-center top-0  left-0 right-0 overflow-hidden ">
          {/* <div className='lg:ml-36  flex lg:mt-48 hidden md:block' data-aos="fade-right"> 
             <img src={User} alt="User" className="mb-3 w-28" />
  <div className="ml-3 mt-8 ">
    <h4 className="text-md  mb-0">Kalpesh Chauhan</h4>
    <h6 className="text-sm  mb-9">Riofin Assets Customer</h6>
  </div>
  </div>
 
  <div className="ml-auto md:max-w-[50%] lg:max-w-[50%] xl:max-w-[50%] hidden md:block" data-aos="fade-left">
  <p className='text-justify pl-2 pr-2 lg:mt-48 lg:pr-36 md:ml-4 flex items-center'>
    <img src={left} className='mr-2 md:mb-24 mb-28 '/>
    I have invested more than 35 lakhs with Riofin Assets in all their
    assets. They provide on-time returns. I have been with them for over a
    year now.
    <img src={right} className='ml-2 md:mt-24 mt-28 '/>
  </p>
</div> */}
        </div>
      </div>
      <div className="lg:pl-[10px] lg:mr-9">
        <hr className="text-white" />
      </div>
    </>
  );
};

export default Funded1;
