import React from "react";
import Image1 from "../../assests/a-file1.png";
import {
  FaAccessibleIcon,
  FaAdjust,
  FaBuilding,
  FaHome,
  FaLandmark,
} from "react-icons/fa";

const MoreProfit = () => {
  return (
    <div className="bg-[#E9FFE9] py-10 my-14 w-full">
      <div className="">
        <h1 className="text-black font-bold text-center text-xl md:text-4xl relative">
          Maximizing Returns with Minimal Capital Outlay
          <div className="border-b-4 border-[#04ef00] md:absolute md:right-1/3 absolute right-1/2  bottom-0 top-10 md:top-12 w-full md:w-1/1 font-sans   "></div>
        </h1>
      </div>
      <div className="object-cover w-full my-10 md:my-10 md:px-5 px-2 ">
        <img
          src={Image1}
          alt="Your Alt Text"
          className="items-center w-full  "
        />
      </div>

      <div className="lg:ml-2 px-2 lg:-mt-32 sm:px-10 lg:mr-2 rounded-[18px] grid grid-cols-0 sm:grid-cols-2 lg:grid-cols-4 md:gap-5 gap-5  lg:gap-3 ">
        {[
          {
            icon: FaHome,
            title: " Warehouse Investment Platform",
            description:
              " Discover lucrative warehouse investment opportunities with Riofin, diversifying your portfolio effortlessly and benefiting from the growth of the industrial sector.",
          },
          {
            icon: FaBuilding,
            title: "Industrial Growth Partnerships",
            description:
              "Join Riofin's industrial ventures to fuel growth in the sector while enjoying assured returns without the burden of managing individual projects.",
          },
          {
            icon: FaLandmark,
            title: "Bank Auction Properties",
            description:
              "Explore valuable warehouse assets at advantageous prices through Riofin's bank auction projects. We handle the complexities, allowing you to reap the rewards of strategic investments.",
          },
          {
            icon: FaAdjust,
            title: "Warehouse Asset Management",
            description:
              "Optimize the performance of your warehouse assets with Riofin's expert management services. From tenant placement to proactive maintenance, we ensure maximum returns on your investment.",
          },
        ].map((card, index) => (
          <div
            className="bg-white p-2 rounded-[18px] pb-8 flex flex-col lg:flex-grow drop-shadow-xl"
            key={index}
          >
            <div className="h-10 w-10 ">
              {React.createElement(card.icon, {
                size: 48,
                className: "md:mr-40 mx-4 text-blue",
              })}
            </div>
            <div className="px-3 lg:px-4 py-2 lg:py-2 ">
              <div className="text-black text-xl font-semibold my-2">
                {card.title}
              </div>
              <div className="text-black text-justify">{card.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MoreProfit;
