import React from 'react';

const Disclaimer = () => {
  return (
    <div className=" max-w-[1430px] mx-auto">
      <div className="text-[26px] md:text-4xl text-center font-sans mb-8 font-bold mt-14">Important Disclaimer &amp; Terms of Use</div>
      <div className="bg-mygreen md:mr-[700px] ml-0  p-0.5 mb-8"></div>
      <div className="p-4 lg:pl-24 lg:pr-24 lg:text-justify  mb-10 text-justify">
      Rio Global Fintech Private Limited (CIN: U74140PN2022PTC211767) operates the website www.riofinassets.com. By accessing the site and its pages, you agree to be bound by its terms of service and privacy policy. Rio Global Private Limited does not endorse or promote any opportunities listed on the website or offer recommendations to investors. Any information provided on the website or linked sites does not constitute a solicitation to buy or sell any machinery or property, nor does it offer investment advice, services, or assistance for any particular investment or transaction. Investing in real property and machinery, directly or indirectly, carries significant risks, and investments may lose value without insurance or guarantee from any governmental agency or Rio Global Fintech Private Limited.
      </div>
    </div>
  );
};

export default Disclaimer;
