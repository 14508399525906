import React from "react";
import Tree from "../../assests/a-Tree.png";
import House from "../../assests/Smart Home Shield.png";
import Top from "../../assests/Top.png";
import right from "../../assests/right (2).png";

const Study = () => {
  return (
    <>
      <div className="lg:flex flex-row md:mr-4 md:ml-4 items-center justify-center">
        {/* Image Section */}
        <div className="lg:w-1/2 hidden md:w-auto  lg:block  mt-4 md:mt-0 md:mx-4">
          <div className="lg:ml-0 lg:mr-0">
            {/* <div className='  rounded-md mt-7 mx-4 pr-2 pl-1 md:mx-48 py-5'> */}
            {/* <h3 className='text-center'>Expected ROI</h3>
      <h1 className='text-black text-center font-bold text-xl'>12% - 36%</h1> */}
            <img src={Top} alt="" className="mt-5 lg:ml-56" />
            {/* </div> */}
          </div>
          <img
            src={Tree}
            className="md:w-96 md:mr-10 lg:w-400 h-auto mx-auto mt-4"
            alt="Tree"
          />
        </div>
        {/* 
  <div className='bg-asgreen hidden lg:block rounded-md py-5  md:ml-0  mr-10 md:mt-4 lg:mr-0 lg:ml-0'>
    <h3 className='text-center'>Tenure</h3>
    <h1 className='text-black text-center font-bold text-xl'>4% - 48 months</h1>
  </div> */}
        <img src={right} alt="" className="mt- pr-10 hidden lg:block" />
        {/* mobile and tab */}
        {/* <div className="lg:hidden lg:flex-col md:w-auto mt-4 md:mt-0 md:mx-4"> */}
        <img
          src={Tree}
          className="w-full lg:hidden h-auto md:w-auto mx-auto mt-4"
          alt="Tree"
        />

        <div className="flex hidden justify-between items-center">
          <img src={Top} alt="" className="mt-5 lg:hidden" />
          <img src={right} alt="" className="mt-5 lg:hidden" />
        </div>

        {/* <div className='md:mt-48 ml-10  md:mr-4'>
        <img src={right} alt=""  />
        </div> */}
        {/* Content Section */}
        <div className="w-full md:w-full lg:w-1/2 bg-white p-4 md:p-8 mr-5 mt-6 md:mt-0">
          <p className="mx-2 text-1xl md:mx-10 text-justify ">
            Riofin Assets is your trusted partner for navigating the Commercial
            Real Estate (CRE) market, specializing in connecting buyers and
            sellers of warehouse properties. With a proven track record in
            warehouse asset management, Riofin Assets ensures smooth
            transactions and maximizes returns for all stakeholders.
          </p>
          <div className="md:ml-10 md:gap-4">
            <div className="flex  mt-4">
              <img
                src={House}
                alt="House Icon"
                className="mr-2 w-6 h-full align-top "
              />
              <p className="text-justify ">Warehouse Asset Management</p>
            </div>
            <div className="flex items-center mt-2">
              <img
                src={House}
                alt="House Icon"
                className="mr-2  w-6 h-full  "
              />
              <p className="">Warehouse Buying and Selling</p>
            </div>
            <div className="flex items-center mt-2">
              <img src={House} alt="House Icon" className="mr-2  w-6 h-full" />
              <p className="">Tenancy Management</p>
            </div>
            <div className="flex items-center mt-2">
              <img src={House} alt="House Icon" className="mr-2  w-6 h-full" />
              <p className="">Property Maintenance and Optimization.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full md:pl-28 text-center md:text-left  ml-0 mb-5">
        <div className="flex flex-col">
          <div className="flex-1">
            <h1 className="font-bold  mt-4 md:mt-0">1. "Financial Growth":</h1>
            <p className="md:mt-[-24px]  md:ml-[165px]">
              The bonsai's slow ascent mirrors the steady growth of strategic
              financial decisions and careful planning.
            </p>
          </div>
          <div className="flex-1">
            <h1 className="font-bold  mt-2">2. "Stability Cultivation":</h1>
            <p className="   md:mt-[-22px] md:ml-48">
              Nurturing a bonsai reflects the methodical approach needed to
              cultivate and maintain financial stability in various economic
              landscapes.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Study;
