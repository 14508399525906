import React from "react";
import map from "../../assests/india.png";

const Nation = () => {
  return (
    <>
      <div className="flex lg:flex-row flex-col md:gap-10 my-5 ">
        <div className="space-y-5 lg:w-[60%] w-full flex flex-col lg:items-center lg:justify-center">
          <h1 className="sm:text-4xl text-xl font-bold p-5">
            We Buy Warehouses Nation-Wide
          </h1>
          <hr className="w-3/4 h-0.5 bg-mygreen" />
          <p className="pt-5 md:pl-20 p-5 leading-10 lg:text-start text-gray-500 text-justify">
            If you're looking to sell your warehouse in India and want to ensure
            a hassle-free experience while getting the best price for your
            property, look no further. Our dedicated team specializes in
            purchasing warehouses across India. From initial contact to
            finalizing the deal, we prioritize efficiency and transparency,
            making the selling process seamless for you. Get in touch with us
            today to discuss how we can help you sell your warehouse quickly and
            conveniently.
          </p>
        </div>
        <div className="lg:w-[40%] md:w-3/5 w-full lg:p-0 p-5 mx-auto">
          <img src={map} alt="" className="w-full" />
        </div>
      </div>
    </>
  );
};

export default Nation;
