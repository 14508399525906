import React from "react";
import Countup from "./Countup";
import Banner from "./Banner";
import Testimonals from "./Testimonals";
import Warehouse from "./Warehouse";
import Rfa from "./Rfa";
import Nation from "./Nation";
import BoxSection from "./Boxsection";
import CardSection from "./CardSection";
import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import SanityClient from "../../SanityClient";
import Form from "./Form";

export default function Main() {
  
  const [data,setData] = useState(null);
  useEffect(()=>{
    const getdata = () =>{
      SanityClient.fetch(
        `*[_type=="rentalwarehouse"]{
          metaTitle,
          metaDescriptions,
          MetaData,
          FocusKeyword,
          MetaURL
        }`
      )
      .then((res) => {
        setData(res[0]);
        console.log(res, "rentalWarehouse");
      })
      .catch((err) => {
        console.log(err);
      })
    };
    getdata();
  },[])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Helmet >
        <title property="og:title">{data ? data.metaTitle : ""}</title>
        <meta
          property="og:description"
          content={data ? data.MetaDescription : ""}
        />
        <meta property="og:url" content={data ? data.MetaData : ""} />
        <meta name="keywords" content={data ? data.FocusKeyword : ""}></meta>
        <meta name="alldata" content={data ? data.MetaURL : ""}></meta>
      </Helmet>
      <Banner />
      <Countup />
      <Warehouse />
      <CardSection />
      <Rfa />
      <Nation />
      <Testimonals />
      <BoxSection />
      <Form />
    </div>
  );
}
