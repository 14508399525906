import React, { useEffect, useState } from "react";
import Page from "./page1";
import Section from "./page2";
import { Opportunity } from "./Opportunity";
import SanityClient from "../../SanityClient";
import { Helmet } from "react-helmet";

const Rio = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [data, setData] = useState(null);
  useEffect(() => {
    const getdata = async () => {
      SanityClient.fetch(
        `*[_type=="opportunities"]{
        metaTitle,
        metaDescriptions,
        MetaData,
        FocusKeyword,
        MetaURL
        }`
      )
        .then((res) => {
          setData(res[0]);
          console.log(res, "opportunities");
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getdata();
  }, []);

  return (
    <>
      <Helmet>
        <title property="og:title">{data ? data.metaTitle : ""}</title>
        <meta
          property="og:description"
          content={data ? data.MetaDescription : ""}
        />
        <meta property="og:url" content={data ? data.MetaData : ""} />
        <meta name="keywords" content={data ? data.FocusKeyword : ""}></meta>
        <meta name="alldata" content={data ? data.MetaURL : ""}></meta>
      </Helmet>
      <Page />
      {/* <Section /> */}
      <Opportunity />
    </>
  );
};

export default Rio;
