import React from 'react';

const MobileBanner = () => {
  return (
    <div className="block md:hidden">
      {/* Content for the section */}
      <div className="bg-darkBlue p-4">
        <hr className="mt-4 text-center bg-mygreen w-full  h-0.5" />
        <h1 className="text-xl md:text-4xl text-center lg:text-5xl font-bold  text-white">
          Asset
        </h1>
        <h1 className="font-bold text-center text-mygreen text-xl md:text-3xl lg:text-4xl mt-2 md:mt-4">
          Backed Leasing
        </h1>
        <p className="mt-2 text-center  md:mt-4 text-sm md:text-xl text-white  lg:text-2xl">
        Unlock financial opportunities by leveraging valuable assets as collateral for leasing arrangements, providing a secure avenue for both lessees and lessors.

        </p>
      </div>
    </div>
  );
};

export default MobileBanner;
