import React from 'react';
import Img1 from '../../assests/file (2).png';
import background from '../../assests/Banner 1.png';

const DesktopBanner = () => {
  const isMobile = window.innerWidth <= 767; // You can adjust the breakpoint as needed

  if (isMobile) {
    return null; // Render nothing on mobile
  }

  return (
    <div className="relative lg:py-8  w-full  ">
      {/* Background Image */}
      <img
        src={background}
        alt="Background"
        className=" hidden md:block md:mr-4 w-[1240px] h-743px md:w-full lg:mt-0 md:h-[300px] lg:h-[600px] absolute inset-0"
      />
      <div className='hidden bg-black'></div>
      <div className="flex mx-4 md:mx-8 lg:mx-20 relative z-10">
        <div className="w-auto hidden md:block md:w-2/3 lg:w-1/2 text-left text-white">
          {/* Content for the section */}
          <hr className="mt-10 lg:mt-24 hidden md:block bg-mygreen w-2/12 p-1" />
          <h1 className="text-xl hidden md:block md:text-4xl lg:text-6xl font-bold mt-5  text-left">ASSET</h1>
          <h1 className="font-bold hidden md:block text-mygreen text-3xl md:text-3xl lg:text-5xl mt-2 md:mt-5">Backed Leasing</h1>
          <p className="mt-3 md:mt-5 hidden md:block  md:text-1xl text-justify lg:text-1xl text-xs sm:text-sm lg:text-lg md:w-full">
          Unlock financial opportunities by leveraging valuable assets as collateral for leasing arrangements, providing a secure avenue for both lessees and lessors.

          </p>
        </div>
        <div className="hidden md:block md:w-[]  md:h-[443px] md:flex md:items-center md:justify-center  lg:ml-32 sm:-mt-20 lg:mt-0 ">
          {/* Overlaying Image */}
          {/* <img
            src={Img1}
            alt="Overlay Image"
            className="object-cover rounded-md mx-auto ml-48 lg:block md:hidden hidden "
          />
           <img
            src={Img1}
            alt="Overlay Image"
            className="object-cover rounded-md mx-auto ml-48 md:w-72 md:h-72 lg:hidden md:block hidden "
          /> */}
        </div>
      </div>
    </div>
  );
};

export default DesktopBanner;