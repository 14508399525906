// import React from 'react';
import image1 from'../../assests/over1.png';
import image2 from '../../assests/aover2.png';
import image3 from '../../assests/over3.png';
import image4 from '../../assests/over4.png';
import image5 from '../../assests/over2.png';


function MobiletabPuzzle() {
  const cardStyle1 = {
   backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  return (
    <div className='lg:hidden w-full'>

    <div className='flex bg-white pt-10  align-middle  ' style={cardStyle1}>
      <div className="grid grid-cols-1 md:grid-cols-2 justify-around mt-0  lg:gap-2 md:gap-6 gap-8 pb-4 md:pt-10 p-4">
        {/* First Image Box */}
        <div className=" pt-4 rounded-[18px]  shadow-lg  lg:m-12 md:m-10  border-r-4 border-b-4 border-t-2 border-l-2 lg:border-r-8 lg:border-b-8 border-blue">
          <div className="flex items-center justify-center">
            <img
              src={image1}
              alt="Your Alt Text"
              className="w-20 h-20 object-cover p-2 rounded-full bg-blue border-r-4 border-l-[1px] border-t-[1px] border-b-4 border-blue border-blue shadow-lg pb-2 lg:pb-4 absolute "
            />
          </div>
          <div className="px-1  py-3 lg:py-4 text-center">
            <h1 className="font-bold  text-xl mt-8 font-sans  lg:mt-16 md:mt-8 text-blue">Due Diligence</h1>
            <p className='text-black text-center text-1xl mt-2 md:mt-5'>
            At Riofin, we meticulously conduct due diligence to3 identify and filter MSME industries with the potential for higher profit generation.

            </p>
          
          </div>
        </div>

        {/* Second Card */}
        <div className=" pt-4 rounded-[18px]  shadow-lg   lg:m-12 md:m-10 border-r-4 border-b-4 border-t-2 border-l-2 lg:border-r-8 lg:border-b-8 border-blue lg:border-mygreen md:border-blue">
          <div className="flex items-center justify-center">
            <img
              src={image2}
              alt="Your Alt Text"
              className="w-20 h-20 object-cover p-2 rounded-full bg-blue md:bg-blue lg:bg-mygreen border-r-4 border-l-[1px] border-t-[1px] border-b-4 lg:border-mygreen md:border-blue border-blue shadow-lg pb-2 lg:pb-4 absolute flex"
            />
          </div>
          <div className="px-3  py-6 lg:py-4 text-center">
            <h1 className="font-bold text-xl mt-8 font-sans  md:mt-8 md:mt-8 text-blue md:text-blue lg:text-blue">Business Track Records</h1>
            <p className='text-black text-center text-1xl mt-2 md:mt-5'>
            Lessee's Management Background Check: Assessing the Top-Level Management's Track Record and Evaluating the Credibility of Promoters and Management.

              </p>
            </div>
        </div>
        
        {/* Third Card */}
        <div className=" pt-4 rounded-[18px]  shadow-lg   lg:m-12 md:m-10  border-r-4 border-b-4 border-t-2 border-l-2 lg:border-r-8 lg:border-b-8 border-blue">
          <div className="flex items-center justify-center">
            <img
              src={image3}
              alt="Your Alt Text"
              className="w-20 h-20 object-cover p-2 rounded-full bg-blue border-r-4 border-l-[1px] border-t-[1px] border-b-4  border-blue shadow-lg pb-2 lg:pb-4 absolute flex"
            />
          </div>
          <div className="px-3 lg:px-6 py-6 lg:py-4 text-center">
            <h1 className="font-bold text-xl mt-8 font-sans md:mt-8 md:mt-8 text-blue">Order Book size</h1>
            <p className='text-black text-center  text-1xl mt-2 md:mt-5'>
            Demonstrating robust demand for products and services, providing clear visibility into future earnings. The business has maintained a consistent track record for a minimum of 2-5 years.
            </p>
           </div>
        </div>
        {/* {fourth card} */}
        <div className=" pt-4 rounded-[18px]  shadow-lg   lg:m-12 md:m-10 border-r-4 border-b-4 border-t-2 border-l-2 lg:border-r-8 lg:border-b-8 border-blue lg:border-mygreen md:border-blue">
          <div className="flex items-center justify-center">
            <img
              src={image4}
              alt="Your Alt Text"
              className="w-20 h-20 object-cover p-2 rounded-full bg-blue md:bg-blue lg:bg-mygreen border-r-4 border-l-[1px] border-t-[1px] border-b-4  lg:border-mygreen md:border-blue border-blue shadow-lg pb-2 lg:pb-4 absolute flex"
            />
          </div>
          <div className="px-3 lg:px-6 py-6 lg:py-4 text-center">
            <h1 className="font-bold text-xl mt-8 font-sans lg:mt-16 md:mt-8 text-blue md:text-blue lg:text-green">Experienced Organisation</h1>
            <p className='text-black text-center text-1xl mt-2 md:mt-5'>
            Diverse expertise in finance, marketing, and operations. A proven organizational track record in consistently delivering high-quality products.
  
              </p>
            </div>
        </div>
        {/* fifth card */}
        <div className=" pt-4 rounded-[18px]  shadow-lg   lg:m-12 md:m-10  border-r-4 border-b-4 border-t-2 border-l-2 lg:border-r-8 lg:border-b-8 border-blue">
          <div className="flex items-center justify-center">
            <img
              src={image5}
              alt="Your Alt Text"
              className="w-20 h-20 object-cover p-2 rounded-full bg-blue border-r-4 border-l-[1px] border-t-[1px] border-b-4 border-blue border-blue shadow-lg pb-2 lg:pb-4 absolute flex"
            />
          </div>
          <div className="px-3 lg:px-6 py-6 lg:py-4 text-center">
            <h1 className="font-bold text-xl mt-8 font-sans lg:mt-16 md:mt-8 text-blue">Lease Profile</h1>
            <p className='text-black text-center text-1xl mt-2 md:mt-5'>
            Lessee's Top-Level Management Background: Assessing the Track Record and Credibility of Promoters and Management.

            </p>
           </div>
        </div>

        </div>
      </div>
    </div>
  );
}

export default MobiletabPuzzle;