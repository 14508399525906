import React, { useEffect, useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { faComputer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-vertical-timeline-component/style.min.css";

const TimeLine = () => {
  return (
    <div className="bg-black py-10">
      <div className="text-center py-10 px-2 space-y-10">
        <h2 className="font-bold md:text-4xl text-[20px] text-white">
          The story of Riofin Assets got started{" "}
        </h2>
        <p className="text-[#BBBBBB]   md:text-2xl text-[16px]">
          Riofin was started by 3 visionaries with the same mission.
          <br />
          Our story starts 1.5 years ago
        </p>
      </div>
      <VerticalTimeline className="bg-black">
        <VerticalTimelineElement
          className="vertical-timeline-element--work "
          date={"February 2023"}
          dateClassName="date"
          icon={<FontAwesomeIcon icon={faComputer} />}
          iconStyle={{ background: "#00FF00" }}
          contentStyle={{
            background: "#0000",
            color: "white",
            border: "black",
            boxShadow: "none",
          }}
        >
          <fieldset className="border-2 border-white h-52 relative">
            <section className="bg-black sm:w-2/3 w-full hometimeline1:text-right absolute hometimeline1:right-0 hometimeline1:-mr-[7%] hometimeline1:-mt-[7%] -mt-[4%] -ml-[1%] hometimeline1:-ml-[0] p-3">
              <div className="vertical-timeline-element-title font-semibold bg-mygreen text-black rounded-lg p-2">
                <h3 className="text-lg">Trust Milestone</h3>
              </div>
              <div className="">
                <p className="description">
                  {" "}
                  Riofin surpasses INR 10 crores in total sales of warehouses on
                  its platform.
                </p>
              </div>
            </section>
          </fieldset>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work "
          date={"January 2024"}
          dateClassName="date"
          icon={<FontAwesomeIcon icon={faComputer} />}
          iconStyle={{ background: "#00FF00" }}
          contentStyle={{
            background: "#0000",
            color: "white",
            border: "black",
            boxShadow: "none",
          }}
        >
          <fieldset className="border-2 border-white h-52 relative ">
            <section className="bg-black sm:w-2/3 w-full hometimeline1:text-left absolute hometimeline1:left-0 hometimeline1:-mt-[7%] -mt-[4%] -ml-[1%] hometimeline1:-ml-[7%] p-3">
              <div className="vertical-timeline-element-title font-semibold  bg-mygreen text-black rounded-lg p-2">
                <h3 className="text-lg">An Army is Formed</h3>
              </div>

              <div className="">
                <p className="description ">
                  {" "}
                  A community of over 2500 like-minded users joins Riofin
                  nationwide.
                </p>
              </div>
            </section>
          </fieldset>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work "
          date={"March 2024"}
          dateClassName="date"
          icon={<FontAwesomeIcon icon={faComputer} />}
          iconStyle={{ background: "#00FF00" }}
          contentStyle={{
            background: "#0000",
            color: "white",
            border: "black",
            boxShadow: "none",
          }}
        >
          <fieldset className="border-2 border-white h-52 relative">
            <section className="bg-black sm:w-2/3 w-full hometimeline1:text-right absolute hometimeline1:right-0 hometimeline1:-mr-[7%] hometimeline1:-mt-[7%] -mt-[4%] -ml-[1%] hometimeline1:-ml-[0] p-3">
              <div className="vertical-timeline-element-title font-semibold bg-mygreen text-black rounded-lg p-2">
                <h3 className="text-lg text-left">Be Revolutionary</h3>
              </div>

              <div className="">
                <p className="description ">
                  Riofin sparks a revolution in the warehouse industry. Stay
                  tuned...
                </p>
              </div>
            </section>
          </fieldset>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};

export default TimeLine;
