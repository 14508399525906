import React, { useEffect, useState } from "react";
import Contactus from "./Contactus";
import MapSection from "./MapSection";
import Investor from "./Investor";
import Disclaimer from "./Disclaimer";
import SanityClient from "../../SanityClient";
import { Helmet } from "react-helmet";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [data, setData] = useState(null);
  useEffect(() => {
    const getdata = () => {
      SanityClient.fetch(
        `*[_type=="contact"]{
          metaTitle,
          metaDescriptions,
          MetaDate,
          FocusKeywords,
          MetaURL
        }`
      )
        .then((res) => {
          setData(res[0]);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getdata();
  });
  return (
    <>
      <Helmet>
        <title property="og:title">{data ? data.metaTitle : ""}</title>
        <meta
          property="og:description"
          content={data ? data.MetaDescription : ""}
        />
        <meta property="og:url" content={data ? data.MetaData : ""} />
        <meta name="keywords" content={data ? data.FocusKeyword : ""}></meta>
        <meta name="alldata" content={data ? data.MetaURL : ""}></meta>
      </Helmet>
      <Contactus />
      <MapSection />
      <Investor />
      <Disclaimer />
    </>
  );
};

export default Contact;
