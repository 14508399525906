// Carousel.js
import Slider from "react-slick";
import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../../assests/projects.png";
import img2 from "../../assests/projects.png";
import img3 from "../../assests/projects.png";
import { FaStar } from "react-icons/fa";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const CurrentProjects2 = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Set slidesToShow to the number of slides you want to show at once
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1164,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  const [activeTab, setActiveTab] = useState("DESCRIPTION");

  const renderContent = () => {
    switch (activeTab) {
      case "DESCRIPTION":
        return (
          <div className="text-white text-[12px] mb-4 text-justify">
            <p>
              <b>Description</b>
            </p>
            <p>
              Commercial warehouse located in the prime location of
              Sriperumbudur. Waiting to be fully funded.
            </p>
          </div>
        );
      case "Tenant":
        return (
          <div>
            <div className="text-white text-[12px] mb-4  text-justify">
              <p>
                <b>Tenant</b>
              </p>
              <p>
                Commercial warehouse located in the prime location of
                Sriperumbudur. Waiting to be fully funded.
              </p>
            </div>
          </div>
        );
      case "MAP":
        return (
          <div>
            <div className="text-white text-[12px] mb-4  text-justify">
              <p>
                <b>Map</b>
              </p>
              <p>
                Commercial warehouse located in the prime location of
                Sriperumbudur. Waiting to be fully funded.
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  const [activTab, setActivTab] = useState("DESCRIPTION");

  const renderConten = () => {
    switch (activTab) {
      case "DESCRIPTION":
        return (
          <div className="text-white text-[12px] mb-4 text-justify">
            <p>
              <b>Descrip</b>
            </p>
            <p>
              Commercial warehouse located in the prime location of
              Sriperumbudur. Waiting to be fully funded.
            </p>
          </div>
        );
      case "Tenant":
        return (
          <div>
            <div className="text-white text-[12px] mb-4  text-justify">
              <p>
                <b>Tenant</b>
              </p>
              <p>
                Commercial warehouse located in the prime location of
                Sriperumbudur. Waiting to be fully funded.
              </p>
            </div>
          </div>
        );
      case "MAP":
        return (
          <div>
            <div className="text-white text-[12px] mb-4  text-justify">
              <p>
                <b>Ma</b>
              </p>
              <p>
                Commercial warehouse located in the prime location of
                Sriperumbudur. Waiting to be fully funded.
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  const [actiTab, setActiTab] = useState("DESCRIPTION");

  const renderConte = () => {
    switch (actiTab) {
      case "DESCRIPTION":
        return (
          <div className="text-white text-[12px] mb-4 text-justify">
            <p>
              <b>Description</b>
            </p>
            <p>
              Commercial warehouse located in the prime location of
              Sriperumbudur. Waiting to be fully funded.
            </p>
          </div>
        );
      case "Tenant":
        return (
          <div>
            <div className="text-white text-[12px] mb-4  text-justify">
              <p>
                <b>Tenant</b>
              </p>
              <p>
                Commercial warehouse located in the prime location of
                Sriperumbudur. Waiting to be fully funded.
              </p>
            </div>
          </div>
        );
      case "MAP":
        return (
          <div>
            <div className="text-white text-[12px] mb-4  text-justify">
              <p>
                <b>Map</b>
              </p>
              <p>
                Commercial warehouse located in the prime location of
                Sriperumbudur. Waiting to be fully funded.
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);

  return (
    <section className="lg:hidden">
      <div className=" md:py-8 pt-8">
        <h1 className="text-black font-bold text-center text-[26px] relative ">
          Our Current Projects
          <div
            className="border-b-4  border-mygreen pb-4 mb-6 md:absolute right-52  bottom-0 top-24 w-1/1 w-full font-sans"
            data-aos="zoom-in-right"
          ></div>
        </h1>
      </div>
      <div className=" lg:hidden lg:flex sm:flex items-center justify-center mt-10 mb-10">
        {/* Content for mobile and tablet */}

        <div className="w-full pl-4 pr-4" data-aos="fade-up">
          <Slider {...settings} className="">
            {/* Slide 1 */}
            <div className="flex flex-col items-center justify-center px-2">
              <div className="font-inter">
                <div>
                  <div className="  ">
                    <div className=" bg-black h-full w-full rounded-[18px] ">
                      <div className="flex justify-end md:ml-2 mb-6  ">
                        <button className="bg-mygreen text-xs font-bold p-1 rounded-md mb-1">
                          Capital Protected
                        </button>
                        <button className="bg-mygreen mx-2 text-xs p-1 font-bold rounded-md mb-1">
                          Trending
                        </button>
                      </div>
                      <p className="text-mygreen px-4 text-xs font-bold md:mb-0">
                        Real Estate 1
                      </p>
                      <div className="md:ml-0 px-4">
                        <h1 className="text-white text-lg md:text-1xl ">
                          CRE-II
                        </h1>
                        <p className="text-gray-200 text-sm mb-4">Mappedu</p>
                        <div className="flex items-end justify-between gap-1">
                          <div className="flex flex-col">
                            <div className="text-white text-sm">67%</div>
                            <div
                              className="mt-2"
                              style={{
                                width: "400%",
                                height: "5px",
                                backgroundColor: "#ddd",
                                borderRadius: "4px",
                                overflow: "hidden",
                                color: "#2272FF",
                              }}
                            >
                              <div
                                style={{
                                  width: `67%`,
                                  height: "100%",
                                  backgroundColor: "green",
                                  borderRadius: "4px",
                                }}
                              />
                            </div>
                          </div>
                          <div>
                            <p className="text-lg font-bold text-white">
                              ₹ 1.2 Crore
                            </p>
                          </div>
                        </div>

                        <br />

                        {/* Tab section 1 */}
                        <div>
                          <div className="flex absolute -m-4 ml-0   flex-row md:mr-3 mb-2 ">
                            <button
                              className={`text-xs text-white ${
                                activeTab === "DESCRIPTION"
                                  ? "border-b-2  border-lightblue"
                                  : ""
                              }`}
                              onClick={() => setActiveTab("DESCRIPTION")}
                            >
                              DESCRIPTION
                            </button>
                            <button
                              className={`px-5 text-xs text-white ml-1 ${
                                activeTab === "Tenant"
                                  ? "border-b-2 border-lightblue"
                                  : ""
                              }`}
                              onClick={() => setActiveTab("Tenant")}
                            >
                              TENANT
                            </button>
                            {/* <button
                              className={`px-2 text-xs text-white ml-1 ${
                                activeTab === "MAP"
                                  ? "border-b-2 border-lightblue"
                                  : ""
                              }`}
                              onClick={() => setActiveTab("MAP")}
                            >
                              MAP
                            </button> */}
                          </div>
                          <hr className="top-1 lg:mb-3 text-gray" />
                          {renderContent()}
                        </div>

                        <div className="flex font-sans flex-row mb-0 text-white gap-2">
                          <p className="px-1 text-xs">ROI %</p>
                          <p className="pl-6 text-xs">TENURE</p>
                          <p className="pl-2 text-xs">MIN INVESTING</p>
                        </div>
                        <div className="flex gap-4 mt-2 md:mt-0">
                          <button className="bg-gray rounded-md px-2 py-1 text-xs text-gray-200 bg-[#23272a]">
                            18% PA
                          </button>
                          <button className="bg-gray rounded-md px-3 py-1 text-xs text-gray-200  bg-[#23272a] ">
                            4 Yr
                          </button>
                          <button className="bg-gray rounded-md px-3 py-1 text-xs text-gray-200   bg-[#23272a]">
                            1 Lakh
                          </button>
                        </div>
                        <br />
                        <button
                          className="bg-black border-mygreen w-full border text-xs font-bold rounded-md text-white  px-12 py-2 hover:bg-mygreen hover:text-black duration-300"
                          onClick={() =>
                            (window.location.href =
                              "https://riofinadmin.whytap.tech/")
                          }
                        >
                          INVEST NOW
                        </button>
                        <br />
                        <br />
                        <Link to="/form">
                          {" "}
                          <button className="bg-mygreen rounded-md w-full text-white text-xs font-bold px-16 py-2 whitespace-nowrap mb-11 hover:scale-105 transition-transform duration-300 ease-in-out transform">
                            TALK TO US
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Slide 2 */}
            <div className="flex flex-col items-center justify-center px-2">
              <div className="font-inter">
                <div>
                  <div className="  ">
                    <div className=" bg-black h-full w-full rounded-[18px] ">
                      <div className="flex justify-end md:ml-2 mb-6  ">
                        <button className="bg-mygreen text-xs font-bold p-1 rounded-md mb-1">
                          Capital Protected
                        </button>
                        <button className="bg-mygreen mx-2 text-xs p-1 font-bold rounded-md mb-1">
                          Trending
                        </button>
                      </div>
                      <p className="text-mygreen px-4 text-xs font-bold md:mb-0">
                        Real Estate 2
                      </p>
                      <div className="md:ml-0 px-4">
                        <h1 className="text-white text-lg md:text-1xl ">
                          CRE-II
                        </h1>
                        <p className="text-gray-200 text-sm mb-4">Mappedu</p>
                        <div className="flex items-end justify-between gap-1">
                          <div className="flex flex-col">
                            <div className="text-white text-sm">67%</div>
                            <div
                              className="mt-2"
                              style={{
                                width: "400%",
                                height: "5px",
                                backgroundColor: "#ddd",
                                borderRadius: "4px",
                                overflow: "hidden",
                                color: "#2272FF",
                              }}
                            >
                              <div
                                style={{
                                  width: `67%`,
                                  height: "100%",
                                  backgroundColor: "green",
                                  borderRadius: "4px",
                                }}
                              />
                            </div>
                          </div>
                          <div>
                            <p className="text-lg font-bold text-white">
                              ₹ 1.2 Crore
                            </p>
                          </div>
                        </div>
                        {/* <div className="flex items-center">
                          <p className="text-mygreen text-xs font-bold ">
                            Raised
                          </p>
                        </div> */}
                        <br />

                        {/* Tab section 2*/}
                        <div>
                          <div className="flex absolute -m-4 ml-0   flex-row md:mr-3 mb-2 ">
                            <button
                              className={`text-xs text-white ${
                                activTab === "DESCRIPTION"
                                  ? "border-b-2  border-lightblue"
                                  : ""
                              }`}
                              onClick={() => setActivTab("DESCRIPTION")}
                            >
                              DESCRIPTION
                            </button>
                            <button
                              className={`px-5 text-xs text-white ml-1 ${
                                activTab === "Tenant"
                                  ? "border-b-2 border-lightblue"
                                  : ""
                              }`}
                              onClick={() => setActivTab("Tenant")}
                            >
                              TENANT
                            </button>
                            {/* <button
                              className={`px-2 text-xs text-white ml-1 ${
                                activTab === "MAP"
                                  ? "border-b-2 border-lightblue"
                                  : ""
                              }`}
                              onClick={() => setActivTab("MAP")}
                            >
                              MAP
                            </button> */}
                          </div>
                          <hr className="top-1 lg:mb-3 text-gray" />
                          {renderConten()}
                        </div>

                        <div className="flex font-sans flex-row mb-0 text-white gap-2">
                          <p className="px-1 text-xs">ROI %</p>
                          <p className="pl-6 text-xs">TENURE</p>
                          <p className="pl-2 text-xs">MIN INVESTING</p>
                        </div>
                        <div className="flex gap-4 mt-2 md:mt-0">
                          <button className="bg-gray rounded-md px-2 py-1 text-xs text-gray-200 bg-[#23272a]">
                            18% PA
                          </button>
                          <button className="bg-gray rounded-md px-3 py-1 text-xs text-gray-200  bg-[#23272a] ">
                            4 Yr
                          </button>
                          <button className="bg-gray rounded-md px-3 py-1 text-xs text-gray-200   bg-[#23272a]">
                            1 Lakh
                          </button>
                        </div>
                        <br />
                        <button
                          className="bg-black border-mygreen w-full border text-xs font-bold rounded-md text-white  px-12 py-2 hover:bg-mygreen hover:text-black duration-300"
                          onClick={() =>
                            (window.location.href =
                              "https://riofinadmin.whytap.tech/")
                          }
                        >
                          INVEST NOW
                        </button>
                        <br />
                        <Link to="/form">
                          {" "}
                          <button className="bg-mygreen rounded-md w-full text-white text-xs font-bold px-16 py-2 whitespace-nowrap mb-11 hover:scale-105 transition-transform duration-300 ease-in-out transform mt-6">
                            TALK TO US
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Slide 3 */}
            <div className="flex flex-col items-center justify-center px-2">
              <div className="font-inter">
                <div>
                  <div className="  ">
                    <div className=" bg-black h-full w-full rounded-[18px] ">
                      <div className="flex justify-end md:ml-2 mb-6  ">
                        <button className="bg-mygreen text-xs font-bold p-1 rounded-md mb-1">
                          Capital Protected
                        </button>
                        <button className="bg-mygreen mx-2 text-xs p-1 font-bold rounded-md mb-1">
                          Trending
                        </button>
                      </div>
                      <p className="text-mygreen px-4 text-xs font-bold md:mb-0">
                        Real Estate 3
                      </p>
                      <div className="md:ml-0 px-4">
                        <h1 className="text-white text-lg md:text-1xl ">
                          CRE-II
                        </h1>
                        <p className="text-gray-200 text-sm mb-4">Mappedu</p>
                        <div className="flex items-end justify-between gap-1">
                          <div className="flex flex-col">
                            <div className="text-white text-sm">67%</div>
                            <div
                              className="mt-2"
                              style={{
                                width: "400%",
                                height: "5px",
                                backgroundColor: "#ddd",
                                borderRadius: "4px",
                                overflow: "hidden",
                                color: "#2272FF",
                              }}
                            >
                              <div
                                style={{
                                  width: `67%`,
                                  height: "100%",
                                  backgroundColor: "green",
                                  borderRadius: "4px",
                                }}
                              />
                            </div>
                          </div>
                          <div>
                            <p className="text-lg font-bold text-white">
                              ₹ 1.2 Crore
                            </p>
                          </div>
                        </div>

                        <br />

                        {/* Tab section 3*/}
                        <div>
                          <div className="flex absolute -m-4 ml-0 flex-row md:mr-3 mb-6  ">
                            <button
                              className={`text-xs text-white ${
                                actiTab === "DESCRIPTION"
                                  ? "border-b-2  border-lightblue"
                                  : ""
                              }`}
                              onClick={() => setActiTab("DESCRIPTION")}
                            >
                              DESCRIPTION
                            </button>
                            <button
                              className={`px-5 text-xs text-white ml-1 ${
                                actiTab === "Tenant"
                                  ? "border-b-2 border-lightblue"
                                  : ""
                              }`}
                              onClick={() => setActiTab("Tenant")}
                            >
                              TENANT
                            </button>
                            {/* <button
                              className={`px-2 text-xs text-white ml-1 ${
                                actiTab === "MAP"
                                  ? "border-b-2 border-lightblue"
                                  : ""
                              }`}
                              onClick={() => setActiTab("MAP")}
                            >
                              MAP
                            </button> */}
                          </div>
                          <hr className="top-1 lg:mb-3 text-gray" />
                          {renderConte()}
                        </div>

                        <div className="flex font-sans flex-row mb-0 text-white gap-2">
                          <p className="px-1 text-xs">ROI %</p>
                          <p className="pl-6 text-xs">TENURE</p>
                          <p className="pl-2 text-xs">MIN INVESTING</p>
                        </div>
                        <div className="flex gap-4 mt-2 md:mt-0">
                          <button className="bg-gray-700 rounded-md px-2 py-1 text-xs text-gray-200 ">
                            18% PA
                          </button>
                          <button className="bg-gray-700 rounded-md px-3 py-1 text-xs text-gray-200 ">
                            4 Yr
                          </button>
                          <button className="bg-gray-700 rounded-md px-3 py-1 text-xs text-gray-200 ">
                            1 Lakh
                          </button>
                        </div>
                        <br />
                        <button
                          className="bg-black border-mygreen w-full border text-xs font-bold rounded-md text-white  px-12 py-2 hover:bg-mygreen hover:text-black duration-300"
                          onClick={() =>
                            (window.location.href =
                              "https://riofinadmin.whytap.tech/")
                          }
                        >
                          INVEST NOW
                        </button>
                        <br />
                        <br />
                        <Link to="/form">
                          {" "}
                          <button className="bg-mygreen rounded-md w-full text-white text-xs font-bold px-16 py-2 whitespace-nowrap mb-11 hover:scale-105 transition-transform duration-300 ease-in-out transform">
                            TALK TO US
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Add more slides with content as needed */}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default CurrentProjects2;
