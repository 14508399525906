import React, { useState } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import Img1 from '../../assests/su-image4.png';
import Img2 from '../../assests/su-image5.png';
import Img3 from '../../assests/su-image7.png';
import Link from 'antd/es/typography/Link';
import { Link as RouterLink } from "react-router-dom";

const Card = () => {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);


  const openModal = (cardIndex) => {
    form.resetFields(); 
    setSelectedCard(cardIndex);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleSubmit = (values) => {
    console.log('Form submitted:', values);
    closeModal();
  };


  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 w-full lg:grid-cols-3 gap-8 my-4 px-4 lg:my-4 lg:px-14 md:my-4 md:px-4">
        {/* Card 1 */}
        <div className="bg-black rounded-lg flex flex-col items-center w-full px-3 py-4">
          <img src={Img1} alt="Your Alt Text" className="object-cover w-18 h-18 rounded-md mb-4" />
          <h1 className="text-center text-xl mb-3 mt-5 text-white font-bold">E-mail</h1>
          <p className="text-white text-center">
            We will solve all the problems in the application together with you, thank you for your support
          </p>
          <button
            onClick={() => openModal(1)}
            className="justify-center mt-4 px-10 py-2 text-sm text-black bg-mygreen rounded-2xl font-bold focus:outline-none"
          >
            Help the team
          </button>
        </div>

        {/* Card 2 */}
        <div className="bg-black rounded-lg flex flex-col items-center w-full px-3 py-4">
          <img src={Img2} alt="Your Alt Text" className="object-cover w-40 h-18 rounded-md mb-4" />
          <h1 className="text-center text-xl mb-3 mt-5 text-white font-bold">FAQ</h1>
          <p className="text-white text-center">
            A small guide in the application that gives short answers to frequently asked questions by users
          </p>
      <RouterLink to='/faq'> 
       <button
            
            className="justify-center mt-4 px-10 py-2 text-sm text-black bg-mygreen rounded-2xl font-bold focus:outline-none"
          >
            View
          </button></RouterLink>
        </div>

        {/* Card 3 */}
        <div className="bg-black rounded-lg flex flex-col items-center w-full px-3 py-4">
          <img src={Img3} alt="Your Alt Text" className="object-cover w-14 h-18 rounded-md mb-4" />
          <h1 className="text-center text-xl mb-4 mt-4 text-white font-bold">Call</h1>
          <p className="text-white text-center mb-6">The team is available 24 hours a day</p>
        <RouterLink to='/contactus'>  <button
            
            className="justify-center mt-4 px-7 py-2 text-sm text-black bg-mygreen rounded-2xl font-bold focus:outline-none"
          >
            Contact
          </button></RouterLink>
        </div>

        {/* Modal */}
        
        <Modal
          visible={modalVisible}
          onCancel={closeModal}
          footer={null}
          centered
          destroyOnClose
        >
          
          <h1 className='text-black text-center font-bold'> Help the Team</h1>
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 18 }} 
            style={{  padding: '10px',  borderRadius: '10px' }}  
          >
            <div className='pl-0 pr-0 md:pl-0 md:pr-0'>
            <Form.Item
              
              name="name"
              rules={[{ required: true, message: 'Please enter your name' }]}
            >
             <Input
      placeholder="Enter Your Name"
      className="placeholder-[#a6aabc] bg-[#f8f8f8] hover:bg-[#f8f8f8] text-black border border-none h-10 "
    />
            </Form.Item>

            <Form.Item
             
              name="email"
              rules={[
                { required: true, message: 'Please enter your email' },
                { type: 'email', message: 'Please enter a valid email' },
              ]}
            >
              <Input placeholder="Enter Your Email"  className="placeholder-[#a6aabc] bg-[#f8f8f8] hover:bg-[#f8f8f8] text-black border border-none h-10 "  />
            </Form.Item>

            <Form.Item
           
              name="mobile"
              rules={[{ required: true, message: 'Please enter your mobile number' }]}
            >
              <Input placeholder="Enter Your Mobile Number"  className="placeholder-[#a6aabc] bg-[#f8f8f8] hover:bg-[#f8f8f8] text-black border border-none h-10 " />
            </Form.Item>
            <Form.Item    
              name="subject"
              rules={[{ required: true, message: 'Please enter the subject' }]}
            >
              <Input placeholder="Enter The Subject"  className="placeholder-[#a6aabc] bg-[#f8f8f8] hover:bg-[#f8f8f8] text-black border border-none h-10 "/>
            </Form.Item>
            <Form.Item
              name="message"
              rules={[{ required: true, message: 'Please enter your message' }]}
            >
              <Input.TextArea placeholder="Enter Your Message" className="placeholder-[#a6aabc] bg-[#f8f8f8] hover:bg-[#f8f8f8] text-black border border-none h-10 " />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'center '}}>
  <Button type="primary" htmlType="submit" style={{ backgroundColor: '#00ff00', color: 'black', fontWeight:'bold'}}>
    Send Message
  </Button>
</Form.Item>
            </div>
          </Form>  
        </Modal>
        </div>

    </>
  );
};

export default Card;

