import React from "react";
import cardicon08 from "../../assests/cardicon08.png";
import cardicon06 from "../../assests/cardicon06.png";
import cardicon05 from "../../assests/cardicon05.png";
import cardicon03 from "../../assests/cardicon03.png";

const Cards2 = () => {
  return (
    <>
      <div
        style={{ backgroundImage: "url('/blackthemebg.png')" }}
        className="flex flex-col py-8 gap-3 bg-cover w-full bg-no-repeat"
      >
        <h1 className="font-extrabold text-3xl text-center text-white">
          Why Riofin
        </h1>

        <p className="h-0.5 w-1/2 bg-mygreen"></p>

        <div className="flex flex-wrap justify-center gap-5 px-3 py-6">
          <div
            className="bg-white flex flex-col rounded-xl w-[250px] h-52 justify-start gap-3
  "
          >
            <div className="flex w-full justify-end py-5">
              <div className="flex flex-row gap-3 rounded-l-full bg-mygreen w-[95%] items-center h-fit">
                {/* <PiBuildingsLight
          size={40}
          className=" text-lightblue bg-white border-mygreen border rounded-full p-1 shadow drop-shadow-2xl"
        /> */}
                <img
                  src={cardicon05}
                  alt=""
                  className="bg-white border-mygreen border-2 rounded-full p-1 shadow drop-shadow-2xl"
                />
                <h1 className="h-fit text-center text-black text-sm font-medium">
                  Cost-Effective Solutions
                </h1>
              </div>
            </div>

            <p className="text-[#757575] text-sm text-justify px-5">
              Benefit from our services, maximizing your profitability
              with minimal cost.
            </p>
          </div>

          <div className="bg-white flex flex-col rounded-xl w-[250px] h-52 justify-start gap-3">
            <div className="flex w-full justify-end py-5">
              <div className="flex flex-row gap-3 rounded-l-full bg-mygreen w-[95%] items-center h-fit">
                {/* <PiBuildingsLight
          size={40}
          className=" text-lightblue bg-white border-mygreen border rounded-full p-1 shadow drop-shadow-2xl"
        /> */}
                <img
                  src={cardicon06}
                  alt=""
                  className="bg-white border-mygreen border-2 rounded-full p-1 shadow drop-shadow-2xl"
                />

                <h1 className="h-fit text-start text-black text-sm font-medium">
                  Experienced Team
                </h1>
              </div>
            </div>

            <p className="text-[#757575] text-sm text-justify px-5">
              Leverage the expertise of our seasoned professionals who bring
              years of industry knowledge to manage your warehouse efficiently.
            </p>
          </div>

          <div className="bg-white flex flex-col rounded-xl w-[250px] h-52 justify-start gap-3">
            <div className="flex w-full justify-end py-5">
              <div className="flex flex-row gap-3 rounded-l-full bg-mygreen w-[95%] items-center h-fit">
                {/* <PiBuildingsLight
          size={40}
          className=" text-lightblue bg-white border-mygreen border rounded-full p-1 shadow drop-shadow-2xl"
        /> */}
                <img
                  src={cardicon03}
                  alt=""
                  className="bg-white border-mygreen border-2 rounded-full p-1 shadow drop-shadow-2xl"
                />

                <h1 className="h-fit text-center  text-black text-sm font-medium">
                  Comprehensive Services
                </h1>
              </div>
            </div>

            <p className="text-[#757575] text-sm text-justify px-5">
              From tenant acquisition to maintenance and financial management,
              we provide a full suite of services to cover all your needs.
            </p>
          </div>

          <div className="bg-white flex flex-col rounded-xl w-[250px] h-52 justify-start gap-3">
            <div className="flex w-full justify-end py-5">
              <div className="flex flex-row gap-3 rounded-l-full bg-mygreen w-[95%] items-center h-fit">
                {/* <PiBuildingsLight
          size={40}
          className=" text-lightblue bg-white border-mygreen border rounded-full p-1 shadow drop-shadow-2xl"
        /> */}
                <img
                  src={cardicon08}
                  alt=""
                  className="bg-white border-mygreen border-2 rounded-full p-1 shadow drop-shadow-2xl"
                />

                <h1 className="h-fit text-center  text-black text-sm font-medium">
                  Transparent Reporting
                </h1>
              </div>
            </div>

            <p className="text-[#757575] text-sm text-justify px-5">
              Stay informed with clear and detailed financial reports, offering
              complete visibility into your warehouse’s performance.
            </p>
          </div>
        </div>
        <a href="#contact">
          <p className="md:w-[30%] w-[60%] sideshadow text-white cursor-pointer bg-blue py-5 mx-auto text-center text-xl rounded-2xl font-semibold">
            Talk To Us Now
          </p>
        </a>
      </div>
    </>
  );
};

export default Cards2;
