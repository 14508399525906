import React, { useEffect, useState } from "react";
import Opporimg from "../../assests/Opporimg.png";
import { LiaStarSolid } from "react-icons/lia";
import { Select } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
// import 'antd/dist/antd.css';
const { Option } = Select;

export const Opportunity = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [activeLease, setActiveLease] = useState(true);
  const [activerealEstate, setActiverealEstate] = useState(false);
  const [selectedOption, setSelectedOption] = useState("leasing");
  const [status, setStatus] = useState(1);
  const rioApiUrl = process.env.L;
  const [investmentData, setInvestmentData] = useState([]);
  const [PaginationMeta, setPaginationMeta] = useState({});

  const handleTabClicklease = () => {
    setActiveLease(true);
    setActiverealEstate(false);
  };
  const handleTabClickrealestate = () => {
    setActiveLease(false);
    setActiverealEstate(true);
  };

  const toggleShowAll = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  const MAX_LENGTH = 120;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const statusParam = status === 1 ? "1" : "2";
        const response = await axios.get(
          `https://app-script.riofinassets.com/user/investment?status=${statusParam}&page=1`
        );
        const responseData = response.data;
        if (selectedOption === "leasing") {
          setInvestmentData(responseData.leasing_investments);
          setPaginationMeta(responseData.leasing_pagination_meta);
        } else if (selectedOption === "realEstate") {
          setInvestmentData(responseData.real_estate_investments);
          setPaginationMeta(responseData.real_estate_pagination_meta);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [selectedOption]);

  const handleChange = (value) => {
    setSelectedOption(value);
  };

  // const handleChange = (value) => {
  //   setSelectedOption(value);
  //   const statusParam = status === 1 ? "1" : "2";

  //   axios
  //     .get(
  //       `https://app-script.riofinassets.com/user/investment?status=${statusParam}&page=1`
  //     )
  //     .then((res) => {
  //       const responseData = res.data;
  //       if (value === "leasing") {
  //         setInvestmentData(responseData.leasing_investments);
  //         setPaginationMeta(responseData.leasing_pagination_meta);
  //       } else if (value === "realEstate") {
  //         setInvestmentData(responseData.real_estate_investments);
  //         setPaginationMeta(responseData.real_estate_pagination_meta);
  //       }
  //       console.log("Investment Data:", investmentData);
  //     })
  //     .catch((err) => console.log(err));

  //   if (value === "leasing") {
  //     handleTabClicklease();
  //   } else if (value === "realEstate") {
  //     handleTabClickrealestate();
  //   }
  // };

  function formatINR(amount) {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    }).format(amount);
  }

  return (
    <article>
      <section className="flex  sm:px-10 mt-5">
        <div className="flex text-center justify-center ">
          <div className="">
            <div className="bg-gray flex items-center gap-3 text-black font-bold font-sans px-6 py-8 ">
              <h2>Opportunity Types</h2>
              <Select
                defaultValue="leasing"
                style={{ width: 120 }}
                onChange={handleChange}
              >
                <Option value="leasing">Leasing</Option>
                <Option value="realEstate">Real Estate</Option>
              </Select>
            </div>
          </div>
        </div>
      </section>
      <article>
        <section
          className={`grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 w-[90%] mx-auto pb-10 gap-10 place-items-center ${
            activeLease === false ? "hidden" : ""
          }`}
        >
          {investmentData &&
            investmentData.map((item, index) => (
              <div
                className={`${
                  item.status === 2 ? "filter grayscale" : ""
                } cursor-pointer`}
                key={index}
              >
                <div
                  className="relative h-[180px] bg-cover rounded-t-xl drop-shadow-xl bg-no-repeat"
                  style={{ backgroundImage: `url(${Opporimg})` }}
                >
                  <div className="flex absolute gap-5 translate-x-[45%] top-0 -mt-1">
                    <p className=" bg-[#00FF00] pl-2 pr-6 text-[12px] font-medium rounded-full drop-shadow-xl">
                      Capital Protected
                    </p>
                    <p className=" bg-[#00FF00] pl-2 pr-6 text-[12px] font-medium rounded-full drop-shadow-xl">
                      Trending
                    </p>
                  </div>
                  <h2 className="text-[#1BA11B] font-semibold text-sm bg-white bg-opacity-70 rounded-tr-2xl py-0.5 px-5 bottom-0 absolute">
                    {item.status === 1 ? (
                      <p>Active Offers</p>
                    ) : (
                      <p>Past Offers</p>
                    )}
                  </h2>
                </div>
                <div className="w-full bg-[#EEFFEE] p-5 rounded-b-xl drop-shadow-xl">
                  <div>
                    <p className="text-[10px] text-[#1BA11B] font-semibold">
                      {item.title}
                    </p>
                  </div>
                  <div className="flex items-end justify-between gap-2">
                    <div className="w-3/4">
                      <p className="text-black">65%</p>
                      <progress value={0.5} className="h-1.5 w-full" />
                    </div>
                    <div>
                      {item.project.map((data, idx) => (
                        <p key={idx}>{formatINR(data.total_invested_amount)}</p>
                      ))}
                    </div>
                  </div>

                  <div>
                    <div className="flex flex-row mt-2">
                      <button
                        className={`text-[10px] font-semibold border-b-2 border-[#00FF00] ${
                          expandedIndex === index ? "border-transparent" : ""
                        }`}
                        onClick={() => toggleShowAll(index)}
                      >
                        BENEFITS
                      </button>
                    </div>
                    <hr className="top-1 lg:mb-3 text-gray" />
                    {/* <div className="text-[12px] mb-4 text-justify">
                      <p className="text-[#1BA11B] font-medium">
                      {expandedIndex === index
                        ? item.benifits
                        : item.benifits.slice(0, MAX_LENGTH)}
                      {item.benifits.length > MAX_LENGTH && (
                        <span>
                          {" "}
                          {expandedIndex !== index ? (
                            <button
                              className="text-[#1BA11B] font-medium"
                              onClick={() => toggleShowAll(index)}
                            >
                              ....<span className="underline">read more</span>
                            </button>
                          ) : (
                            <button
                              className="text-[#1BA11B] font-medium"
                              onClick={() => toggleShowAll(index)}
                            >
                              (read less)
                            </button>
                          )}
                        </span>
                      )}
                    </p>
                    </div> */}
                    {/* <div className="text-[12px] mb-4 text-justify">
                      <p
                        className="text-[#1BA11B] font-medium"
                        dangerouslySetInnerHTML={{
                          __html:
                            expandedIndex === index
                              ? item.benifits
                              : item.benifits.slice(0, MAX_LENGTH),
                        }}
                      />
                      {item.benifits.length > MAX_LENGTH && (
                        <span>
                          {" "}
                          {expandedIndex !== index ? (
                            <button
                              className="text-[#1BA11B] font-medium"
                              onClick={() => toggleShowAll(index)}
                            >
                              ....<span className="underline">read more</span>
                            </button>
                          ) : (
                            <button
                              className="text-[#1BA11B] font-medium"
                              onClick={() => toggleShowAll(index)}
                            >
                              (read less)
                            </button>
                          )}
                        </span>
                      )}
                    </div> */}
                    <div className="text-[12px] mb-4 text-justify">
                      Investing in Riofin's fractional ownership program for
                      equipment leasing can offer several benefits for
                      investors, including: 1.Diversification: Investing in
                      fractional ownership of equipment.
                    </div>
                  </div>
                  <div className="flex justify-evenly items-center text-[12px]">
                    <div className="flex flex-col items-center gap-1">
                      <p className="font-semibold">ROI %</p>
                      {item.project.map((data, idx) => (
                        <button
                          key={idx}
                          className="bg-gray rounded-full px-4 py-1 text-[#00FF00] bg-[#26272a]"
                        >
                          {data.bank_rate_percentage}%
                        </button>
                      ))}
                    </div>
                    <div className="flex flex-col items-center gap-1">
                      <p className="font-semibold">TENURE</p>
                      {item.project.map((data, idx) => (
                        <button
                          key={idx}
                          className="bg-gray rounded-full px-4 py-1 text-[#00FF00] bg-[#26272a]"
                        >
                          {data.tenure} months
                        </button>
                      ))}
                    </div>
                    <div className="flex flex-col items-center gap-1">
                      <p className="font-semibold">MIN INVESTING</p>
                      {item.project.map((data, idx) => (
                        <button
                          key={idx}
                          className="bg-gray rounded-full px-4 py-1 text-[#00FF00] bg-[#26272a]"
                        >
                          {formatINR(data.total_invested_amount)}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 text-[10px] font-semibold mt-2">
                    <Link to="https://riofinadmin.whytap.tech/">
                      <button className="bg-transparent px-10 w-full border-[1.5px] py-0.5 border-[#00FF00] hover:bg-mygreen duration-300 rounded-full">
                        INVEST NOW
                      </button>
                    </Link>
                    <Link to="https://riofinadmin.whytap.tech/">
                      <button className="px-10 w-full border-[1.5px] py-0.5 bg-[#00FF00] rounded-full hover:scale-105 transition-transform duration-300 ease-in-out transform">
                        LEARN MORE
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}

          <div className={`filter grayscale cursor-pointer`}>
            <div
              className="relative h-[180px] bg-cover rounded-t-xl drop-shadow-xl bg-no-repeat"
              style={{ backgroundImage: `url(${Opporimg})` }}
            >
              <div className="flex absolute gap-5 translate-x-[45%] top-0 -mt-1">
                <p className=" bg-[#00FF00] pl-2 pr-6 text-[12px] font-medium rounded-full drop-shadow-xl">
                  Capital Protected
                </p>
                <p className=" bg-[#00FF00] pl-2 pr-6 text-[12px] font-medium rounded-full drop-shadow-xl">
                  Trending
                </p>
              </div>
              <h2 className="text-[#1BA11B] font-semibold text-sm bg-white bg-opacity-70 rounded-tr-2xl py-0.5 px-5 bottom-0 absolute">
                <p>Past Offers</p>
              </h2>
            </div>
            <div className="w-full bg-[#EEFFEE] p-5 rounded-b-xl drop-shadow-xl">
              <div>
                <p className="text-[10px] text-[#1BA11B] font-semibold">
                  CONSTRUCTION AND INFRASTRUCTURE INDUSTRY
                </p>
              </div>
              <div className="flex items-end justify-between">
                <div className="w-3/4">
                  <p className="text-black">65%</p>
                  <progress value={1} className="h-1.5 w-full" />
                </div>
                <div>
                  <p>₹4,18,64,023.00</p>
                </div>
              </div>
              <div>
                <div className="flex flex-row mt-2">
                  <button
                    className={`text-[10px] font-semibold border-b-2 border-[#00FF00]`}
                  >
                    BENEFITS
                  </button>
                </div>
                <hr className="top-1 lg:mb-3 text-gray" />
                <div className="text-[12px] mb-4 text-justify">
                  <p className="text-[#1BA11B] font-medium" />
                  <span>
                    Investing in Riofin's fractional ownership program for
                    equipment leasing can offer several benefits for investors,
                    including: 1.Diversification: Investing in fractional
                    ownership of equipment.
                  </span>
                </div>
              </div>
              <div className="flex justify-evenly items-center text-[12px]">
                <div className="flex flex-col items-center gap-1">
                  <p className="font-semibold ">ROI %</p>
                  <button className="bg-gray rounded-full px-4 py-1 text-[#00FF00] bg-[#26272a]">
                    8%
                  </button>
                </div>
                <div className="flex flex-col items-center gap-1">
                  <p className="font-semibold">TENURE</p>
                  <button className="bg-gray rounded-full px-4 py-1 text-[#00FF00] bg-[#26272a]">
                    42months
                  </button>
                </div>
                <div className="flex flex-col items-center gap-1">
                  <p className="font-semibold">MIN INVESTING</p>
                  <button className="bg-gray rounded-full px-4 py-1 text-[#00FF00] bg-[#26272a]">
                    ₹4,18,64,023.00
                  </button>
                </div>
              </div>
              <div className="flex flex-col gap-2 text-[10px] font-semibold mt-2">
                {/* <button className="bg-transparent px-10 border-[1.5px] py-0.5 border-[#00FF00] rounded-full">
                  INVEST NOW
                </button> */}
                <Link to="https://riofinadmin.whytap.tech/">
                  <button className="w-full px-10 border-[1.5px] py-0.5 bg-[#00FF00] rounded-full">
                    LEARN MORE
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className={`filter grayscale cursor-pointer`}>
            <div
              className="relative h-[180px] bg-cover rounded-t-xl drop-shadow-xl bg-no-repeat"
              style={{ backgroundImage: `url(${Opporimg})` }}
            >
              <div className="flex absolute gap-5 translate-x-[45%] top-0 -mt-1">
                <p className=" bg-[#00FF00] pl-2 pr-6 text-[12px] font-medium rounded-full drop-shadow-xl">
                  Capital Protected
                </p>
                <p className=" bg-[#00FF00] pl-2 pr-6 text-[12px] font-medium rounded-full drop-shadow-xl">
                  Trending
                </p>
              </div>
              <h2 className="text-[#1BA11B] font-semibold text-sm bg-white bg-opacity-70 rounded-tr-2xl py-0.5 px-5 bottom-0 absolute">
                <p>Past Offers</p>
              </h2>
            </div>
            <div className="w-full bg-[#EEFFEE] p-5 rounded-b-xl drop-shadow-xl">
              <div>
                <p className="text-[10px] text-[#1BA11B] font-semibold">
                  CONSTRUCTION AND INFRASTRUCTURE INDUSTRY
                </p>
              </div>
              <div className="flex items-end justify-between">
                <div className="w-3/4">
                  <p className="text-black">65%</p>
                  <progress value={1} className="h-1.5 w-full" />
                </div>
                <div>
                  <p>₹4,18,64,023.00</p>
                </div>
              </div>
              <div>
                <div className="flex flex-row mt-2">
                  <button
                    className={`text-[10px] font-semibold border-b-2 border-[#00FF00]`}
                  >
                    BENEFITS
                  </button>
                </div>
                <hr className="top-1 lg:mb-3 text-gray" />
                <div className="text-[12px] mb-4 text-justify">
                  <p className="text-[#1BA11B] font-medium" />
                  <span>
                    Investing in Riofin's fractional ownership program for
                    equipment leasing can offer several benefits for investors,
                    including: 1.Diversification: Investing in fractional
                    ownership of equipment.
                  </span>
                </div>
              </div>
              <div className="flex justify-evenly items-center text-[12px]">
                <div className="flex flex-col items-center gap-1">
                  <p className="font-semibold">ROI %</p>
                  <button className="bg-gray rounded-full px-4 py-1 text-[#00FF00] bg-[#26272a]">
                    8%
                  </button>
                </div>
                <div className="flex flex-col items-center gap-1">
                  <p className="font-semibold">TENURE</p>
                  <button className="bg-gray rounded-full px-4 py-1 text-[#00FF00] bg-[#26272a]">
                    42months
                  </button>
                </div>
                <div className="flex flex-col items-center gap-1">
                  <p className="font-semibold">MIN INVESTING</p>
                  <button className="bg-gray rounded-full px-4 py-1 text-[#00FF00] bg-[#26272a]">
                    ₹4,18,64,023.00
                  </button>
                </div>
              </div>
              <div className="flex flex-col gap-2 text-[10px] font-semibold mt-2">
                {/* <button className="bg-transparent px-10 border-[1.5px] py-0.5 border-[#00FF00] rounded-full">
                  INVEST NOW
                </button> */}
                <Link to="https://riofinadmin.whytap.tech/">
                  <button className="w-full px-10 border-[1.5px] py-0.5 bg-[#00FF00] rounded-full">
                    LEARN MORE
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className={`cursor-pointer`}>
            <div
              className="relative h-[180px] bg-cover rounded-t-xl drop-shadow-xl bg-no-repeat"
              style={{ backgroundImage: `url(${Opporimg})` }}
            >
              <div className="flex absolute gap-5 translate-x-[45%] top-0 -mt-1">
                <p className=" bg-[#00FF00] pl-2 pr-6 text-[12px] font-medium rounded-full drop-shadow-xl">
                  Capital Protected
                </p>
                <p className=" bg-[#00FF00] pl-2 pr-6 text-[12px] font-medium rounded-full drop-shadow-xl">
                  Trending
                </p>
              </div>
              <h2 className="text-[#1BA11B] font-semibold text-sm bg-white bg-opacity-70 rounded-tr-2xl py-0.5 px-5 bottom-0 absolute">
                <p>Active Offers</p>
              </h2>
            </div>
            <div className="w-full bg-[#EEFFEE] p-5 rounded-b-xl drop-shadow-xl">
              <div>
                <p className="text-[10px] text-[#1BA11B] font-semibold">
                  CONSTRUCTION AND INFRASTRUCTURE INDUSTRY
                </p>
              </div>
              <div className="flex items-end gap-2 justify-between">
                <div className="w-3/4">
                  <p className="text-black">65%</p>
                  <progress value={0.5} className="h-1.5 w-full" />
                </div>
                <div>
                  <p>₹4,18,64,023.00</p>
                </div>
              </div>
              <div>
                <div className="flex flex-row mt-2">
                  <button
                    className={`text-[10px] font-semibold border-b-2 border-[#00FF00]`}
                  >
                    BENEFITS
                  </button>
                </div>
                <hr className="top-1 lg:mb-3 text-gray" />
                <div className="text-[12px] mb-4 text-justify">
                  <p className="text-[#1BA11B] font-medium" />
                  <span>
                    Investing in Riofin's fractional ownership program for
                    equipment leasing can offer several benefits for investors,
                    including: 1.Diversification: Investing in fractional
                    ownership of equipment.
                  </span>
                </div>
              </div>
              <div className="flex justify-evenly items-center text-[12px]">
                <div className="flex flex-col items-center gap-1">
                  <p className="font-semibold">ROI %</p>
                  <button className="bg-gray rounded-full px-4 py-1 text-[#00FF00] bg-[#26272a]">
                    8%
                  </button>
                </div>
                <div className="flex flex-col items-center gap-1">
                  <p className="font-semibold">TENURE</p>
                  <button className="bg-gray rounded-full px-4 py-1 text-[#00FF00] bg-[#26272a]">
                    42months
                  </button>
                </div>
                <div className="flex flex-col items-center gap-1">
                  <p className="font-semibold">MIN INVESTING</p>
                  <button className="bg-gray rounded-full px-4 py-1 text-[#00FF00] bg-[#26272a]">
                    ₹4,18,64,023.00
                  </button>
                </div>
              </div>
              <div className="flex flex-col gap-2 text-[10px] font-semibold mt-2">
                <Link to="https://riofinadmin.whytap.tech/">
                  <button className="bg-transparent w-full px-10 border-[1.5px] py-0.5 border-[#00FF00] hover:bg-mygreen duration-300 rounded-full">
                    INVEST NOW
                  </button>
                </Link>
                <Link to="https://riofinadmin.whytap.tech/">
                  <button className="w-full px-10 border-[1.5px] py-0.5 bg-[#00FF00] rounded-full hover:scale-105 transition-transform duration-300 ease-in-out transform">
                    LEARN MORE
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </article>
    </article>
  );
};
