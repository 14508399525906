import React from "react";
import About from "../../assests/Ri-new1.png";
import Opportunities from '../../assests/Opportunities.jpg';

const Page = () => {
  return (
    <>
      <div className="bg-black text-white px-4 md:px-6 lg:px-16">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="mb-6 w-full font-sans">
              <div className="flex flex-col pt-8 md:pt-16">
                <div className="border-b-8 border-[#04ef00]"></div>

                <h1 className="mt-3 text-xl sm:text-lg lg:text-5xl  font-bold mb-2 md:mb-4">
                  Riofin’s Opportunities
                </h1>
                <p className="text-[16px] sm:text-lg lg:text-[20px] md:w-full">
                  Explore our opportunities for lucrative deals and strategic
                  investments in warehouse assets, tailored to maximize returns
                  and foster long-term growth in real estate.
                </p>
              </div>
            </div>
            <div className="md:my-5 flex justify-center md:justify-end">
              <img
                src={Opportunities}
                alt="no img"
                className="max-w-full hidden md:block h-64 md:max-w-md lg:max-w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page;
