// App.js

import React, { useState } from "react";
import "./flip.css";
import img1 from '../../assests/a-list1.png';
import img2 from '../../assests/a-list2.png';
import img3 from '../../assests/a-list3.png';

const GridSection = () => {
  const cards = [
    { id: 1, frontImage: img1, backContent: "Purchase at Lower Value: Investors buy properties at a lower market value, often distressed or undervalued." },
    { id: 2, frontImage: img2, backContent: "Strategic Renovation: The property undergoes strategic renovations or improvements to enhance its appeal and market value." },
    { id: 3, frontImage: img3, backContent: "Profitable Resale: After renovations, the property is sold quickly at a higher price, allowing investors to make a profit from the increased value." },
  ];

  const [flippedCards, setFlippedCards] = useState([]);

  const handleFlip = (cardId) => {
    setFlippedCards((prevFlippedCards) => {
      if (prevFlippedCards.includes(cardId)) {
        return prevFlippedCards.filter((id) => id !== cardId);
      } else {
        return [...prevFlippedCards, cardId];
      }
    });
  };

  return (
    <div className="App w-full">
      <div className="text-center">
        <h1 className='text-black mt-4 font-bold text-xl md:text-4xl relative'>
          What Is Property Flipping ?
          <div className='border-b-4 border-[#04ef00] md:absolute md:right-1/3 absolute right-1/2  bottom-0 top-12 w-full md:w-1/1 font-sans mt-2'></div>
        </h1>
      </div>
      <div className="w-full flex justify-center items-center my-16 text-center">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {cards.map((card) => (
            <div
              key={card.id}
              className={`flip-card ${flippedCards.includes(card.id) ? "flipped" : ""}`}
            >
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img src={card.frontImage} alt={`Card ${card.id} Front`} />
                  <button
                    className="flip-button"
                    onClick={() => handleFlip(card.id)}
                  >
                  </button>
                </div>
                <div className="flip-card-back">
                  <div className="card-content">
                    {card.backContent}
                  </div>
                  <button
                    className="flip-button"
                    onClick={() => handleFlip(card.id)}
                  >
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GridSection;
