import React from "react";
import RfaImage from "../../assests/RFA.png";

const Rfa = () => {
  return (
    <>
      <div className="relative">
        <div className="w-full lg:h-full h-[500px] homefunded:h-[500px]">
          <img src={RfaImage} alt="" className="w-full h-full" />
        </div>
        <div className="bg-black absolute top-0 bg-opacity-70 w-full h-full"></div>
        <div className="absolute top-0 text-white w-full h-full space-y-5 ">
          <h1 className="text-center xl:text-5xl md:text-2xl sm:text-lg  font-semibold p-5 xl:pt-14 ">
            Tailored Warehouse Solutions
          </h1>
          <div className="flex flex-col justify-end items-end">
            <hr className="h-0.5 w-1/2 bg-mygreen" />
          </div>
          <div className="xl:p-10 p-2 space-y-10">
            <div className="flex flex-row justify-start items-center gap-5 lg:w-[80%] mx-auto">
              <p className="bg-white text-blue1 font-bold p-2 px-4 rounded-full shadow-md shadow-black">
                1
              </p>
              <p className="homefunded:text-base text-xs">
                We excel in securing optimal warehouse spaces and crafting
                leases tailored to your growth and strategic objectives.
              </p>
            </div>
            <div className="flex flex-row justify-start items-center gap-5 lg:w-[80%] mx-auto">
              <p className="bg-white text-blue1 p-2 px-4 rounded-full shadow-md shadow-black">
                2
              </p>
              <p className="homefunded:text-base text-xs">
                Our thorough market analysis empowers you to make informed
                decisions aligned with your unique needs.
              </p>
            </div>
            <div className="flex flex-row justify-start items-center gap-5 lg:w-[80%] mx-auto">
              <p className="bg-white text-blue1 p-2 px-4 rounded-full shadow-md shadow-black">
                3
              </p>
              <p className="homefunded:text-base text-xs">
                With a consultative approach, we deliver personalized solutions,
                backed by a history of client success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rfa;
