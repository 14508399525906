import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import background1 from '../../assests/Frame 15.png';
import background2 from '../../assests/v2.png';
import background3 from '../../assests/v3.png';
import background4 from '../../assests/Loginrec.png';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assests/Riologo.png'

const SignUpForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [otp, setOtp] = useState('');


  return (

    <div className="relative">
     
        <Carousel className="" autoPlay={true} interval={5000} infiniteLoop={true} showStatus={false} showThumbs={false}>
          <div>
            <img src={background1} className="object-cover object-center" alt="" />
          </div>
          <div>
            <img src={background2} className="object-cover object-center" alt="" />
          </div>
          <div>
            <img src={background3} className="object-cover object-center" alt="" />
          </div>
        </Carousel>
    

      <div className=" flex  mt-0 md:absolute  inset-0 items-center  lg:p-40 md:p-20 p-4">
      <div className="title-container hidden md:flex flex-col  items-center sm:pt-4 md:pt-6 lg:pt-8 sm:h-64 md:h-[450px] lg:h-[610px] w-full lg:w-1/2" style={{ backgroundImage: `url(${background4})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', position: 'relative' }}>
  <p className="font-bold" style={{ position: 'absolute', bottom: '0', color: 'white', padding: '8%', fontWeight: 'bold'  }}>Unlock your financial potential with RioFin, your trusted partner in smart investments.</p>
  <img src={Logo} className="lg:h-20 md:h-10" alt="" />
</div>
<div className="bg-white  w-full lg:w-1/2  md:pt-2 lg:pt-8 h-fit rounded">
          <form className="lg:p-4 md:p-2 ">
            <div className=" flex justify-end ">
                <Link to="/login">
                  <button className="py-2 px-6 lg:text-md md:text-sm  font-bold bg-black text-white rounded-lg">
                    Log In
                  </button>
                </Link>
                <Link to="/signupform">
                  <button className="py-2 px-6 lg:text-md md:text-sm    font-bold  bg-mygreen text-black rounded-lg">
                    Sign Up
                  </button>
                </Link>
              </div>
              <div className="lg:mb-8 lg:mt-9">
                <label className="block text-gray-700 lg:text-sm md:text-xs  ml-2 font-bold mb-2" htmlFor="name">
                Name
                </label>
                <input
                  className="shadow-none appearance-none border-b border-gray-500 w-full py-2 px-3 lg:text-sm md:text-xs  text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                  id="name"
                  type="text"
                  placeholder="Enter Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <label className="block text-gray-700 p-2 lg:text-sm md:text-xs  font-bold mb-2" htmlFor="email">
                 Email
                </label>
                <input
                  className="shadow-none appearance-none border-b border-gray-500 w-full lg:text-sm md:text-xs  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                  id="email"
                  type="email"
                  placeholder="Enter Your Email Id"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label className="block text-gray-700 lg:text-sm md:text-xs  p-2 font-bold mb-2" htmlFor="referralCode">
                  Referral Code (Optional) 
                </label>
                <input
                  className="shadow-none appearance-none border-b border-gray-500 w-full lg:text-sm md:text-xs  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                  id="referralCode"
                  type="tel"
                  placeholder="Enter Your Referral Code"
                  value={referralCode}
                  onChange={(e) => setReferralCode(e.target.value)}
                />
                <label className="block text-gray-700 lg:text-sm md:text-xs  p-2 font-bold mb-2" htmlFor="otp">
                 Mobile Number 
                </label>
                <input
                  className="shadow-none appearance-none border-b border-gray-500 w-full lg:text-sm md:text-xs  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                  id="otp"
                  type="text"
                  placeholder="Enter Your Mobile Number"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
                <label className="block text-gray-700 lg:text-sm md:text-xs  p-2 font-bold mb-2" htmlFor="otp">
                OTP
                </label>
                <input
                  className="shadow-none appearance-none border-b border-gray-500 w-full lg:text-sm md:text-xs  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                  id="otp"
                  type="tel"
                  placeholder="Enter Your OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
              </div>
              <Link to='/signup'>
            <div className=" flex justify-end pt-3 ">
      <button className='bg-black py-2 px-6 text-white lg:text-md md:text-sm  font-bold text-center rounded-lg'>Next</button>
      </div>
      </Link>
  
  
 
            </form>
          
      </div>
       </div>
       </div>
      
    
  );
}

export default SignUpForm;
