import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import build from "../../assests/asset.png";
function Banner() {
  return (
    <div className="flex flex-row justify-center items-center -mb-1">
      <div className="p-10 lg:w-[45%] flex flex-col 2xl:gap-5 w-full space-y-5">
        <h1 className="sm:text-3xl 2xl:text-5xl homefunded:text-2xl text-lg font-bold lg:text-start text-center  text-black">
          Optimize Your Warehouse Assets with Riofin{" "}
        </h1>
        <hr className="w-1/2 h-0.5 lg:mx-0 mx-auto bg-mygreen" />
        {/* <div className="flex sm:flex-row flex-col md:gap-0 gap-3 lg:justify-between justify-around items-center">
        <div className="flex flex-col gap-3">
          <div className="flex flex-row items-center gap-3">
            <FaRegCheckCircle className="w-6 h-6 text-mygreen" />
            <p>No lengthy process</p>
          </div>
          <div className="flex flex-row items-center gap-3">
            <FaRegCheckCircle className="text-mygreen w-6 h-6" />
            <p>No delays</p>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex flex-row items-center gap-3">
            <FaRegCheckCircle className="text-mygreen w-6 h-6" />
            <p>No hidden charges</p>
          </div>
          <div className="flex flex-row items-center gap-3">
            <FaRegCheckCircle className="text-mygreen w-6 h-6" />
            <p>No Obligation</p>
          </div>
        </div>
      </div> */}
        <p className="lg:w-[75%] lg:text-start text-center font-semibold">
          Expert tenant bringing, maintenance, and more with Riofin's expertise.
        </p>
        <div className="w-full flex">
          <a href="#contact">
            <button className="text-white bg-blue p-3 lg:mx-0 mx-auto px-8 text-center rounded-full">
              Talk to Us Now
            </button>
          </a>
        </div>
      </div>
      <div className="lg:w-[55%] h-full lg:block hidden">
        <img src={build} alt="" className="w-full h-full " />
      </div>
    </div>
  );
}

export default Banner;
