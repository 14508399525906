// import React, { useState } from 'react';
// import rectangle4431 from '../../assests/E-Rectangle 4431.png';

// // ... (your existing imports)

// // ... (your existing imports)

// const Form = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     companyName: '',
//     companyWebsite: '',
//     financingType: '',
//     stakeHeld: '',
//     annualRevenue: '',
//     cashRunway: '',
//     whereHeard: '',
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Add your form submission logic here
//     console.log('Form submitted:', formData);
//   };

//   return (
//     <div className="flex flex-col md:flex-row">
//       {/* Image Section */}
//       <div className="lg:w-2/4 md:w-2/3">
//         <img src={rectangle4431} alt="no img" className="w-full h-full" />
//       </div>

//       {/* Form Section */}
//       <div className="lg:w-2/4 md:w-2/3">
//         <form className="max-w-md mx-auto mt-8 p-4" onSubmit={handleSubmit}>
//           <h1 className="font-bold text-black text-3xl text-center lg:text-left">Contact Form</h1>
//           <div className="bg-mygreen mb-5 p-0.5 mr-40 px-4 md:px-18 md:mr-40" />

//           <div className="grid grid-cols-1 gap-6 p-0">
//             <input
//               type="text"
//               id="name"
//               name="name"
//               placeholder="Name"
//               value={formData.name}
//               onChange={handleChange}
//               className="mt-4 p-2 w-full bg-formbg"
//               required
//             />
//             <input
//               type="text"
//               id="email"
//               name="email"
//               placeholder="Email"
//               value={formData.email}
//               onChange={handleChange}
//               className="p-2 w-full bg-formbg"
//               required
//             />
//             <input
//               type="text"
//               id="companyName"
//               name="companyName"
//               placeholder="Company Legal Name"
//               value={formData.companyName}
//               onChange={handleChange}
//               className="p-2 w-full bg-formbg"
//               required
//             />
//             <input
//               type="text"
//               id="companyWebsite"
//               name="companyWebsite"
//               placeholder="Company Website Link"
//               value={formData.companyWebsite}
//               onChange={handleChange}
//               className=" p-2 w-full bg-formbg"
//               required
//             />
//             <input
//               type="text"
//               id="financingType"
//               name="financingType"
//               placeholder="Type Of Financing"
//               value={formData.financingType}
//               onChange={handleChange}
//               className=" p-2 w-full bg-formbg"
//               required
//             />
//             <input
//               type="text"
//               id="stakeHeld"
//               name="stakeHeld"
//               placeholder="Stake held by VC/PE investors"
//               value={formData.stakeHeld}
//               onChange={handleChange}
//               className=" p-2 w-full bg-formbg"
//               required
//             />
//             <input
//               type="text"
//               id="annualRevenue"
//               name="annualRevenue"
//               placeholder="Annual Revenue Rate"
//               value={formData.annualRevenue}
//               onChange={handleChange}
//               className="p-2 w-full bg-formbg"
//               required
//             />
//             <input
//               type="text"
//               id="cashRunway"
//               name="cashRunway"
//               placeholder="Cash Runway"
//               value={formData.cashRunway}
//               onChange={handleChange}
//               className=" p-2 w-full bg-formbg"
//               required
//             />
//             <input
//               type="text"
//               id="whereHeard"
//               name="whereHeard"
//               placeholder="Where did you hear about us?"
//               value={formData.whereHeard}
//               onChange={handleChange}
//               className=" p-2 w-full bg-formbg"
//               required
//             />
//           </div>

//           {/* Submit button */}
//           <div className="mt-6 lg:text-right text-center">
//             <button
//               type="submit"
//               className="bg-mygreen text-black px-8 py-2 rounded-lg font-bold"
//             >
//               Submit
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Form;

import React from "react";

function BoxSection() {
  return (
    <article className="bg-white flex justify-center items-center m-10">
      <section className="bg-gradient-to-r from-[#2272FF] to-[#0D03C3] rounded-xl shadow-xl shadow-black flex flex-col gap-5 items-center justify-center sm:px-20 sm:py-8 p-5">
        <h2 className="sm:text-xl md:text-2xl lg:text-3xl text-white font-semibold">
          Start Your Profitable Investment Journey Today with Riofin!
        </h2>
        <a href="#contact">
          <p className="font-bold bg-mygreen cursor-pointer py-2 px-2 sm:py-4 sm:px-5 sm:text-base text-[10px]">
            Talk to us now
          </p>
        </a>
      </section>
    </article>
  );
}

export default BoxSection;
