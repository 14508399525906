import React, { useState, useEffect, useRef } from "react";
import BackgroundImage1 from "../../assests/tab.png";
import Aos from "aos";
import "aos/dist/aos.css";

const TabsComponent = ({ items }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const firstBtnRef = useRef();

  // useEffect(() => {
  //   firstBtnRef.current.focus();
  // }, []);

  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedTab((prevTab) =>
        prevTab === items.length - 1 ? 0 : prevTab + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [items.length]);

  const cardStyle1 = {
    backgroundImage: `url(${BackgroundImage1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const textColors = ["text-blue", "text-blue", "text-blue"];

  return (
    <div
      className="bg-gradient-to-b from-blue to-indigo-800 pl-4 pr-4 lg:pl-40 lg:pr-40 md:pl-20 md:pr-20 pt-10 pb-20 align-middle"
      style={cardStyle1}
    >
      <h1 className="text-white font-bold text-center md:text-4xl text-[26px] font-sans pb-10">
        Unlock Unconventional Opportunities for Growth
      </h1>

      <div
        className="flex justify-center items-center py-12 pt-10 pb-0 pr-2 pl-2 md:pr-20 md:pl-20 bg-white border-b-8 border-r-8 border-blue rounded-[18px]"
        data-aos="fade-up"
      >
        <div className="max-w-screen-xl w-full flex flex-col gap-y-2">
          <div className="bg-white p-1 rounded-[6px] flex text-[11px] md:text-[16px] gap-0 md:gap-x-2 font-bold border-b-6 border-r-6 md:justify-evenly text-black border-2 border-r-4 border-b-4 border-black">
            {items.map((item, index) => (
              <button
                ref={index === 0 ? firstBtnRef : null}
                key={index}
                onClick={() => setSelectedTab(index)}
                className={`w-full md:w-auto p-2 text-center focus:bg-white focus:text-blue ${
                  selectedTab === index ? `bg-white text-blue-600 ${textColors[index % textColors.length]}` : ""
                }`}
              >
                {item.title}
              </button>
            ))}
          </div>

          <div className="bg-white p-2 rounded-xl">
            {items.map((item, index) => (
              <div
                key={index}
                className={`${selectedTab === index ? "" : "hidden"}`}
              >
                {item.content}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabsComponent;
