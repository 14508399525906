import React, { useState, useEffect, useRef } from "react";
import Logo from "../assests/Logo.png"; // Update the path accordingly
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faBars, faCaretDown } from "@fortawesome/free-solid-svg-icons";

const Navbar1 = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1200);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const closeMobileMenu = () => {
    setShowMobileMenu(false);
  };

  // const handleBookNowClick = () => {
  //   const target = document.querySelector("#home");
  //   if (target) {
  //     const navHeight = document.querySelector(".Nav").offsetHeight;
  //     const containerPadding = 20;
  //     const contentOffset = 20;

  //     const top = target.offsetTop - navHeight - containerPadding + contentOffset;

  //     window.scrollTo({
  //       behavior: "smooth",
  //       top: top,
  //     });

  //     closeMobileMenu();
  //   }
  // };

  const [isServicesOpen, setIsServicesOpen] = useState(false); //dropdownmenu
  const [story, setStory] = useState(false);
  const [property, setProperty] = useState(false);
  const [warehouse, setWarehouse] = useState(false);

  const closeTimeout = useRef(null);

  //Resources Drop Down
  const handleServicesClick = () => {
    setIsServicesOpen(!isServicesOpen);
  };
  const openDropdown = () => {
    setIsServicesOpen(true);
    setStory(false);
    setProperty(false);
    setWarehouse(false);
    clearTimeout(closeTimeout.current);
  };
  const closeDropdown = () => {
    closeTimeout.current = setTimeout(() => {
      setIsServicesOpen(false);
    }, 600);
  };
  const handleMouseEnter = () => {
    openDropdown();
  };

  const handleMouseLeave = () => {
    closeDropdown();
  };

  //Our Story & values Drop Down
  const handleStoryClick = () => {
    setStory(!story);
  };

  const openStorydropdown = () => {
    setStory(true);
    setIsServicesOpen(false);
    setProperty(false);
    setWarehouse(false);
    clearTimeout(closeTimeout.current);
  };
  const closestorydropdown = () => {
    closeTimeout.current = setTimeout(() => {
      setStory(false);
    }, 600);
  };
  const handelStoryMouseEnter = () => {
    openStorydropdown();
  };
  const handleStoryMouseLeave = () => {
    closestorydropdown();
  };

  //Property Solution Drop Down
  const handlePropertyClick = () => {
    setProperty(!property);
  };

  const openPropertydropdown = () => {
    setProperty(true);
    setIsServicesOpen(false);
    setStory(false);
    setWarehouse(false);
    clearTimeout(closeTimeout.current);
  };
  const closePropertydropdown = () => {
    closeTimeout.current = setTimeout(() => {
      setProperty(false);
    }, 600);
  };
  const handelPropertyMouseEnter = () => {
    openPropertydropdown();
  };
  const handlePropertyMouseLeave = () => {
    closePropertydropdown();
  };

  //Warehouse Drop Down
  const handleWarehouseClick = () => {
    setWarehouse(!warehouse);
  };

  const openWarehousedropdown = () => {
    setWarehouse(true);
    setIsServicesOpen(false);
    setStory(false);
    setProperty(false);
    clearTimeout(closeTimeout.current);
  };
  const closeWarehousedropdown = () => {
    closeTimeout.current = setTimeout(() => {
      setWarehouse(false);
    }, 600);
  };
  const handelWarehouseMouseEnter = () => {
    openWarehousedropdown();
  };
  const handleWarehouseMouseLeave = () => {
    closeWarehousedropdown();
  };

  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setIsServicesOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);

  return (
    <nav className="bg-black pt-6 py-3 sticky top-0 z-50 ">
      <div className="container mx-auto">
        <div className="flex items-center justify-between mx-1">
          {/* Logo and Hamburger Menu */}
          <NavLink to="/" className="flex items-center">
            <img
              src={Logo}
              alt="Logo"
              className="text-white md:-ml-3  lg:-ml-0 md:w-36 lg:w-44"
            />
          </NavLink>
          <button
            className="text-white pl-3 ml-36 absolute right-8 nav1:mr-3 focus:outline-none nav1:hidden"
            onClick={toggleMobileMenu}
          >
            {isMobile && showMobileMenu ? (
              <FontAwesomeIcon icon={faTimes} onClick={closeMobileMenu} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </button>

          {/* Responsive Menu */}
          {isMobile && showMobileMenu && (
            <div className="w-full bg-black text-white  absolute top-full left-0 z-50">
              <div className="container mx-auto">
                <div className="flex flex-col items-start ml-4 py-2">
                  <NavLink
                    to="/"
                    title="Home"
                    onClick={closeMobileMenu}
                    className="block text-white hover:text-mygreen py-2 w-full"
                  >
                    Home
                  </NavLink>
                  <NavLink
                    to="/investment"
                    title="Opportunities"
                    onClick={closeMobileMenu}
                    className="block text-white hover:text-mygreen py-2 w-full"
                  >
                    Opportunities
                  </NavLink>

                  <Link
                    className={`text-white py-2 relative group hover:text-mygreen `}
                    title="Our Story & Values"
                    onClick={handleStoryClick}
                  >
                    Our Story & Values <FontAwesomeIcon icon={faCaretRight} />
                    <ul
                      className={`absolute right-0 -mr-48 -mt-[25%] ml-10 w-44 pl-2  rounded-lg ${
                        story ? "slide-in" : "slide-out"
                      } bg-black text-white mt-2 space-y-2  transition-opacity duration-300 ease-in-out`}
                    >
                      <NavLink
                        to="/aboutus"
                        onClick={closeMobileMenu}
                        className="block text-white hover:text-mygreen py-2 w-full "
                      >
                        About Us
                      </NavLink>
                      <NavLink
                        to="/resources/assets"
                        onClick={closeMobileMenu}
                        className="block text-white hover:text-mygreen py-2 w-full"
                      >
                        Our USP
                      </NavLink>
                    </ul>
                  </Link>

                  <Link
                    className={`text-white py-2 relative group hover:text-mygreen `}
                    title="Our Story & Values"
                    onClick={handlePropertyClick}
                  >
                    Property Solutions <FontAwesomeIcon icon={faCaretRight} />
                    <ul
                      className={`absolute right-0 -mr-48 -mt-[25%] ml-10 w-44 pl-2  rounded-lg ${
                        property ? "slide-in" : "slide-out"
                      } bg-black text-white mt-2 space-y-2  transition-opacity duration-300 ease-in-out`}
                    >
                      <NavLink
                        to="/resources/assetsleasing"
                        onClick={closeMobileMenu}
                        className="block text-white hover:text-mygreen py-2 w-full "
                      >
                        Asset Leasing
                      </NavLink>
                      <NavLink
                        to="/resources/fractional"
                        onClick={closeMobileMenu}
                        className="block text-white hover:text-mygreen py-2 w-full"
                      >
                        Fractional Ownership
                      </NavLink>
                      <NavLink
                        to="/resources/enquiry"
                        onClick={closeMobileMenu}
                        className="block text-white hover:text-mygreen py-2 w-full"
                      >
                        RE ventures
                      </NavLink>
                    </ul>
                  </Link>
                  <Link
                    className={`text-white py-2 relative group hover:text-mygreen `}
                    title="Our Story & Values"
                    onClick={handleWarehouseClick}
                  >
                    Warehouse Solutions <FontAwesomeIcon icon={faCaretRight} />
                    <ul
                      className={`absolute right-0 -mr-48 -mt-[25%] ml-10 w-44 pl-2  rounded-lg ${
                        warehouse ? "slide-in" : "slide-out"
                      } bg-black text-white mt-2 space-y-2  transition-opacity duration-300 ease-in-out`}
                    >
                      <NavLink
                        to="/resources/sellyourwarehouse"
                        onClick={closeMobileMenu}
                        className="block text-white hover:text-mygreen py-2 w-full "
                      >
                        Sell Your Warehouse
                      </NavLink>
                      <NavLink
                        to="/resources/buywarehouse"
                        onClick={closeMobileMenu}
                        className="block text-white hover:text-mygreen py-2 w-full "
                      >
                        Buy a Warehouse
                      </NavLink>
                      <NavLink
                        to="/resources/rentalwarehouse"
                        onClick={closeMobileMenu}
                        className="block text-white hover:text-mygreen py-2 w-full "
                      >
                        Rental Warehouse
                      </NavLink>
                      <NavLink
                        to="/resources/warehouseasset"
                        onClick={closeMobileMenu}
                        className="block text-white hover:text-mygreen py-2 w-full "
                      >
                        Warehouse Asset Management
                      </NavLink>
                    </ul>
                  </Link>

                  <Link
                    className={`text-white py-2 relative group hover:text-mygreen `}
                    title="Resources"
                    onClick={handleServicesClick}
                  >
                    Resources <FontAwesomeIcon icon={faCaretRight} />
                    <ul
                      className={`absolute right-0 -mr-48 -mt-[25%] ml-10 w-44 pl-2  rounded-lg ${
                        isServicesOpen ? "slide-in" : "slide-out"
                      } bg-black text-white mt-2 space-y-2  transition-opacity duration-300 ease-in-out`}
                    >
                      <NavLink
                        to="/blog"
                        onClick={closeMobileMenu}
                        className="block text-white hover:text-mygreen py-2 w-full "
                      >
                        Blog
                      </NavLink>
                      <NavLink
                        to="/faq"
                        onClick={closeMobileMenu}
                        className="block text-white hover:text-mygreen py-2 w-full"
                      >
                        FAQ
                      </NavLink>
                      {/* <li className=" pt-1 pb-4">
                        <NavLink
                          to="/resources/support"
                          className="text-white hover:text-mygreen "
                          onClick={closeMobileMenu}
                        >
                          Support
                        </NavLink>
                      </li> */}
                    </ul>
                  </Link>

                  {/* <NavLink
                    to="/faq"
                    onClick={closeMobileMenu}
                    className="block text-white hover:text-mygreen py-2 w-full"
                  >
                    FAQ
                  </NavLink>

                  <NavLink
                    to="/blog"
                    onClick={closeMobileMenu}
                    className="block text-white hover:text-mygreen py-2 w-full "
                  >
                    Blog
                  </NavLink> */}

                  <NavLink
                    to="/contactus"
                    onClick={closeMobileMenu}
                    className="block text-white hover:text-mygreen py-2 w-full"
                  >
                    Contact Us
                  </NavLink>

                  <button
                    className="bg-mygreen py-2 px-7 mt-2 rounded-md font-bold text-black w-sm"
                    onClick={() => {
                      closeMobileMenu();
                      window.location.href = "https://riofinadmin.whytap.tech/";
                    }}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Regular Menu for larger screens */}
          {!isMobile && (
            <div className="lg:space-x-5 md:absolute lg:relative md:right-0.5 lg:right-4 space-x-4">
              <NavLink to="/" exact className="text-white  hover:text-mygreen ">
                Home
              </NavLink>
              <NavLink to="/investment" exact className="text-white  hover:text-mygreen ">
                Opportunities
              </NavLink>
              <Link
                className="text-white hover:text-mygreen relative gap-1"
                onMouseEnter={handelStoryMouseEnter}
                onMouseLeave={handleStoryMouseLeave}
                onClick={handleStoryClick}
                ref={ref}
              >
                Our Story & Values <FontAwesomeIcon icon={faCaretDown} />
                {story && (
                  <ul
                    ref={ref}
                    className={`absolute left-0 w-44 pl-2 rounded-lg bg-black text-white mt-2 space-y-2 transition-opacity duration-500 ease-in-out`}
                  >
                    <li className=" py-1">
                      <NavLink
                        to="/aboutus"
                        className="text-white hover:text-mygreen"
                      >
                        About Us
                      </NavLink>
                    </li>

                    <li className=" py-1">
                      <NavLink
                        to="/resources/assets"
                        className="text-white hover:text-mygreen "
                      >
                        Our USP
                      </NavLink>
                    </li>
                  </ul>
                )}
              </Link>

              <Link
                className="text-white hover:text-mygreen relative gap-1"
                onMouseEnter={handelPropertyMouseEnter}
                onMouseLeave={handlePropertyMouseLeave}
                onClick={handlePropertyClick}
                ref={ref}
              >
                Property Solutions
                <FontAwesomeIcon icon={faCaretDown} className="ml-1" />
                {property && (
                  <ul
                    ref={ref}
                    className={`absolute left-0 w-44 pl-2 rounded-lg bg-black text-white mt-2 space-y-2 transition-opacity duration-500 ease-in-out`}
                  >
                    <li className=" py-1">
                      <NavLink
                        to="/resources/assetsleasing"
                        className="text-white hover:text-mygreen"
                      >
                        Asset Leasing
                      </NavLink>
                    </li>

                    <li className=" py-1">
                      <NavLink
                        to="/resources/fractional"
                        className="text-white hover:text-mygreen "
                      >
                        Fractional Ownership
                      </NavLink>
                    </li>
                    <li className=" py-1">
                      <NavLink
                        to="/resources/enquiry"
                        className="text-white hover:text-mygreen "
                      >
                        RE Ventures
                      </NavLink>
                    </li>
                  </ul>
                )}
              </Link>

              <Link
                className="text-white hover:text-mygreen relative gap-1"
                onMouseEnter={handelWarehouseMouseEnter}
                onMouseLeave={handleWarehouseMouseLeave}
                onClick={handleWarehouseClick}
                ref={ref}
              >
                Warehouse Solutions{" "}
                <FontAwesomeIcon icon={faCaretDown} className="ml-1" />
                {warehouse && (
                  <ul
                    ref={ref}
                    className={`absolute left-0 w-44 pl-2 rounded-lg bg-black text-white mt-2 space-y-2 transition-opacity duration-500 ease-in-out`}
                  >
                    <li className=" py-1">
                      <NavLink
                        to="/resources/sellyourwarehouse"
                        className="text-white hover:text-mygreen"
                      >
                        Sell Your Warehouse
                      </NavLink>
                    </li>

                    <li className=" py-1">
                      <NavLink
                        to="/resources/buywarehouse"
                        className="text-white hover:text-mygreen "
                      >
                        Buy a Warehouse
                      </NavLink>
                    </li>
                    <li className=" py-1">
                      <NavLink
                        to="/resources/rentalwarehouse"
                        className="text-white hover:text-mygreen "
                      >
                        Rental Warehouse
                      </NavLink>
                    </li>
                    <li className=" py-1">
                      <NavLink
                        to="/resources/warehouseasset"
                        className="text-white hover:text-mygreen "
                      >
                        Warehouse Asset Management
                      </NavLink>
                    </li>
                  </ul>
                )}
              </Link>

              {/* <NavLink to="/blog" className="text-white hover:text-mygreen">
                Blog
              </NavLink> */}

              <Link
                className="text-white hover:text-mygreen relative"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleServicesClick}
                ref={ref}
              >
                Resources <FontAwesomeIcon icon={faCaretDown} />
                {isServicesOpen && (
                  <ul
                    ref={ref}
                    className={`absolute right-0 -mr-20 w-44 pl-2 rounded-lg bg-black text-white mt-2 space-y-2 transition-opacity duration-500 ease-in-out`}
                  >
                    <li className=" py-1">
                      <NavLink
                        to="/blog"
                        className="text-white hover:text-mygreen"
                      >
                        Blog
                      </NavLink>
                    </li>

                    <li className=" py-1">
                      <NavLink
                        to="/faq"
                        className="text-white hover:text-mygreen "
                      >
                        FAQ
                      </NavLink>
                    </li>

                    {/* <li className=" py-1">
                      <NavLink
                        to="/resources/fractional"
                        className="text-white hover:text-mygreen"
                      >
                        Fractional Ownership
                      </NavLink>
                    </li>

                    <li className=" py-1">
                      <NavLink
                        to="/resources/enquiry"
                        className="text-white hover:text-mygreen "
                      >
                        Raise Capital
                      </NavLink>
                    </li>
                    <li className=" py-1">
                      <NavLink
                        to="/resources/sellyourwarehouse"
                        className="text-white hover:text-mygreen "
                      >
                        Sell Your Warehouse
                      </NavLink>
                    </li>
                    <li className=" py-1">
                      <NavLink
                        to="/resources/buywarehouse"
                        className="text-white hover:text-mygreen "
                        onClick={closeMobileMenu}
                      >
                        Buy a Warehouse
                      </NavLink>
                    </li>
                    <li className=" py-1">
                      <NavLink
                        to="/resources/rentalwarehouse"
                        className="text-white hover:text-mygreen "
                      >
                         Warehouse Rental
                      </NavLink>
                    </li>
                    <li className=" py-1">
                      <NavLink
                        to="/resources/warehouseasset"
                        className="text-white hover:text-mygreen "
                        onClick={closeMobileMenu}
                      >
                        Warehouse Asset Management
                      </NavLink>
                    </li> */}
                    {/* <li className=" pt-1 pb-4">
                      <NavLink
                        to="/resources/support"
                        className="text-white hover:text-mygreen"
                      >
                        Support
                      </NavLink>
                    </li> */}
                  </ul>
                )}
              </Link>

              {/* <NavLink>
              <li
                  className={`text-white relative group`}
                  onClick={handleServicesClick}
                >
                  Services
                  <ul
                    className={`absolute ${
                      isServicesOpen ? 'block' : 'hidden'
                    } bg-gray-800 text-white mt-2 space-y-2`}
                  >
                      <li className="px-4 py-2">Service 1</li>
                      <li className="px-4 py-2">Service 2</li>
                      
                  </ul>
              </li>
             </NavLink> */}

              <NavLink
                to="/contactus"
                className="text-white hover:text-mygreen"
              >
                Contact Us
              </NavLink>

              <button
                className="bg-mygreen py-2 px-5 mt-2 rounded-md text-black font-bold w-sm"
                onClick={() => {
                  closeMobileMenu();
                  window.location.href = "https://riofinadmin.whytap.tech/";
                }}
              >
                Login
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar1;
